import DropDown from "./DropDown";
import EventButton from "./EventButton";
import TextBox from "./TextBox";
import styles from './stylesheets/dropdownlayout.module.css';
function Levers(prop) {
let  instoreMarketing = "Instore"+"\n"+"Marketing";
    let  retailerDiscount = "Retailer"+"\n"+"Discount";
    let lockPredictionButton;
    if(prop.showLockedPrediction){
        lockPredictionButton =   <EventButton  buttonname = "Locked" buttonid = "lockedpredictionbutton" onhandlePredictButton = {prop.onHandleLoadLockedPredictionButton}/>

    }else{
      lockPredictionButton  =<p></p>;
    }
    //<DropDown labeltitle = {retailerDiscount} labelid = "retailerdiscount" onhandleDropDownStateChange = {prop.onhandleDropDownStateChange} dropDownData =  {[]}/>

    
    return( <div className="dropdownsection">
            <h2 className={styles.dropdownsectiontitle}>{prop.sectionname}</h2>
            <hr/>
            <div className={styles.priceslayout}>
            <p className={styles.current_price}>Current:<span>Ksh {prop.currentPrice}</span></p>
            <p className={styles.proposed_price}>Proposed:<span>Ksh {prop.proposedPrice}</span></p>
            <p className={styles.proposed_price}>Disc:<span>{prop.discount}%</span></p>
            </div>
            <div className={styles.dropdownlayout}>
                <TextBox labeltitle = "Price" labelid = "price" placeholder = "price" disabled = {false} type = "text" value = {prop.currentPrice} onhandleTextBoxStateChange = {prop.onhandleTextBoxStateChange}/>
                <TextBox labeltitle = "Discount (Ksh)" labelid = "discount" placeholder = "discount" type = "text" disabled = {false} value = {prop.absoluteDiscount} onhandleTextBoxStateChange = {prop.onhandleTextBoxStateChange} />

                <DropDown labeltitle = "Period" labelid = "periodlevers" onhandleDropDownStateChange = {prop.onhandleDropDownStateChange} dropDownData =  {[]}/>
                <DropDown labeltitle = {instoreMarketing} labelid = "instoremarketing" onhandleDropDownStateChange = {prop.onhandleDropDownStateChange} dropDownData =  {[]}/>

            </div>
          <hr/>
            <div className={styles.dropdownbuttonlayout}>
             <EventButton  buttonname = "Predict" buttonid = "predictbutton" onhandlePredictButton = {prop.onhandlePredictButton}/>
                {lockPredictionButton}
            </div>
        </div>


    )


}

export default Levers