
import styles from './stylesheets/automateresults.module.css'
import {
    CircularProgress,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell, tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useRef} from "react";
import AutomateResultsButtons from "./AutomateResultsButtons";

import {downLoadChartImage} from "../utils/ReportUtilities";
import {allocateBudget} from "../utils/Utilities";
function AutomateResults(props) {

    let rows = [];
    const recommendation = useRef("");

    const tableref = useRef(null);
    const showPlaceHolder = false;
    //console.log(props.maxDiscount1)
    //console.log(props.maxDiscount2);
    //console.log(props.maxDiscount1Retailer);
    //console.log(props.maxDiscount2Retailer);
    console.log(props.calculationsResultArray);

    //console.log(props.calculationsResultArray2);
//alert(props.calculationsResultArray.length + ""+props.calculationsResultArray2.length);

/**
    let calculationsArray;
    if(props.constraint === "Max Discount"){
        calculationsArray= props.calculationsResultArray;
    }else{
        //Max Budget
        calculationsArray = props.calculationsResultArray;

    }
 **/


   // console.log(calculationsArray);

    function scrollTableUp() {
        tableref.current.scrollTop =0;
    }

    function createData(position, retailer, discount, volume, volumeuplift, salesroi, investment) {
        return {position, retailer, discount, volume, volumeuplift, salesroi, investment};
    }


    function getSalesROIAndUplift(calculationsArray, discount, productPosition) {
        //console.log("Calculations  Array: " + props.calculationsResultArray)
        //let calculationsArray = props.calculationsResultArray;

        let filteredArray = calculationsArray.filter((value) => {


            return value.Discount === discount;
        });
        let ROI = filteredArray[0].ROI[productPosition] === undefined?0:filteredArray[0].ROI[productPosition];
        let Uplift = filteredArray[0].Uplift[productPosition] === undefined ? 0: filteredArray[0].Uplift[productPosition];
        let Investment = filteredArray[0].Investment[productPosition] === undefined ? 0: filteredArray[0].Investment[productPosition];

        return {ROI: ROI, Uplift: Uplift, Investment:Investment};

    }

/**
    //index specifies position of product in ml models output
    function displayResults(calculationsArray, productPosition) {
        //try {
        let maxDiscountArray;
        if(props.constraint === "Max Discount"){
            maxDiscountArray = props.maxDiscount;
        }else{
            /**
           maxDiscountArray = props.maxDiscount.filter(function (value, index) {

               return value.discount >= 0 && value.discount <= 20;
           });

            maxDiscountArray = props.maxDiscount;
        }

       // console.log(maxDiscountArray);
        //console.log(calculationsArray);

        const rowsUnformatted = [];

        maxDiscountArray.forEach(function (value, index, array) {

            if(calculationsArray[index] !== undefined && calculationsArray[index] !== null){
            //if(index <= 9) {
            let position = index + 1;
            let discount = value.discount;

            let outputArray = value.output[0];
            let outputVolume = outputArray[productPosition];
            let results = getSalesROIAndUplift(calculationsArray,discount, props.productIndex);

            rowsUnformatted.push(createData(position, props.retailer, discount, outputVolume, results.Uplift, results.ROI, results.Investment));
             }

        });

        let rowsFormatted;
       if( props.objective === "Maximize Volume"){

         rowsFormatted  = rowsUnformatted.sort((a, b) => {


               return b.volume - a.volume;
           });

       }else{

           rowsFormatted  = rowsUnformatted.sort((a, b) => {


               return b.salesroi - a.salesroi;
           });
       }



        rowsFormatted.forEach((value, index, array) => {

            if (index === 0) {
                recommendation.current = `Run ${value.discount}% in ${value.retailer}  to get total volume of ${value.volume.toFixed(0)} and Roi of ${value.salesroi}%`
            }

            rows.push(createData(index + 1, value.retailer, value.discount, value.volume, value.volumeuplift, value.salesroi, value.investment));

        });

       console.log(JSON.stringify(rows));

        //  }catch (e) {
        //console.log(e)


        //}

    }
    **/



    function getDisplayResults(calculationsArray, maxDiscountArray, retailer,  productPosition, showRecommendation = true) {
        try {
        const rows = [];

           //let maxDiscountArray = props.maxDiscount;


        console.log(maxDiscountArray);
        console.log(calculationsArray);

       // alert(maxDiscountArray.length + " "+ calculationsArray.length);

        const rowsUnformatted = [];

        maxDiscountArray.forEach(function (value, index, array) {

            if(calculationsArray[index] !== undefined){
                //if(index <= 9) {
                let position = index + 1;
                let discount = value.discount;
               // alert(discount);

                let outputArray = value.output[0];
                let outputVolume = outputArray[productPosition];
                let results = getSalesROIAndUplift(calculationsArray,discount, props.productIndex);

                if( props.constraint === "Max Budget")
                {
                    if (results.Investment <= props.maxconstraint)
                    {
                        rowsUnformatted.push(createData(position, retailer, discount, outputVolume, results.Uplift, results.ROI, results.Investment));
                    }
                }
                else
                {
                    rowsUnformatted.push(createData(position, retailer, discount, outputVolume, results.Uplift, results.ROI, results.Investment));
                }
            }

        });

        let rowsFormatted;
        if( props.objective === "Maximize Volume"){

            rowsFormatted  = rowsUnformatted.sort((a, b) => {


                return b.volume - a.volume;
            });

        }else{

            rowsFormatted  = rowsUnformatted.sort((a, b) => {


                return b.salesroi - a.salesroi;
            });
        }



        rowsFormatted.forEach((value, index, array) => {

            if(showRecommendation) {
                if (index === 0) {
                    recommendation.current = `Run ${value.discount}% in ${value.retailer}  to get total volume of ${value.volume.toFixed(0)} and Roi of ${value.salesroi}%`
                }


            }

            rows.push(createData(index + 1, value.retailer, value.discount, value.volume, value.volumeuplift, value.salesroi, value.investment));
            
        });



        console.log(JSON.stringify(rows));

        return rows;

          }catch (e) {
        console.log(e)


        }

    }

    function optimizeBudget(calculationsArray, calculationsArray2, budget){
        try {

          // alert(calculationsArray.length + " "+ calculationsArray2.length);
            let array1 = getDisplayResults(calculationsArray, props.maxDiscount1, props.maxDiscount1Retailer, 0, false);
            let array2 = getDisplayResults(calculationsArray2, props.maxDiscount2, props.maxDiscount2Retailer, 0, false);

            console.log(array1);
            console.log(array2);

            console.log(array1.length + " " + array2.length);
            let allocatedBudget;
            if (array1.length > array2.length) {
                allocatedBudget = allocateBudget(array2, array1, budget);

            } else if (array2.length > array1.length) {
                allocatedBudget = allocateBudget(array1, array2, budget);

            } else {
                allocatedBudget = allocateBudget(array1, array2, budget);
            }


            if (allocatedBudget.selectedRetailers.length > 0) {

                recommendation.current = `Run ${allocatedBudget.selectedRetailers[0].discount}% in ${allocatedBudget.selectedRetailers[0].retailer} & ${allocatedBudget.selectedRetailers[1].discount}% in ${allocatedBudget.selectedRetailers[1].retailer}  to get total volume of ${allocatedBudget.totalVolume.toFixed(0)} and Roi of ${allocatedBudget.selectedRetailers[0].salesroi}% in ${allocatedBudget.selectedRetailers[0].retailer} & ${allocatedBudget.selectedRetailers[1].salesroi}% in ${allocatedBudget.selectedRetailers[1].retailer}`;

            }

            return allocatedBudget.selectedRetailers;

        }catch (e) {
            console.log(e);
        }

    }



    function showSign(constraint) {

        if (constraint === "Max Discount") {
            return "%";
        } else {

            return "Ksh";
        }
    }

    function shareReport() {

        scrollTableUp();


        setTimeout(()=>{
            let inputs = {
                imageurl: null,
                product: props.product,
                category: props.category,
                retailer: props.retailer,
                retailers:props.retailers,
                date: props.date,
                price: null,
                period: null,
                discount: null,
                sales: rows[0].volume,
                sales2:rows[1].volume,
                salesAmount: null,
                salesUplift: rows[0].volumeuplift,
                salesUplift2: rows[1].volumeuplift,
                ROI:rows[0].salesroi,
                ROI2:rows[1].salesroi,
                constraint:props.constraint,
                maxconstraint:props.maxconstraint,
                objective:props.objective
            }

            downLoadChartImage(inputs, "optimize", tableref.current);

        },2000)

    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            border: '1px solid white',
            fontSize: 14,

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 15,

        },
    }));
  //displayResults(props.calculationsResultArray, 0);
    if(props.constraint === "Max Discount") {
        rows = getDisplayResults(props.calculationsResultArray,props.maxDiscount,props.retailer, 0);
        //console.log(props.calculationsResultArray);
        //console.log(props.calculationsResultArray2);
    }else {

//max budget
            //alert(props.retailers);
            if(props.retailers.length === 2) {
                rows = optimizeBudget(props.calculationsResultArray, props.calculationsResultArray2, props.maxconstraint);
            }else{
                rows = getDisplayResults(props.calculationsResultArray,props.maxDiscount,props.retailer, 0);

            }

    }
    console.log(rows);
  let width = 14.28;
let displayRetailer;
 if(props.retailers.length === 2) {

     displayRetailer = `${props.maxDiscount1Retailer} & ${props.maxDiscount2Retailer}`

 }else{

     displayRetailer = props.retailer;
 }
 let targetName;
 if(showPlaceHolder === true){
    targetName = 'Product XXX';
 }else{
     targetName = props.target;
 }

    let position = 1;


    let tableData =   <TableContainer sx={{ maxHeight: 440, width:'100%', overflow:'auto'}} component={Paper} ref={tableref}  >
        <Table stickyHeader aria-label="Optimize Results Table">
            <TableHead  >
                <TableRow >
                    <StyledTableCell sx={{ width: `${width}%` }}    align="center">Position</StyledTableCell>
                    <StyledTableCell sx={{ width: `${width}%` }}  align="center">Retailer</StyledTableCell>
                    <StyledTableCell sx={{ width: `${width}%` }}  align="center">Discount %</StyledTableCell>
                    <StyledTableCell sx={{ width: `${width}%` }}  align="center">Volume</StyledTableCell>
                    <StyledTableCell sx={{ width: `${width}%` }}  align="center">Volume Uplift</StyledTableCell>
                    <StyledTableCell sx={{ width: `${width}%` }} align="center">Investment </StyledTableCell>
                    <StyledTableCell sx={{ width: `${width}%` }}  align="center">Sales ROI</StyledTableCell>



                </TableRow>
            </TableHead>
            <TableBody>

                {rows.map((row) => (
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell align="center">{props.retailers.length === 2?position++:row.position}</TableCell>
                        <TableCell align="center">{row.retailer}</TableCell>
                        <TableCell align="center">{`${row.discount}%`}</TableCell>
                        <TableCell align="center">{row.volume.toFixed(0)}</TableCell>
                        <TableCell align="center">{`${row.volumeuplift}%`}</TableCell>
                        <TableCell align="center">{`${row.investment.toFixed(0).replace("-","")}`}</TableCell>
                        <TableCell align="center">{`${row.salesroi}%`}</TableCell>


                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>


    return(<div className={styles.mainlayout}>

        <div className={styles.tablelayout}>
            <p className={styles.automateresultstitle}>Summary</p>
            <div className={styles.automateresultstitlelayout}>
            <div>
                <div>
             <h3>Target:</h3>
             <p>{targetName                                                                                                   }</p>
                </div>
                <div>
                    <h3>Retailer:</h3>
                    <p>{displayRetailer}</p>
                </div>
            </div>
              <div>
                  <div>
                      <h3>Objective:</h3>
                      <p>{props.objective}</p>
                  </div>
                  <div>
                      <h3>Constraints:</h3>
                      <p>{`${props.constraint} ${props.maxconstraint}${showSign(props.constraint)}`}</p>
                  </div>
              </div>
            </div>

            {tableData}
        </div>
        <div className={styles.automateresultsbottomlayout}>
        <h4>Recommendation</h4>
        <p>{recommendation.current}</p>

        <AutomateResultsButtons onhandleShareClicked = {shareReport} csvData = {rows} retailer = {displayRetailer} category = {props.category} constraint = {props.constraint} maxconstraint = {props.maxconstraint} product = {props.product} date = {props.date}/>
        </div>
    </div>)



}

export default AutomateResults;