import logo from './logo.svg';
import './App.css';
import Header from "./components/header";
import Sidebar from "./components/Sidebar";
import SectionTitles from "./components/SectionTitles";
import Target from "./components/Target";
import Levers from "./components/Levers";
import Scenarios from "./components/Scenarios";
import ChartsLayout from "./components/ChartsLayout";
import RegistrationForm from "./components/RegistrationForm";
import LoginForm from "./components/LoginForm";
import ComingSoonImage from "./components/ComingSoonImage";
import {useState, useEffect, useRef} from "react";
import LineChart from "./components/LineChart";
import {CircularProgress} from "@mui/material";

import {
    calculateEffectivePrice,
    calculateSalesROi,
    calculateUplift,
    formatDate,
    getMonthFormatted,
    getNextMonth,
    getPreviousMonth,
    getYearFormatted, isValuePack
} from "./utils/Utilities"
import {ButtonGroup, Button} from "@mui/material";
import axios from "axios";
import DoghnutChartWhatIfAnalaysisLayout from "./components/DoghnutChartWhatIfAnalaysisLayout";
import AutomateLevers from "./components/AutomateLevers";
import AutomateResults from "./components/AutomateResults";
import LandingPage from "./components/LandingPage";
import constructionImage from './components/images/aitools/cunstruction/underconstruction.png';



function App() {


    const [showScenario, setScenario] = useState(false);
    const [showAutomate, setShowAutomate] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const[showMyPlan, setShowMyPlan] = useState(false);
    const [showDoghnutChartWhatIf, setShowDoghnutChartWhatIf] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showAuthentication, setShowAuthentication] = useState(true);
    const [showLoginScreen, setShowLoginScreen] = useState(true);
    const [showRegistrationScreen, setShowRegistrationScreen] = useState(false);
    const [showLoginLoader, setShowLoginLoader] = useState(false);
    const [showAutomateLoader, setShowAutomateLoader] = useState(false);
    const [showLandingPage, setShowLandingPage] = useState(true);
    const [showRegistrationLoader, setShowRegistrationLoader] = useState(false);
    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loginAlertMessage, setLoginAlertMessage] = useState("");
    const [showRegistrationAlert, setShowRegistrationAlert] = useState(false);
    const [showAutomateResults, setShowAutomateResults] = useState(false);
    const [registrationAlertType, setShowRegistrationAlertType] = useState("");
    const [registrationAlertMessage, setRegistrationAlertMessage] = useState("");
    const [token, setToken] = useState(localStorage.getItem("token"));

    const[selectedSubCategory, setSelectedSubCategory] = useState("Regular Bleach")
   // const[selectedSubCategory, setSelectedSubCategory] = useState("Liquid Toilet Cleaner")

    //target levers

    const [product, setProduct] = useState("Jik Bleach Regular 750ml");
    const [productIndex, setProductIndex] = useState(0);
    const [category, setCategory] = useState("Bleach");
    const [subcategory, setSubCategory] = useState("Regular Bleach");
    const [brand, setBrand] = useState("Jik");
    const [size, setSize] = useState("750ml");



   /**
     const [product, setProduct] = useState("Harpic Power Plus Citrus 500ml");
     const [category, setCategory] = useState("Toilet Cleaner");
     const [subcategory, setSubCategory] = useState("Liquid Toilet Cleaner");
     const [brand, setBrand] = useState("Harpic");
     const [size, setSize] = useState("500ml");
    **/




    const [constraint, setConstraint] = useState("Max Discount");
    const [objective, setObjective] = useState("Max Volume");
    const [maxconstraint, setMaxConstraint] = useState(0);
    const [budget, setBudget] = useState(0);
    const maxDiscount = useRef([
        {
            discount: 0,
            output: []
        }
    ]);
    const roiCalculationsResult = useRef([]);
    const averagePriceAndQuantity = useRef([
        {
            fullDate: '',
            barcode: '',
            product: '',
            salesQuantity: 0,
            averageSalesQuantity: 0,
            averagePrice: 0
        }
    ]);






    const [retailer, setRetailer] = useState(3);
    const [retailerName, setRetailerName] = useState("Naivas");
    const [date, setDate] = useState("0");
    const [previousDate, setPreviousDate] = useState("0");
    const [proposedPrice, setProposedPrice] = useState(0);
    const [effectivePrice, setEffectivePrice] = useState(0);
    const [placeHolderProductLabel, setPlaceHolderProductLabel] = useState();
    const [showPlaceHolderProductLabel, setShowPlaceHolderProductLabel] = useState(false);


    //what if levers
    const [price, setPrice] = useState();
    const [discount, setDiscount] = useState(0);
    const [period, setPeriod] = useState(1);
    const [imageUrl, setImageUrl] = useState("");
    const [instoremarjeting, setInstoreMarketing] = useState();
    const [retailerDiscount, setRetailerDiscount] = useState();
    const [currentPricesList, setCurrentPricesList] = useState([]);
    const [lineChartYAxis, setLineChartYAxis] = useState([]);
    const [lineChartYAxisPredicted, setLineChartYAxisPredicted] = useState([]);
    const [lineChartXAxis, setLineChartXAxis] = useState([]);

    const [currentPrice, setCurrentPrice] = useState(0);
    const [previousPrice, setPreviousPrice] = useState(0);
    const [totalPredictedSalesQty, setTotalPredictedSalesQty] = useState(0);
    const [totalpreviousMonthQty, setTotalPreviousMonthQty] = useState(0);
    const [previousMonthProductQty, setPreviousMonthProductQty] = useState(0);
    const[peviosMonthResults, setPreviousMonthResults] = useState([]);
    const [marketViewArrayPredictedValues, setMarketViewArrayPredictedValues] = useState([]);
    const [marketViewArrayPredictedLabels, setMarketViewArrayPredictedLabels] = useState([]);
    const [marketViewArrayActualValues, setMarketViewArrayActualValues] = useState([]);
    const [marketViewArrayActualLabels, setMarketViewArrayActualLabels] = useState([]);


    const loginUser = (formData) => {
        const requestData = {email: formData.email, password: formData.password}
        setShowLoginLoader(true);
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mluserlogin', requestData).then(function (response) {
            console.log(response);

            setShowLoginLoader(false);

            const data = response.data;

            if (data.status === "Success") {
                localStorage.setItem("token", "loggedin");
                setToken(localStorage.getItem("token"));
                setShowAuthentication(false);
                setShowLoginAlert(false);
            } else {
                setShowLoginAlert(true)
                setLoginAlertMessage(data.errorMessage);
                //alert(data.errorMessage);
                console.log(data.errorMessage);
            }
        }).catch(function (error) {
            console.log(error);
            setShowLoginAlert(true)
            setLoginAlertMessage(error);
            // alert(error)
        })
    };

    const registerUser = (formData) => {
        const requestData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            organization: formData.organization,
            role: formData.role,
            password: formData.password
        }
        setShowRegistrationLoader(true);
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mluserregistration', requestData).then(function (response) {
            console.log(response);

            setShowRegistrationLoader(false);

            const data = response.data;

            if (data.statusCode === "200") {

                setRegistrationAlertMessage(data.body);
                setShowRegistrationAlert(true);
                setShowRegistrationAlertType("success");
                // alert(data.body);

            } else {

                setRegistrationAlertMessage(data.body);
                setShowRegistrationAlert(true);
                setShowRegistrationAlertType("error");
                // alert(data.body);
            }
        }).catch(function (error) {
            console.log(error);
            setRegistrationAlertMessage(error);
            setShowRegistrationAlert(true);
            setShowRegistrationAlertType("error");
        })
    };

    //get current bleach prices
    useEffect(() => {
          let requestData;
          if(selectedSubCategory === "Regular Bleach") {
              requestData = {fmcgId: 34, category: "Bleach", subCategory: "Regular Bleach"}
          }else {
              requestData = {fmcgId: 34, category: "Toilet Cleaner", subCategory: "Liquid Toilet Cleaner"}
          }
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachescurrentprices', requestData).then(function (response) {
            console.log(response);

            const data = response.data;

            //got current prices successfully
            if (data.statusCode === "200") {

                //alert("successfully got current prices");
                setCurrentPricesList(data.currentPricesResponseItemsList);


            } else {

                alert(data.statusMessage);

            }


        }).catch(function (error) {
            console.log(error);
        });

    }, [selectedSubCategory])

    const handleShowScenarioStateChange = (newState) => {
        setScenario(newState)
    };
    const handleShowMyPlanStateChange = (newState)=>{
        setShowMyPlan(newState);
    }

    const handleShowSettingsStateChange = (newState)=>{

     setShowSettings(newState);
    }

    const handleAutomateStateChange = (newState) => {

        if(showAutomateResults){
            setShowAutomateResults(false);
        }
        setShowAutomate(newState);

    }

    const handleLogoutStateChange = () => {

        localStorage.removeItem("token");
        setToken(localStorage.getItem("token"));
        setShowAuthentication(true);
        setShowLoginScreen(true);
        setShowRegistrationScreen(false);
        setShowLandingPage(true)
        setShowMyPlan(false);
        setShowSettings(false);
    };

    const handleLoginButtonLandingPage = ()=>{

        setShowLandingPage(false)
        setShowLoginScreen(true);
        setShowRegistrationScreen(false);
        setShowAuthentication(true);
    };

    const handleLoginBackPress = () => {


        setShowAuthentication(false);
        setShowLoginScreen(false);
        setShowRegistrationScreen(false);
        setShowLandingPage(true)
    };


    const handleTextBoxStateChange = (newState) => {
        if (newState.type === "discount") {
            //setDiscount(parseInt(newState.value))

            if (!isNaN(parseFloat(newState.value))) {
                setDiscount(parseFloat(newState.value));

                let proposedDiscount = (currentPrice - ((parseFloat(newState.value) / 100) * currentPrice)).toFixed(0);
                setProposedPrice(proposedDiscount);
            } else {
                setProposedPrice(0);
                setProposedPrice(currentPrice);
            }


        }else if(newState.type === "maxconstraint"){

            setMaxConstraint(parseFloat(newState.value));

        }

    }
    const handleDropDownStateChange = (newState) => {

        if (newState.type === "product") {
            if (!newState.showImage) {
                setImageUrl(newState.img_url);
                setShowPlaceHolderProductLabel(false);
            } else {
                //show placeholder
                setPlaceHolderProductLabel(newState.text);
                setShowPlaceHolderProductLabel(true);
            }
            //alert(newState.text);

            // alert(newState.img_url);
            //if current prices list has been set
            if (currentPricesList.length > 0) {

                const filterResults = currentPricesList.filter((value, index, array) => {


                    return (value.product === newState.value) && (value.retailer_id === retailer);
                });


                if (filterResults.length > 0) {

                    // alert(filterResults[0].price);

                    setCurrentPrice(filterResults[0].price);

                    setProduct(newState.value);
                    setProductIndex(newState.selectedProductIndex);

                    //check if proposed discount has been set
                    setProposedPrice(filterResults[0].price);


                } else {
                    setCurrentPrice(0);
                    setProposedPrice(0);
                    setDiscount(0);
                    alert("current price not found for product");
                }


            }

            //setCurrentPrice(newState.type);

        } else if (newState.type === "discount") {

            setDiscount(parseInt(newState.value))


        } else if (newState.type === "periodlevers") {

            setPeriod(parseInt(newState.value));

            //alert(newState.value);

        } else if (newState.type === "subcategory") {

           // setSubCategory(newState.value)

           // alert(newState.value);


            if(newState.value === "Regular Bleach"){

                setProduct("Jik Bleach Regular 750ml")
                setCategory("Bleach")
                setSubCategory(newState.value)
                setBrand("Jik")
                setSize("750ml")
                setSelectedSubCategory(newState.value)

                //toilet cleaners
            }else{
               setProduct("Harpic Power Plus Citrus 500ml")
               setCategory("Toilet Cleaner")
                setSubCategory("Liquid Toilet Cleaner")
                setBrand("Harpic")
                setSize("500ml")
                setSelectedSubCategory("Liquid Toilet Cleaner")

            }

            setCurrentPrice(0);
            setProposedPrice(0);
            setDiscount(0);

        } else if (newState.type === "size") {

            setSize(newState.value)

        } else if (newState.type === "brand") {

            setBrand(newState.value);

        }else if(newState.type === "constraints"){

            setConstraint(newState.value);


        }else if(newState.type === "objective"){

            setObjective(newState.value);

        }else if(newState.type === "retailer"){

            setRetailerName(newState.value);


        } else {

           // alert(newState.value);

        }

    }

    const handleDatePickerSelect = (value) => {

        let got_date = `${value}-01`;
        let previous_date = getPreviousMonth(got_date);
        console.log(`selected date ${got_date}`);
        console.log(`previous date ${previous_date}`);

        setDate(got_date);
        setPreviousDate(previous_date);

        //alert(previous_date);


    }

    const getFilteredPrice = (productName, gotRetailer) => {

        const filterResults = currentPricesList.filter((value, index, array) => {


            return (value.product === productName) && (value.retailer_id === gotRetailer);
        });

        return filterResults[0].price;
    };
    const handleOptimizeButtonClicked = () => {
        if(showAutomateResults) {
            setShowAutomateResults(false);
        }

        //alert(maxconstraint);

        /**
         const requestData = {
            targetDiscount: maxconstraint,
            targetVolume: 0,
            targetROI: 0
        }
         **/

        let selectedConstraint = constraint;

        let selectedBrand = brand;

        if (selectedConstraint === "Max Discount")
        {
            const requestData = {
                modelId: 9,
                fmcgId: 34,
                date: date,
                product: product,
                category: category,
                subcategory: subcategory,
                size: size,
                targetPrice: 0,
                effectivePrice: 0,
                discount: maxconstraint,
                period: 3,
                Jik_Bleach_Regular_750ml_price: getFilteredPrice("Jik Bleach Regular 750ml", retailer),
                Jik_Bleach_Spring_Fresh_750ml_price: getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer),
                Jik_Bleach_Lemon_750ml_price: getFilteredPrice("Jik Bleach Lemon 750ml", retailer),
                requestType: "optimize",
                constraint: constraint.replaceAll(" ", ""),
                budget: 0,
                Omo_Bleach_Lemon_700ml_price: getFilteredPrice("Omo Bleach Lemon Fresh 700ml", retailer),
                Omo_Bleach_Regular_700ml_price: getFilteredPrice("Omo Bleach Regular 700ml", retailer),
                Safisha_Bleach_Lemon_750ml_price: getFilteredPrice("Safisha Bleach Lemon 750ml", retailer),
                Safisha_Bleach_Regular_750ml_price: getFilteredPrice("Safisha Bleach Regular 750ml", retailer),
                Topex_Bleach_Lemon_750ml_price: getFilteredPrice("Topex Bleach Lemon 750ml", retailer),
                Topex_Bleach_Regular_750ml_price: getFilteredPrice("Topex Bleach Regular 750ml", retailer),
                brand: selectedBrand
            }

            setShowAutomateLoader(true);
            axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {
                console.log(response);

                // setShowLoginLoader(false);

                const data = response.data;

                if (data === null || data === "") {
                    console.log("Data is null")
                    alert("Data is null");
                } else {

                    console.log(data.volumeResponse);
                    maxDiscount.current = data.volumeResponse;



                    axios.get('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity').then(function (response) {
                        console.log(response);

                        // setShowLoginLoader(false);

                        const data = response.data;

                        if (data === null || data === "") {
                            console.log("Data is null")

                            alert("Data is null");

                        } else {
                            console.log(data);
                            averagePriceAndQuantity.current = data;

                            let budget = maxconstraint;

                            // Initialize an empty array to store JSON objects
                            var jsonArray = [];
                            let isFinished = false;
                            for (let i = 0; i < maxDiscount.current.length; i++)
                            {
                                if (!isFinished)
                                {
                                    var roiArray = [];
                                    var salesGrowthArray = [];
                                    var investmentArray = [];
                                    var predictedQtyArray = [];
                                    var predictedPriceArray = [];
                                    var previousQtyArray = [];
                                    var previousPriceArray = [];
                                    var upliftArray = [];
                                    for (let j = 0; j < averagePriceAndQuantity.current.length; j++)
                                    {
                                        console.log("Discount Index: " + i +  " product Index: " + j);
                                        let predictedQty = maxDiscount.current[i].output[0][j]; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? maxDiscount.current[i].output[0][j] : 0;
                                        let predictedPrice = 430 * (1 - i/100);

                                        let previousQty = averagePriceAndQuantity.current[j].averageSalesQuantity; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averageSalesQuantity;
                                        let previousPrice = averagePriceAndQuantity.current[j].averagePrice; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averagePrice : 0;

                                        let ROI = parseFloat(calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice));
                                        let salesGrowth = (predictedQty * predictedPrice) - (previousQty * previousPrice);
                                        let investment = -(predictedQty * (predictedPrice - previousPrice));
                                        let uplift = calculateUplift(predictedQty, previousQty);

                                        roiArray.push(ROI);
                                            predictedQtyArray.push(predictedQty);
                                            predictedPriceArray.push(predictedPrice);
                                            previousQtyArray.push(previousQty);
                                            previousPriceArray.push(previousPrice);
                                            salesGrowthArray.push(salesGrowth);
                                            investmentArray.push(investment);
                                            upliftArray.push(uplift);
                                    }

                                    // Create a JSON object for each data entry
                                    var jsonObject = {
                                        "Discount": i,
                                        "predictedQty": predictedQtyArray,
                                        "predictedPrice": predictedPriceArray,
                                        "previousQty": previousQtyArray,
                                        "previousPrice": previousPriceArray,
                                        "ROI": roiArray,
                                        "SalesGrowth": salesGrowthArray,
                                        "Investment": investmentArray,
                                        "Uplift": upliftArray
                                    };

                                    // Push the JSON object into the jsonArray
                                    jsonArray.push(jsonObject);
                                }
                                else
                                {
                                    break;
                                }
                            }
                            // console.log("Maximize ROI: " + JSON.stringify(jsonArray));
                            roiCalculationsResult.current = jsonArray;
                            setShowAutomateLoader(false);
                            setShowAutomateResults(true);
                        }
                       // saveActivity("run optimize")
                    }).catch(function (error) {
                        console.log(error);

                        alert(error);
                    })









                }
            }).catch(function (error) {
                console.log(error);

                alert(error);
            })

        }
        else if (selectedConstraint === "Max Budget")
        {
            const requestData = {
                modelId: 9,
                fmcgId: 34,
                date: date,
                product: product,
                category: category,
                subcategory: subcategory,
                size: size,
                targetPrice: 0,
                effectivePrice: 0,
                discount: 50,
                period: 3,
                Jik_Bleach_Regular_750ml_price: getFilteredPrice("Jik Bleach Regular 750ml", retailer),
                Jik_Bleach_Spring_Fresh_750ml_price: getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer),
                Jik_Bleach_Lemon_750ml_price: getFilteredPrice("Jik Bleach Lemon 750ml", retailer),
                requestType: "optimize",
                constraint: "MaxDiscount",
                budget: maxconstraint,
                brand: selectedBrand,
                Omo_Bleach_Lemon_700ml_price: getFilteredPrice("Omo Bleach Lemon Fresh 700ml", retailer),
                Omo_Bleach_Regular_700ml_price: getFilteredPrice("Omo Bleach Regular 700ml", retailer),
                Safisha_Bleach_Lemon_750ml_price: getFilteredPrice("Safisha Bleach Lemon 750ml", retailer),
                Safisha_Bleach_Regular_750ml_price: getFilteredPrice("Safisha Bleach Regular 750ml", retailer),
                Topex_Bleach_Lemon_750ml_price: getFilteredPrice("Topex Bleach Lemon 750ml", retailer),
                Topex_Bleach_Regular_750ml_price: getFilteredPrice("Topex Bleach Regular 750ml", retailer),
            }

            setShowAutomateLoader(true);
            axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {
                console.log(response);

                // setShowLoginLoader(false);

                const data = response.data;

                if (data === null || data === "") {
                    console.log("Data is null")
                    alert("Data is null");
                } else {

                    console.log(data.volumeResponse);
                    maxDiscount.current = data.volumeResponse;



                    axios.get('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlaveragepricequantity').then(function (response) {
                        console.log(response);

                        // setShowLoginLoader(false);

                        const data = response.data;

                        if (data === null || data === "") {
                            console.log("Data is null")

                            alert("Data is null");

                        } else {
                            console.log(data);
                            averagePriceAndQuantity.current = data;

                            let budget = maxconstraint;

                            // Initialize an empty array to store JSON objects
                            var jsonArray = [];
                            let isFinished = false;
                            for (let i = 0; i < maxDiscount.current.length; i++)
                            {
                                if (!isFinished)
                                {
                                    var roiArray = [];
                                    var salesGrowthArray = [];
                                    var investmentArray = [];
                                    var predictedQtyArray = [];
                                    var predictedPriceArray = [];
                                    var previousQtyArray = [];
                                    var previousPriceArray = [];
                                    var upliftArray = [];
                                    for (let j = 0; j < averagePriceAndQuantity.current.length; j++)
                                    {
                                        console.log("Discount Index: " + i +  " product Index: " + j);
                                        let predictedQty = maxDiscount.current[i].output[0][j]; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? maxDiscount.current[i].output[0][j] : 0;
                                        let predictedPrice = 430 * (1 - i/100);

                                        let previousQty = averagePriceAndQuantity.current[j].averageSalesQuantity; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averageSalesQuantity;
                                        let previousPrice = averagePriceAndQuantity.current[j].averagePrice; //(maxDiscount.current[i] !== undefined && j <= maxDiscount.current[i].output.length) ? averagePriceAndQuantity.current[j].averagePrice : 0;

                                        let ROI = parseFloat(calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice));
                                        let salesGrowth = (predictedQty * predictedPrice) - (previousQty * previousPrice);
                                        let investment = -(predictedQty * (predictedPrice - previousPrice));
                                        let uplift = calculateUplift(predictedQty, previousQty);

                                        if (investment <= budget)
                                        {
                                            roiArray.push(ROI);
                                            predictedQtyArray.push(predictedQty);
                                            predictedPriceArray.push(predictedPrice);
                                            previousQtyArray.push(previousQty);
                                            previousPriceArray.push(previousPrice);
                                            salesGrowthArray.push(salesGrowth);
                                            investmentArray.push(investment);
                                            upliftArray.push(uplift);
                                        }
                                        else
                                        {
                                            isFinished = true;
                                            break;
                                        }
                                    }

                                    // Create a JSON object for each data entry
                                    var jsonObject = {
                                        "Discount": i,
                                        "predictedQty": predictedQtyArray,
                                        "predictedPrice": predictedPriceArray,
                                        "previousQty": previousQtyArray,
                                        "previousPrice": previousPriceArray,
                                        "ROI": roiArray,
                                        "SalesGrowth": salesGrowthArray,
                                        "Investment": investmentArray,
                                        "Uplift": upliftArray
                                    };

                                    // Push the JSON object into the jsonArray
                                    jsonArray.push(jsonObject);
                                }
                                else
                                {
                                    break;
                                }
                            }
                            // console.log("Maximize ROI: " + JSON.stringify(jsonArray));
                            console.log("Budget: " + JSON.stringify(jsonArray));
                            roiCalculationsResult.current = jsonArray;
                            setShowAutomateLoader(false);
                            setShowAutomateResults(true);
                        }
                       // saveActivity("run optimize")
                    }).catch(function (error) {
                        console.log(error);

                        alert(error);
                    })









                }
            }).catch(function (error) {
                console.log(error);

                alert(error);
            })
        }
        else
        {
            alert ("No constraint selected");
        }
    };

    const handleClearEventButtonClicked = ()=>{

        setLineChartXAxis([]);
        setLineChartYAxis([]);
    }

    const handlePredictButtonClicked = () => {

        if (currentPricesList.length === 0) {
            alert('current prices havent loaded');

        } else if (product === "select") {
            alert('please select a product');

        } else if (currentPrice === 0) {
            alert('current price has not been set');

        } else if (date === "0") {
            alert('please set a date');

            //}else if(discount === 0){


            //alert('please type a discount rate');

        } else if (period === 0) {

            alert('please select a discount period');

        } else {

            //calculate average price


            //get prices for "Jik Bleach Regular 750ml", "Jik Bleach Spring Fresh 750ml", "Jik Bleach Lemon 750ml"

            /**
             let jik_bleach_regular_price = (parseFloat(getFilteredPrice("Jik Bleach Regular 750ml", retailer))*(1 - (parseFloat(discount)/100)));
             let jik_bleach_springfresh_price = (parseFloat(getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer))*(1 - (parseFloat(discount)/100)));
             let jik_bleach_lemon_price = (parseFloat(getFilteredPrice("Jik Bleach Lemon 750ml", retailer))*(1 - (parseFloat(discount)/100)));
             **/
            let requestData;
            if (category.toLowerCase().includes("toilet")) {
                let discountPercentage = (discount / 100)
                let calculatedEffectivePrice = calculateEffectivePrice(parseFloat(getFilteredPrice(product, retailer)), discount, period);
                // const requestData = { modelId:4,fmcgId:34,date:"2023-08-01",product:product,category:category, subcategory:subcategory, size:size, Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price, Jik_Bleach_Spring_Fresh_750ml_price:jik_bleach_springfresh_price, Jik_Bleach_Lemon_750ml_price:jik_bleach_lemon_price}
                setEffectivePrice(calculatedEffectivePrice);
                requestData = {
                    modelId: 10,
                    fmcgId: 34,
                    date: date,
                    product: product,
                    category: category,
                    subcategory: subcategory,
                    size: size,
                    targetPrice: proposedPrice,
                    effectivePrice: calculatedEffectivePrice,
                    discount: discountPercentage,
                    period: period,
                    Jik_Bleach_Regular_750ml_price: 0,
                    Jik_Bleach_Spring_Fresh_750ml_price: 0,
                    Jik_Bleach_Lemon_750ml_price: 0,
                    requestType:"whatif"
                }

            } else {
                let calculatedEffectivePrice = calculateEffectivePrice(parseFloat(getFilteredPrice(product, retailer)), discount, period);
                let jik_bleach_regular_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Regular 750ml", retailer)), discount, period);
                let jik_bleach_springfresh_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Spring Fresh 750ml", retailer)), discount, period);
                let jik_bleach_lemon_price = calculateEffectivePrice(parseFloat(getFilteredPrice("Jik Bleach Lemon 750ml", retailer)), discount, period);

                setEffectivePrice(calculatedEffectivePrice);
                //alert(jik_bleach_regular_price + " "+ jik_bleach_springfresh_price + " "+ jik_bleach_lemon_price);
                let discountPercentage = (discount / 100)
                // const requestData = { modelId:4,fmcgId:34,date:"2023-08-01",product:product,category:category, subcategory:subcategory, size:size, Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price, Jik_Bleach_Spring_Fresh_750ml_price:jik_bleach_springfresh_price, Jik_Bleach_Lemon_750ml_price:jik_bleach_lemon_price}
                requestData = {
                    modelId: 9,
                    fmcgId: 34,
                    date: date,
                    product: product,
                    category: category,
                    subcategory: subcategory,
                    size: size,
                    targetPrice: proposedPrice,
                    effectivePrice: calculatedEffectivePrice,
                    discount: discountPercentage,
                    period: period,
                    Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price,
                    Jik_Bleach_Spring_Fresh_750ml_price: jik_bleach_springfresh_price,
                    Jik_Bleach_Lemon_750ml_price: jik_bleach_lemon_price,
                    requestType:"whatif"

                }
            }
            setLoading(true);
            axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {

                console.log(response);
                setLoading(false);
                const data = response.data;

                //got current prices successfully
                if (data.statusCode === "200") {

                    let inference_data_results = category.toLowerCase().includes("toilet") ? data.toiletCleanersResponseItemsList[0] : data.bleachesResponseItemsList[0];


                    let previous_quantities_results = data.previousQuantitiesList;


                    const previous_month_results_original = data.actualCategoryQuantitiesList

                    //order based on quantity
                    const previous_month_results = previous_month_results_original.sort((a, b) => {

                        return (b.quantity - a.quantity);


                    });
                    // alert(`${getYearFormatted(predictedDate)} ${previous_quantities_results[previous_quantities_results.length - 1].year}`)


                    let actualMonthQuantity = 0;
                    //check if previous quantities contain the current month
                    if ((getYearFormatted(date) === previous_quantities_results[previous_quantities_results.length - 1].year) && (getMonthFormatted(date).toLowerCase() === previous_quantities_results[previous_quantities_results.length - 1].month.toLowerCase())) {

                        actualMonthQuantity = previous_quantities_results[previous_quantities_results.length - 1].qty;
                        //get predicted month year and current month year
                        // let actualMonthMonth = previous_quantities_results[previous_quantities_results.length - 1].month;
                        // let actualMonthYear = previous_quantities_results[previous_quantities_results.length - 1].year;

                        //remove extra month element
                        previous_quantities_results.pop();

                    }


                    //filter to show previous quantities;

                    let previousQuantities = [];
                    let predictedQuantities = [];
                    let previousQuantitiesLabels = [];
                    let previousMonthLabels = [];
                    let previousMonthValues = [];
                    let predictedMonthLabels = [];
                    let predictedMonthValues = [];
                    let predictedMonthValuesLabels = [];
                    let lastPreviousQuantityMonthYear;

                    previous_quantities_results.forEach(function (value) {

                        previousQuantities.push(value.qty);
                        predictedQuantities.push(value.qty);
                        previousQuantitiesLabels.push(`${value.month.substring(0, 3)} ${value.year}`);
                        lastPreviousQuantityMonthYear = `${value.month} ${value.year}`


                    });


                    let predictedValue;
                    let predictionTotalSalesQty = 0;
                    //filter to add prediction
                    Object.keys(inference_data_results).forEach(key => {
                        // predictedMonthValues.push(parseFloat(inference_data_results[key]))

                        predictionTotalSalesQty += parseFloat(inference_data_results[key]);

                        //predictedMonthLabels.push(key.replace("Qty", ""));

                        predictedMonthValuesLabels.push({
                            label: key.replace("Qty", ""),
                            value: parseFloat(inference_data_results[key])
                        });

                        if (product.toLowerCase().replaceAll(" ", "").includes(key.replace("Qty", "").toLowerCase())) {

                            predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                            console.log("found " + key);

                        }

                    });

                    let predictedMonthValuesLabelsSorted = predictedMonthValuesLabels.sort(function (a, b) {


                        return (b.value - a.value);
                    });

                    predictedMonthValuesLabelsSorted.forEach((value) => {

                        predictedMonthLabels.push(value.label);
                        predictedMonthValues.push(value.value);
                    });


                    let previousMonthQtyTotal = 0;

                    previous_month_results.forEach(function (value) {
                        previousMonthValues.push(parseFloat(value.quantity))
                        previousMonthLabels.push(value.product)
                        previousMonthQtyTotal += parseFloat(value.quantity);


                    });


                    const previousMonthProduct = previous_month_results.filter((value) => {

                        return value.product === product;

                    });


                    //add if acrual value for predicted month exists only
                    if (actualMonthQuantity !== 0) {
                        previousQuantities.push(actualMonthQuantity);
                    }

                    previousQuantitiesLabels.push(getNextMonth(lastPreviousQuantityMonthYear));
                    predictedQuantities.push(predictedValue);
                    setTotalPredictedSalesQty(predictionTotalSalesQty);
                    setLineChartYAxis(previousQuantities);
                    setLineChartYAxisPredicted(predictedQuantities);
                    setLineChartXAxis(previousQuantitiesLabels);
                    setTotalPreviousMonthQty(previousMonthQtyTotal);
                    setPreviousMonthProductQty(previousMonthProduct[0].quantity)
                    setMarketViewArrayPredictedLabels(predictedMonthLabels);
                    setMarketViewArrayPredictedValues(predictedMonthValues);
                    setMarketViewArrayActualLabels(previousMonthLabels);
                    setMarketViewArrayActualValues(previousMonthValues);
                    setPreviousPrice(previous_quantities_results[previous_quantities_results.length - 1].avg_price);

                    // alert("previous price" + previous_quantities_results[previous_quantities_results.length - 1].avg_price);


                    //alert("got inference data successfully")
                } else {

                    alert(data.message);


                }


            }).catch(function (error) {
                setLoading(false)
                console.log(error);
            });


        }

        //
    }

    const handleCurrentViewButtonClicked = () => {
        //alert('currentview');
        setShowDoghnutChartWhatIf(false);
    }

    const handleMarketViewButtonClicked = () => {

        //alert('marketview');
        setShowDoghnutChartWhatIf(true);


    }

    const handleDontHaveAccount = () => {

        setShowLoginScreen(false);
        setShowRegistrationScreen(true);
    }

    const handleGoBackButtonInAccountRegistration = () => {
        setShowLoginScreen(true)
        setShowRegistrationScreen(false);
        setShowRegistrationAlert(false);

    }

//  <!--<RegistrationForm appFunction={registerUser}/>-->
    let header =<Header/>;
    let sidebar  =  <Sidebar onhandleAutomateStateChange={handleAutomateStateChange}
                             onhandleShowScenarioStateChange={handleShowScenarioStateChange}
                             onhandleShowSettingsStateChange = {handleShowSettingsStateChange}
                             onhandleShowmyPlanStateChange = {handleShowMyPlanStateChange}
                             onLogoutStateChange={handleLogoutStateChange}/>;

    let leverComponent;
    if (showScenario) {
        leverComponent = <Scenarios sectionname="Scenarios"/>
    } else {

        if(!showAutomate) {
            leverComponent = <Levers sectionname="Levers" proposedPrice={proposedPrice} currentPrice={currentPrice}
                                     onhandlePredictButton={handlePredictButtonClicked}
                                     onhandleDropDownStateChange={handleDropDownStateChange}
                                     onhandleTextBoxStateChange={handleTextBoxStateChange}/>

        }else{
            leverComponent = <AutomateLevers sectionname="Optimization Objectives & Constraints"  onhandleDropDownStateChange={handleDropDownStateChange}  onhandleOptimizeButton={handleOptimizeButtonClicked} objective = {objective} constraints = {constraint}  onhandleTextBoxStateChange={handleTextBoxStateChange}></AutomateLevers>
        }
    }
    let showChart;
    let showButtonGroup;
    if (!showScenario) {

       //show values
        if (showAutomate) {

            if(showAutomateResults) {
                console.log("Max Discount Output: " + maxDiscount)
               // const discountValues = maxDiscount.current.map(x => x["Discount"]).join(',');
              //  const volumeValues = maxDiscount.current.map(x => x["Output"]).join(',');

               // showChart = (discountValues.length > 0) ? <p>Discount: {discountValues}</p> : <p></p>;
               // showButtonGroup = (volumeValues.length > 0) ? <p>Volume: {volumeValues}</p> : <p></p>;
//alert("ok");
                showChart = <AutomateResults retailer = {retailerName} maxconstraint = {maxconstraint} objective = {objective} constraint = {constraint} target = {product} maxDiscount = {maxDiscount.current} calculationsResultArray = {roiCalculationsResult.current} productIndex = {productIndex}/>
                showButtonGroup = <p></p>
            }else{
                if(showAutomateLoader){
                    showChart = <CircularProgress/>;
                    showButtonGroup = <p></p>;
                }else {
                    showChart = <p></p>;
                    showButtonGroup = <p></p>;
                }
            }

        }else{

        if (!isLoading) {
            if (lineChartXAxis.length > 0 && lineChartYAxis.length > 0) {
                showButtonGroup = <><ButtonGroup variant="contained" aria-label="Basic button group">
                    <Button onClick={handleCurrentViewButtonClicked}>Product View</Button>
                    <Button onClick={handleMarketViewButtonClicked}>Market View</Button>


                </ButtonGroup>
                </>;
                if (showDoghnutChartWhatIf) {

                    showChart = <DoghnutChartWhatIfAnalaysisLayout predictedDate={formatDate(date)}
                                                                   actualDate={formatDate(previousDate)}
                                                                   actualValues={marketViewArrayActualValues}
                                                                   actualLabels={marketViewArrayActualLabels}
                                                                   predictedValues={marketViewArrayPredictedValues}
                                                                   predictedLabels={marketViewArrayPredictedLabels}></DoghnutChartWhatIfAnalaysisLayout>

                } else {

                     let charttitle = isValuePack(product)?`${product} Prediction (Singles)`:`${product} Prediction`;
                    showChart = <LineChart charttitle={charttitle}
                                           placeholdercharttitle={`${placeHolderProductLabel} Prediction`}
                                           showPlaceHolder={showPlaceHolderProductLabel} xAxis={lineChartXAxis}
                                           yAxis={lineChartYAxis}
                                           yAxisPredicted={lineChartYAxisPredicted}
                                           totalPredictedSales={totalPredictedSalesQty}
                                           currentPrice={currentPrice} proposedPrice={proposedPrice}
                                           effectivePrice={effectivePrice} previousPrice={previousPrice}
                                           previousMonthTotalQty={totalpreviousMonthQty}
                                           previousMonthSelectedProductQty={previousMonthProductQty}
                                           onHandleClearButtonClicked = {handleClearEventButtonClicked}
                                           product = {product}
                                           category = {category}
                                           subcategory = {subcategory}
                                           brand = {brand}
                                           size = {size}
                                           retailer = {retailer}
                                           date = {date}
                                           discount = {discount}
                                           price = {proposedPrice}
                    />
                }
            } else {
                showChart = <p></p>;
                showButtonGroup = <p></p>;
            }
        } else {
            showChart = <CircularProgress/>

        }
    }
    } else {
        //showChart = <ChartsLayout/>
        showChart = <p></p>
    }
   let layoutView;
    if(showLandingPage){
        //not logged in
        if (token === null || token === "") {
            layoutView = <LandingPage onHandleLoginButton={handleLoginButtonLandingPage}/>
        }else {
          //logged in

            if(showMyPlan || showSettings){
              layoutView = <>  {header}
                {sidebar}

                    <ComingSoonImage isShowMyPlan = {showMyPlan} isShowmySettings = {showSettings}/>

                  </>

            }else {
                layoutView = <>
                    {header}
                    {sidebar}
                    <div className="maincontent">


                        <Target sectionname="Target" img_url={imageUrl} category={selectedSubCategory}
                                onhandleDatePickerChange={handleDatePickerSelect}
                                onhandleDropDownStateChange={handleDropDownStateChange}/>


                        {leverComponent}

                        {showButtonGroup}
                        {showChart}


                    </div>
                </>
            }
        }
    }else {
        if (token === null || token === "") {
            if (showAuthentication) {
                if (showLoginScreen) {


                    layoutView =
                        <>
                            {header}
                            <div className="maincontent">
                                <LoginForm onLoginUser={loginUser}
                                           onDontHaveAccountStateChange={handleDontHaveAccount}
                                           onBackPress = {handleLoginBackPress}
                                           showLoader={showLoginLoader}
                                           showAlert={showLoginAlert}
                                           alertMessage={loginAlertMessage}/>
                            </div>

                        </>

                } else {
                    if (showRegistrationScreen) {
                        layoutView =
                            <>
                                {header}
                                <div className="maincontent"><RegistrationForm onRegisterUser={registerUser}
                                                                               onGoBackStateChange={handleGoBackButtonInAccountRegistration}
                                                                               showLoader={showRegistrationLoader}
                                                                               showAlert={showRegistrationAlert}
                                                                               alertMessage={registrationAlertMessage}
                                                                               alertType={registrationAlertType}/>
                                </div>
                            </>
                    }
                }
            } else {
                layoutView = <>
                    {header}
                    {sidebar}
                    <div className="maincontent">


                        <Target sectionname="Target" img_url={imageUrl}
                                onhandleDatePickerChange={handleDatePickerSelect}
                                onhandleDropDownStateChange={handleDropDownStateChange} category={selectedSubCategory}/>


                        {leverComponent}

                        {showButtonGroup}
                        {showChart}


                    </div>
                </>

            }

        } else {
            if (showMyPlan || showSettings) {
                layoutView = <>  {header}
                    {sidebar}

                        <ComingSoonImage isShowMyPlan = {showMyPlan} isShowmySettings = {showSettings}/>

                </>
            } else {
                layoutView = <>
                    {header}
                    {sidebar}
                    <div className="maincontent">


                        <Target sectionname="Target" img_url={imageUrl} category={selectedSubCategory}
                                onhandleDatePickerChange={handleDatePickerSelect}
                                onhandleDropDownStateChange={handleDropDownStateChange}/>


                        {leverComponent}

                        {showButtonGroup}
                        {showChart}


                    </div>
                </>
            }
        }

    }


//


  return (
      <>


          {layoutView}

        </>

  );
}

export default App;
