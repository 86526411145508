import jsPDF from "jspdf";
import latoregular from "../components/fonts/Lato-Regular.ttf";
import latobold from "../components/fonts/Lato-Bold.ttf";
import logo from "../components/images/tabirilogo.png";
import html2canvas from "html2canvas";
import {formatDateReport, getMonthFormatted} from "./Utilities";


function setProductNames(category){

    if(category.includes("bleach")){

        return "JIK Brands"

    }else if(category.includes("toilet")){

        return  "Harpic Brands"
    }else if(category.includes("diaper")){

        return "Diapers Brands"
    }

}
function generatePdfReport(inputs,imgData, type) {
    const doc = new jsPDF();
    const fontType = "Lato";
    const locale = "en-US";
    const fontColorHighLight = "#7030A0";
    const fontColorDefault = "#000000";
    doc.addFont(latoregular, "Lato", "regular");
    doc.addFont(latobold, "Lato", "bold");
    // console.log(doc.getFontList());
    doc.addImage(logo,'PNG', 10,5, logo.width, logo.height);
    doc.textWithLink("www.tabiri.ai", 10, 28, {url:'https://www.tabiri.ai'});
    doc.setFontSize(14);
    doc.setFont(fontType, "bold");
    doc.text("Date", 150, 18);
    doc.setFontSize(13);
    doc.setFont(fontType, "normal");
    let gen_date = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let formattedDate = gen_date.toLocaleDateString(locale, options)
    doc.text(formattedDate, 175, 18);
/**
    if(inputs.imageurl !== null && inputs.imageurl !==undefined && inputs.imageurl.length !==0){

        let productImage = new Image();
        productImage.crossOrigin = 'anonymous';
        productImage.onload = function (){
            try {

                doc.addImage(this, 'JPEG', 175, 22, 200, 200);
            }catch (e) {
                console.log(e);
                // alert(e);

            }

        };
        productImage.src = inputs.imageurl;

    }
 **/


    doc.setFontSize(18);
    doc.setFont(fontType, "bold");
    doc.text("Prediction Output - ", 10, 40);
    doc.setTextColor(fontColorHighLight);
    doc.text(`${type ==="whatif"?'"What if Analysis"':'"Optimize"'}`,70,40);
    doc.setFontSize(15);
    //left side
    let titles_y_position = 60;
    let titles_y_position_offset = 3;
    let line_y_position = 61;
    doc.text("Target", 10, titles_y_position);
    doc.setDrawColor(fontColorHighLight)
    doc.line(10, line_y_position, 26, line_y_position);

    //right side
    doc.text(`${type === "whatif"?"Levers Applied":"Objectives & Constraints"}`, 130, titles_y_position);
   if(type === "whatif") {
       doc.line(130, line_y_position, 165, line_y_position);
   }else{
       doc.line(130, line_y_position, 190, line_y_position);
   }
    // Add the details
    //left side
    doc.setFontSize(13);
    doc.setFont(fontType, "normal");
    doc.setTextColor(fontColorDefault)
    //targets
    let x_position_target_titles = 10;
    doc.text("Product: ", x_position_target_titles, titles_y_position +10 - titles_y_position_offset);
    doc.text("Category: ", x_position_target_titles, titles_y_position+20- titles_y_position_offset);
    doc.text("Retailer:" , x_position_target_titles, titles_y_position+30- titles_y_position_offset);
    doc.text("Month Predicted: ", x_position_target_titles, titles_y_position+40- titles_y_position_offset);

    doc.setFontSize(12);
    doc.setFont(fontType, "bold");
    //target values
    let x_position_target_values = 50
    if(inputs.showStackBar){
        let title = setProductNames(inputs.category.toLowerCase());
        // ==="bleach"?"Jik Brands":"Harpic Brands";
        doc.text(title, x_position_target_values, titles_y_position+10- titles_y_position_offset);
    }else{
        doc.text(inputs.product, x_position_target_values, titles_y_position+10- titles_y_position_offset);
    }

    doc.text(inputs.category, x_position_target_values, titles_y_position+20- titles_y_position_offset);

    if(inputs.retailers.length === 2){
        doc.text(inputs.retailers[0] +" & "+inputs.retailers[1], x_position_target_values, titles_y_position + 30 - titles_y_position_offset);

    }else {
        doc.text(inputs.retailer, x_position_target_values, titles_y_position + 30 - titles_y_position_offset);
    }
    let predicted_date = new Date(inputs.date); // Example date

    let options_predicted_date = { month: 'short', year: 'numeric' };
    let formattedPredictedDate = predicted_date.toLocaleDateString(locale, options_predicted_date);

    doc.text(formattedPredictedDate, x_position_target_values, titles_y_position+40- titles_y_position_offset);


    //levers
    doc.setFontSize(13);
    let x_position_levers_titles = 130;
    doc.setFont(fontType, "normal");
    if(type === "whatif") {
        doc.text("Price:", x_position_levers_titles, titles_y_position + 10 - titles_y_position_offset);
        doc.text("Discount: ", x_position_levers_titles, titles_y_position + 20 - titles_y_position_offset);
        doc.text("Period: ", x_position_levers_titles, titles_y_position + 30 - titles_y_position_offset);
    }else{
        doc.text("Objective:", x_position_levers_titles, titles_y_position + 10 - titles_y_position_offset);
        doc.text("Constraint: ", x_position_levers_titles, titles_y_position + 20 - titles_y_position_offset);

    }

    //levers values
    doc.setFontSize(12);
    let x_position_levers_values = 155;
    doc.setFont(fontType, "bold");
    if(type === "whatif") {
        doc.text(`${inputs.price}`, x_position_levers_values, titles_y_position + 10 - titles_y_position_offset);
        doc.text(inputs.discount + "%", x_position_levers_values, titles_y_position + 20 - titles_y_position_offset);
        doc.text(`${inputs.period} ${inputs.period > 1 ? 'weeks' : 'week'}`, x_position_levers_values, titles_y_position + 30 - titles_y_position_offset);
    }else{
        doc.text(`${inputs.objective}`, x_position_levers_values, titles_y_position + 10 - titles_y_position_offset);
        doc.text(`${inputs.constraint === "Max Discount"?inputs.maxconstraint + "% Max Discount": inputs.maxconstraint + "Ksh Max Budget"}`, x_position_levers_values, titles_y_position + 20 - titles_y_position_offset);

    }
    doc.setTextColor(fontColorHighLight);
    doc.setFontSize(17);
    doc.text('Results', 10, 120);
    doc.line(10, 122, 31, 122);


    let img = new Image();
    img.onload = function() {
        // Calculate image dimensions
        let maxWidth;
        let maxHeight;
        if(type === "whatif"){
            maxWidth = 200;
            maxHeight = 140;
        }else{
             maxWidth = 190;
             maxHeight = 170;
        }

        const aspectRatio = img.width / img.height;

        // Calculate the new width and height based on the aspect ratio
        let newWidth = img.width;
        let newHeight = img.height;
        if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = newWidth / aspectRatio;
        }
        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = newHeight * aspectRatio;
        }


        // Add the image to the PDF
        doc.addImage(this, 'PNG', 10, 125, newWidth, newHeight);

        let bottom_y_position = type === "whatif"?260:250;
        doc.text("Conclusion", 10, bottom_y_position);
        doc.line(10, bottom_y_position +1, 42,bottom_y_position +1);
        doc.setFontSize(12);
        doc.setFont(fontType, "normal");
        doc.setTextColor(fontColorDefault);
        let conclusion_text;
        if(type === "whatif") {
            conclusion_text = `If you ran ${inputs.discount}% discount for ${inputs.showStackBar === true? setProductNames(inputs.category.toLowerCase()):inputs.product} at ${inputs.retailer} for ${inputs.period} ${inputs.period > 1 ? "weeks" : "week"} in ${formattedPredictedDate}; we expect a sales uplift/decline of ${inputs.salesUplift}%, total sales of ${inputs.sales} units (Ksh ${inputs.salesAmount}) and a Sales RoI of ${(inputs.ROI < 100) ? `${inputs.ROI}%` : `100%+`}`;





        }else{
            if(inputs.retailers.length === 2){

                conclusion_text = `Run ${inputs.maxconstraint} ${inputs.constraint === "Max Discount" ? "%" : "Ksh"} in ${inputs.retailer}, this will lead to a total sales volume of ${inputs.sales.toFixed(0)} which is a sales uplift of ${inputs.salesUplift}% and Roi of ${inputs.ROI}%`


            }else {


                conclusion_text = `Run ${inputs.maxconstraint} ${inputs.constraint === "Max Discount" ? "%" : "Ksh"} in ${inputs.retailer}, this will lead to a total sales volume of ${inputs.sales.toFixed(0)} which is a sales uplift of ${inputs.salesUplift}% and Roi of ${inputs.ROI}%`
            }
            }
        justifyText(doc, conclusion_text, 10, bottom_y_position+6, 500, 7);
        doc.setFont(fontType, "bold");
        let footer_text = "Any questions? contact us at customercare@tabiri.ai";
        doc.text(footer_text, 10, bottom_y_position+25);
        //let date = new Date().toLocaleString().trim().replace(" ", "").replace(",", "").toLowerCase();
        let date = formatDateReport(new Date());
        //let date = new Date().getTime();
        // Save the PDF
        doc.save(`${inputs.product.replace(" ","_").replace("  ","_")}_${inputs.retailer}_${getMonthFormatted(inputs.date)}_${date}.pdf`);
    };
    // Replace 'image.png' with the path to your image
    img.src = imgData;

}

function justifyText(doc, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '';
    let currentY = y;

    for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const lineWidth = doc.getStringUnitWidth(line + word) * doc.internal.getFontSize();

        if (lineWidth < maxWidth) {
            line += (line ? ' ' : '') + word;
        } else {
            const spaceCount = line.split(' ').length - 1;
            const spaceWidth = (maxWidth - lineWidth) / spaceCount;

            doc.text(line, x, currentY);
            line = word;
            currentY += lineHeight;
        }
    }

    doc.text(line, x, currentY);
}


function downLoadChartImage( inputs, type =  "whatif",  imageRef) {


    if (imageRef) {
        if(type === "whatif") {
            document.getElementById("chartbuttonslayout").style.visibility = "hidden";
        }
        html2canvas(imageRef)
            .then((canvas) => {
                let imgData = canvas.toDataURL('image/png');
              if(type === "whatif") {
                  document.getElementById("chartbuttonslayout").style.visibility = "visible";
              }
                /**
                 let link = document.createElement('a');
                 link.href = imgData;
                 link.download = 'chart.png';
                 link.click();
                 **/
                generatePdfReport(inputs, imgData, type);
            })
            .catch((error) => {
                console.error('Error exporting chart:', error);
                alert(error)
            });
    }

}

export {downLoadChartImage}