// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linechart_linechartlayout__CdR1w{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-wrap: nowrap;
    padding-top: 1%;
    padding-bottom: 5%;

}

.linechart_linechartlayoutleft__z\\+X6U{
    width: 80%;
}
.linechart_linechartlayoutleft__z\\+X6U div{
    width: 100%;
}
.linechart_linechartlayoutright__tpKL1{
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/linechart.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;IACX,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,kBAAkB;;AAEtB;;AAEA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;;;AAGvB","sourcesContent":[".linechartlayout{\r\n  display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n    width: 100%;\r\n    flex-wrap: nowrap;\r\n    padding-top: 1%;\r\n    padding-bottom: 5%;\r\n\r\n}\r\n\r\n.linechartlayoutleft{\r\n    width: 80%;\r\n}\r\n.linechartlayoutleft div{\r\n    width: 100%;\r\n}\r\n.linechartlayoutright{\r\n    width: 20%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linechartlayout": `linechart_linechartlayout__CdR1w`,
	"linechartlayoutleft": `linechart_linechartlayoutleft__z+X6U`,
	"linechartlayoutright": `linechart_linechartlayoutright__tpKL1`
};
export default ___CSS_LOADER_EXPORT___;
