// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_mainlayoutauthentication__sWz6X{

    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

}

.login_formlayout__sARiP{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 40%;

}
.login_formcomponents__7zK-Z{

    margin-top: var(--textfields_margintopdown) !important;
    margin-bottom: var(--textfields_margintopdown) !important;
    font-weight: var(--font_medium);
}

.login_formcomponentsbutton__LJWy0{

    margin-top: var(--form_submit_button_margintop) !important;
    margin-bottom: var(--textfields_margintopdown) !important;
}

.login_link__JApId{

    color: black;
    font-weight: var(--font_medium);
    text-decoration: none;
    font-size: 17px;
    margin-top: var(--textfields_margintopdown);
    padding-bottom: var(--textfields_margintopdown);

}
.login_link__JApId:hover{
    text-decoration: underline;
    color: #666666;


}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/login.module.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;;AAErB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,iBAAiB;IACjB,UAAU;;AAEd;AACA;;IAEI,sDAAsD;IACtD,yDAAyD;IACzD,+BAA+B;AACnC;;AAEA;;IAEI,0DAA0D;IAC1D,yDAAyD;AAC7D;;AAEA;;IAEI,YAAY;IACZ,+BAA+B;IAC/B,qBAAqB;IACrB,eAAe;IACf,2CAA2C;IAC3C,+CAA+C;;AAEnD;AACA;IACI,0BAA0B;IAC1B,cAAc;;;AAGlB","sourcesContent":[".mainlayoutauthentication{\r\n\r\n    width: 90%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n\r\n}\r\n\r\n.formlayout{\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n    flex-wrap: nowrap;\r\n    width: 40%;\r\n\r\n}\r\n.formcomponents{\r\n\r\n    margin-top: var(--textfields_margintopdown) !important;\r\n    margin-bottom: var(--textfields_margintopdown) !important;\r\n    font-weight: var(--font_medium);\r\n}\r\n\r\n.formcomponentsbutton{\r\n\r\n    margin-top: var(--form_submit_button_margintop) !important;\r\n    margin-bottom: var(--textfields_margintopdown) !important;\r\n}\r\n\r\n.link{\r\n\r\n    color: black;\r\n    font-weight: var(--font_medium);\r\n    text-decoration: none;\r\n    font-size: 17px;\r\n    margin-top: var(--textfields_margintopdown);\r\n    padding-bottom: var(--textfields_margintopdown);\r\n\r\n}\r\n.link:hover{\r\n    text-decoration: underline;\r\n    color: #666666;\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainlayoutauthentication": `login_mainlayoutauthentication__sWz6X`,
	"formlayout": `login_formlayout__sARiP`,
	"formcomponents": `login_formcomponents__7zK-Z`,
	"formcomponentsbutton": `login_formcomponentsbutton__LJWy0`,
	"link": `login_link__JApId`
};
export default ___CSS_LOADER_EXPORT___;
