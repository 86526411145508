// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customlegend_legendcontainer__GRJc4{

    margin-top: 55px;
    display: grid;
    column-gap: 15px;
    row-gap: 15px;
    grid-template-columns:auto auto ;

}
.customlegend_legendlayout__ONTqu{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

}

.customlegend_legendbullet__bG7G8{
 width: 45px;
  height: 25px;
    background-color: black;


}

.customlegend_legendtext__\\+7sXk{
 font-weight: var(--font_medium);
 font-size: 15px;
 margin-left: 13px;


}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .customlegend_legendcontainer__GRJc4{




        grid-template-columns:auto ;

    }
}



/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 601px) and  (max-width: 1200px) {
    .customlegend_legendcontainer__GRJc4{


        grid-template-columns:auto  ;

    }
}

`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/customlegend.module.css"],"names":[],"mappings":"AAAA;;IAEI,gBAAgB;IAChB,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,gCAAgC;;AAEpC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;;AAEnB;;AAEA;CACC,WAAW;EACV,YAAY;IACV,uBAAuB;;;AAG3B;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,iBAAiB;;;AAGlB;;AAEA,iDAAiD;AACjD;;IAEI;;;;;QAKI,2BAA2B;;IAE/B;AACJ;;;;AAIA,qDAAqD;AACrD;IACI;;;QAGI,4BAA4B;;IAEhC;AACJ","sourcesContent":[".legendcontainer{\r\n\r\n    margin-top: 55px;\r\n    display: grid;\r\n    column-gap: 15px;\r\n    row-gap: 15px;\r\n    grid-template-columns:auto auto ;\r\n\r\n}\r\n.legendlayout{\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: flex-start;\r\n  align-items: center;\r\n  flex-wrap: nowrap;\r\n\r\n}\r\n\r\n.legendbullet{\r\n width: 45px;\r\n  height: 25px;\r\n    background-color: black;\r\n\r\n\r\n}\r\n\r\n.legendtext{\r\n font-weight: var(--font_medium);\r\n font-size: 15px;\r\n margin-left: 13px;\r\n\r\n\r\n}\r\n\r\n/* Extra small devices (phones, 600px and down) */\r\n@media only screen and (max-width: 600px) {\r\n\r\n    .legendcontainer{\r\n\r\n\r\n\r\n\r\n        grid-template-columns:auto ;\r\n\r\n    }\r\n}\r\n\r\n\r\n\r\n/* Medium devices (landscape tablets, 768px and up) */\r\n@media (min-width: 601px) and  (max-width: 1200px) {\r\n    .legendcontainer{\r\n\r\n\r\n        grid-template-columns:auto  ;\r\n\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"legendcontainer": `customlegend_legendcontainer__GRJc4`,
	"legendlayout": `customlegend_legendlayout__ONTqu`,
	"legendbullet": `customlegend_legendbullet__bG7G8`,
	"legendtext": `customlegend_legendtext__+7sXk`
};
export default ___CSS_LOADER_EXPORT___;
