import styles from './stylesheets/comingsoonimage.module.css'
import comingsoon from "./images/aitools/animation/comingsoon.json";
import contactus from "./images/aitools/animation/contactus.json";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
function ComingSoonImage(props) {
   let animation, text;
   if(props.isShowMyPlan){
      animation = comingsoon;
      text = "We are building this feature and we are almost done!";
   }else {
      animation = contactus;
      text = "Contact us to change your settings";
   }

   return <div className={styles.layout}>

      <Player
          autoplay
          loop
          src={animation}
          style={{ height: '200px', width: '300px' }}
      >
         <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
      </Player>
      <p className={styles.comingsoontext}>{text}</p>
   </div>

}

export default ComingSoonImage;