import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import styles from "./stylesheets/multipleselect.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {

            width: 200,
            paddingLeft:10,
            paddingRight:10,

        },
    },
};

const retailers = [
    'Naivas',
    'Carrefour',

];

export default function MultipleSelect(props) {
    const [retailer, setRetailers] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },} = event;
        setRetailers(typeof value === 'string' ? value.split(',') : value);
       // alert(event.target.value);
        let response = {type:"multipleretailers", value:typeof value === 'string' ? value.split(',') : value}
        props.onhandleDropDownStateChange(response);
    };
    //  <InputLabel id="demo-multiple-checkbox-label">Retailers</InputLabel>

    return (
        <div className={styles.selectcontainer}>

            <label  htmlFor="multiple-checkbox" className={styles.selectlabel}>{props.labeltitle}</label>
                <Select

                    id="multiple-checkbox"
                    multiple
                    value={retailer}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    sx={{ height: '32px', width:'200px', marginLeft:'7px', outline:'none' }}
                >
                    {retailers.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={retailers.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>

        </div>
    );
}
