import {useRef, useState} from "react";
import {TextField, Button, InputAdornment, CircularProgress, Alert} from "@mui/material";
import styles from './stylesheets/login.module.css';
import {Lock} from "@mui/icons-material";
import AlertDialog from "./AlertDialog";
//let email= "", password = "";
function LoginForm(prop) {
    const email = useRef("")
    const password = useRef("")
    const[emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const[passwrdErrorMesssage, setPasswordErrorMessage]  = useState("");

    let loader, alert;



    // Handle input change
    const handleChange = (e) => {
      switch (e.target.id){

          case "email":

              email.current  = e.target.value;

              break;

          case "password":

              password.current = e.target.value;

              break;


      }
    };

    const handleClickBackEvent = (event)=>{

        prop.onBackPress();

    }

    const handleSubmit = (event) => {

        try
        {
            setEmailError(false)
            setEmailErrorMessage("")
            setPasswordError(false);
            setPasswordErrorMessage("");

            if(email.current.length === 0 && password.current.length === 0) {

                setEmailError(true)
                setEmailErrorMessage("email is empty")
                setPasswordError(true);
                setPasswordErrorMessage("password is empty");

            }else if(email.current.length === 0){

                setEmailError(true)
                setEmailErrorMessage("email is empty")


            }else if(password.current.length === 0){

                setPasswordError(true);
                setPasswordErrorMessage("password is empty");

            }else{

                const formData = {email:email.current, password:password.current}

                prop.onLoginUser(formData);
               // alert(formData.email + " "+ formData.password);



            }


        }
        catch (error)
        {
            console.error('Error submitting form:', error);

            alert(error);
        }
    }
    const handleDontHaveAccountClick = (event)=>{
        console.log("click dont have account");
        prop.onDontHaveAccountStateChange();

    };

    if(prop.showLoader){
        loader = <CircularProgress/>;
    }

    if(prop.showAlert){
        alert = <Alert variant="filled" severity="error">
            {prop.alertMessage}
        </Alert>
    }

    return (
        <div className={styles.mainlayoutauthentication}>
            <h2>Login</h2>
            {alert}
            <form  className={styles.formlayout}>
                <TextField id="email" className={styles.formcomponents
                } fullWidth={true}  label="Email" type="email" inputProps={{maxLength:100}} variant="filled"
                          onChange={handleChange} error={emailError} helperText={emailErrorMessage}></TextField>



                <TextField id="password" type="password" label = "Password" error={passwordError} helperText={passwrdErrorMesssage} inputProps={{maxLength:20}} className={styles.formcomponents} fullWidth={true}  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lock />
                        </InputAdornment>
                    ),
                }}   variant="filled"
                         onChange={handleChange} ></TextField>


                <Button variant="contained" className={styles.formcomponentsbutton} fullWidth={true} onClick={handleSubmit}>Login</Button>
            </form>

            <a href="#"  className={styles.link} onClick={handleDontHaveAccountClick}>Dont have account?</a>
            <a href="#"  className={styles.link} onClick={handleClickBackEvent}>Go Home</a>

            {loader}

        </div>
    )

    /**
    return (
      <div className="maincontent">
        <form onSubmit={handleSubmit}>
          <label>Email:
            <input
              type="text"
              name="email" 
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label>Password:
            <input
              type="password"
              name="password" 
              value={formData.password}
              onChange={handleChange}
            />
          </label>
          <input type="submit" value="Log In" />
        </form>
      </div>
    )
     **/
  }

export default LoginForm