// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectcontainer{
    margin-right: 12px;



}

.select{
    margin-left: 7px;

}
.selectlabel{
    font-weight: var(--font_medium);
    font-size: 15px;
    margin-right: 6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/multipleselect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;;;AAItB;;AAEA;IACI,gBAAgB;;AAEpB;AACA;IACI,+BAA+B;IAC/B,eAAe;IACf,4BAA4B;AAChC","sourcesContent":[".selectcontainer{\r\n    margin-right: 12px;\r\n\r\n\r\n\r\n}\r\n\r\n.select{\r\n    margin-left: 7px;\r\n\r\n}\r\n.selectlabel{\r\n    font-weight: var(--font_medium);\r\n    font-size: 15px;\r\n    margin-right: 6px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
