import DropDown from "./DropDown";
import EventButton from "./EventButton";
import TextBox from "./TextBox";
import styles from './stylesheets/dropdownlayout.module.css';
import MultipleSelect from "./MultipleSelect";
function AutomateLevers(prop) {

   const  objectiveDropDownData = [{objective:"Maximize Volume"}, {objective:"Maximize Sales ROI"}, {objective:"Maximize Value Portfolio"}, {objective:"Maximize Sales ROI Portfolio"}];
   const constraintsDropDownData = [{constraints:"Max Discount"}, {constraints:"Max Budget"}];

   let title, placeholder;

   if(prop.constraints === "Max Discount"){


       title =  "Enter Max Disc";
       placeholder = "max discount";

   }else{
       title =  "Enter Max Budget";
       placeholder = "max budget";
   }

   //<MultipleSelect  labeltitle = "Retailers"></MultipleSelect>

    return( <div className="dropdownsection">
            <h2 className={styles.dropdownsectiontitle}>{prop.sectionname}</h2>
            <hr/>

            <div className={styles.dropdownlayout}>

                <DropDown labeltitle = "Objective" labelid = "objective" onhandleDropDownStateChange = {prop.onhandleDropDownStateChange} dropDownData =  {objectiveDropDownData}/>
                <DropDown labeltitle = "Constraints" labelid = "constraints" onhandleDropDownStateChange = {prop.onhandleDropDownStateChange} dropDownData =  {constraintsDropDownData}/>
                <TextBox labeltitle = {title} labelid = "maxconstraint" placeholder = {placeholder} type = "text" disabled = {false} onhandleTextBoxStateChange = {prop.onhandleTextBoxStateChange}  />

            </div>
            <hr/>
            <div className={styles.dropdownbuttonlayout}>
                <EventButton  buttonname = "Optimize" buttonid = "optimizebutton" onhandleOptimizeButton = {prop.onhandleOptimizeButton}/>
            </div>
        </div>


    )


}

export default AutomateLevers