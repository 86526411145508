import styles from './stylesheets/textbox.module.css'

function TextBox(props){

 const handleChange = (event)=>{

     if(props.labelid === "discount") {
         let response = {type: "discount", value: event.target.value}

         props.onhandleTextBoxStateChange(response);

     }else if(props.labelid === "maxconstraint"){
         let response = {type: "maxconstraint", value: event.target.value}

         props.onhandleTextBoxStateChange(response);

     }else if(props.labelid === "price"){
         let response = {type: "price", value: event.target.value}

         props.onhandleTextBoxStateChange(response);

     }
 };



 const setTextBoxValues = ()=>{
       let value = props.value;
     if(props.labelid === "discount") {


         if ( props.value === 0){
             value = "";
         }



     }else if(props.labelid === "price"){


         if(props.value === 0){
             value = "";
         }

     }

     return value;

 }
 let textBoxValue = setTextBoxValues();

    return(
        <div className={styles.textboxcontainer}>
            <label className={styles.textboxlabel} htmlFor={props.labelid}>{props.labeltitle}</label>
        <input className={styles.textbox} type={props.type} placeholder={props.placeholder} onChange={handleChange} value={textBoxValue} disabled={props.disabled}/>
        </div>
    )




}

export default TextBox;