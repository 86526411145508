import styles from './stylesheets/landingpage.module.css'
import logo from './images/tabirilogo.png'
import colgate from './images/brands/colgate2.png'
import kimberlyclark from './images/brands/kimberlyclark.png'
import reckitt from './images/brands/reckitt.png'
import simon from './images/team/simon.png'
import isaac from './images/team/isaac.png'
import gabriel from './images/team/gabriel.png'
import belinda from './images/team/belinda.png'
import jesse from './images/team/jesse.png'
import defaultphoto from './images/team/default_team.png'
import linkedin from './images/team/social_media_icons/linkedin.png'

import benefitsstep1 from './images/aitools/benefits/1.png'
import benefitsstep2 from './images/aitools/benefits/2.png'
import benefitsstep3 from './images/aitools/benefits/3.png'
import landingpage from './images/aitools/banner/landingpageholder.jpg'
import ingest from './images/aitools/howitworks/ingest-data@2x.png'
import customizemodel from './images/aitools/howitworks/customize-model@2x.png'
import predict from './images/aitools/howitworks/predict@2x.png'
import competitormoves from './images/aitools/benefits/competitor-moves@2x.png';
import optimize from './images/aitools/benefits/optimize@2x.png';
import highroi from './images/aitools/benefits/high-roi@2x.png';
import {Close, Menu} from "@mui/icons-material";
import {useState} from "react";
import ContactUs from "./ContactUs";
import LandingPageHeader from "./LandingPageHeader";
import LandingpageFooter from "./LandingpageFooter";



function LandingPage(props){
    const [width, setWidth] = useState("-240px");
    const [showContactUs, setShowContactUs] = useState(false);
    const clickLoginButton =  (event)=>{

        props.onHandleLoginButton();

    }

    const clickCloseNavBar = (event)=>{

        setWidth("-240px");
        setShowContactUs(false)
    }
    const clickOpenNavBar = (event)=>{

        setWidth("0");
    }

    const clickContactUsNavBar = (event)=>{
        setWidth("-240px");
        setShowContactUs(true)

    }
    const clickContactUs = (event)=>{

        setShowContactUs(true)

    }

    const clickHyperLink = (event)=>{

        setShowContactUs(false)

    }

    const clickDemoButton = (event)=>{


        clickContactUsNavBar();


    }

    function scrollToSection() {
        let hash = window.location.hash;
        if (hash) {
            let target = document.querySelector(hash);
            if (target) {
                target.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    // Scroll to section when the page loads
    window.onload = scrollToSection;


// <!-- <h3>TABIRI <span>AI</span></h3>-->
    //  <img className={styles.bannerimage} src={landingpage}/>
   let layoutView;
    if(showContactUs){
        layoutView = <ContactUs/>;
    }else{
         layoutView = <>
            <div className={styles.mainsection} id={"home"}>
                <div className={styles.mainsectionleft}>

                    <div className={styles.taglineslayout}>
                        <h1>Improving <span>Sales RoI</span> for FMCGs using <span>Machine Learning</span></h1>
                        <p>Put your own & competitor historical data to work! </p>
                        <button className="default_button_landingpage" onClick={clickDemoButton}>Request Demo</button>

                    </div>


                </div>
                <div className={styles.mainsectionright}>

                </div>

            </div>

            <div className={styles.contentsection} id={"howitworks"}>
                <h2 className={styles.sectiontitle}>How does it work?</h2>
                <div className={styles.contentsubsection}>
                    <div>
                        <h3 className={styles.subsectiontitle}>Ingest Data</h3>
                        <img className={styles.subsectionimage} src={ingest}/>

                        <p className={styles.subsectiontext}>We take in your PoS (Incl. competitor historical) data that you have collected over the years</p>
                        <p className={styles.subsectiontext}>	Merge it our own proprietary data (granular pricing and promo data), inflation, exch rate, weather, seasons, holidays and more.</p>
                    </div>
                    <div>
                        <h3 className={styles.subsectiontitle}>Customize Models</h3>
                        <img className={styles.subsectionimage} src={customizemodel}/>

                        <p className={styles.subsectiontext}>We customize our model to your scenario; there is no one category, size or retail channel that is the same.</p>
                        <p className={styles.subsectiontext}>Once trained and tested, the models are deployed in an easy-to-use web-portal or your systems via api for live prediction</p>
                    </div>
                    <div>
                        <h3 className={styles.subsectiontitle}>Predict and Optimize</h3>
                        <img className={styles.subsectionimage} src={predict}/>

                        <p className={styles.subsectiontext}>Run simple <b>‘what if’</b> analysis (e.g. if we ran 20% what’s the vol uplift) and select highest Roi interventions.  </p>
                        <p className={styles.subsectiontext}>Optimize multiple interventions given constraints (e.g. budget) and get highest Roi brands or portfolio level.</p>
                    </div>

                </div>
            </div>



            <div className={[styles.contentsubsectionverticallayout, styles.defaultbackgroundcolor].join(" ")}  id={"benefits"}>
                <h2 className={styles.sectiontitle}>What's the value for FMCG'S?</h2>

                <div className={styles.contentsubsectionvertical}>


                    <div>
                        <img className={styles.subsectionimagebenefits} src={highroi}/>
                    </div>
                    <div>
                        <h3 className={styles.subsectiontitle}>Select <span>High RoI</span>  </h3>
                        <p className={styles.subsectiontext}>Test your promos, price changes or in-store marketing in multiple retailers and sales growth or cost saving opportunities.</p>
                    </div>

                </div>
                <div  className={styles.contentsubsectionvertical}>
                    <div>
                        <h3 className={styles.subsectiontitle}>Predict Impact Of <span>Competitor Moves</span> </h3>
                        <p className={styles.subsectiontext}>Predict the impact of competitors’ actions on your sales as they happen and decide if and how to respond timely. </p>

                    </div>
                    <div>
                        <img className={styles.subsectionimagebenefits} src={competitormoves}/>
                    </div>

                </div>

                <div  className={styles.contentsubsectionvertical}>

                    <div>
                        <img className={styles.subsectionimagebenefits} src={optimize}/>
                    </div>
                    <div>
                        <h3 className={styles.subsectiontitle}>AI for <span>Portfolio Decision</span></h3>
                        <p className={styles.subsectiontext}>Let our ML models/AI agent automatically find the best combination of intervention that will lead to the highest RoI at the portfolio or brand level given constraint like budget or max discount.</p>
                    </div>
                </div>

            </div>







            <div className={styles.contentsection}>
                <h2 className={styles.sectiontitle}>Our Partners</h2>
                <div className={styles.contentsubsection}>

                    <div className={styles.trustedbylayout}>
                        <img className={styles.trustedbyimg} src={reckitt}/>
                        <p className={styles.trustedbytext}>Reckitt Benckiser</p>
                    </div>

                    <div className={styles.trustedbylayout}>
                        <img className={styles.trustedbyimg} src={colgate}/>
                        <p className={styles.trustedbytext}>Colgate</p>
                    </div>

                    <div className={styles.trustedbylayout}>
                        <img className={styles.trustedbyimg} src={kimberlyclark}/>
                        <p className={styles.trustedbytext}>Kimberly Clarks </p>
                    </div>

                </div>
            </div>


            <div className={styles.contentsection} id={"aboutus"}>
                <h2 className={styles.sectiontitle}>Our Team</h2>
                <div className={styles.contentsubsection}>
                    <div className={styles.teamlayout}>
                        <img className={styles.teamphoto} src={simon}/>
                        <a href={"https://www.linkedin.com/in/ngigisimon/"}> <img className={styles.teamlinkedinlogo} src={linkedin}/></a>
                        <p className={styles.teamname}>Simon </p>
                        <p className={styles.teamrole}>CEO & Data Scientist</p>
                        <p className={styles.teamexperience}>Ex-Bain, INSEAD MBA, BSC Comp Science
                        </p>


                    </div>

                    <div className={styles.teamslayout}>
                        <img className={styles.teamphoto} src={isaac}/>
                        <a href={"https://www.linkedin.com/in/isaac-sichangi-525b4121b/"}><img className={styles.teamlinkedinlogo}src={linkedin}/></a>
                        <p className={styles.teamname}>Isaac</p>
                        <p className={styles.teamrole}>CTO & Data Scientist</p>
                        <p className={styles.teamexperience}>2X Founder, BSC Comp Science,DayStar University
                        </p>

                    </div>

                    <div className={styles.teamslayout}>
                        <img className={styles.teamphoto} src={gabriel}/>
                        <a href={"https://www.linkedin.com/in/gabriel-odero-114b11177/"}> <img className={styles.teamlinkedinlogo} src={linkedin}/></a>
                        <p className={styles.teamname}>Gabriel</p>
                        <p className={styles.teamrole}>Backend Engineer & Data Scientist</p>
                        <p className={styles.teamexperience}>MSC Comp Science, Coventry University,UK
                        </p>


                    </div>



                </div>
                <div className={styles.contentsubsection}>
                    <div className={styles.teamslayout}>
                        <img className={styles.teamphoto} src={belinda}/>
                        <a href={"https://www.linkedin.com/in/belinda-chepkemoi-068b61164"}> <img className={styles.teamlinkedinlogo} src={linkedin}/></a>
                        <p className={styles.teamname}>Belinda</p>
                        <p className={styles.teamrole}>Finance & Ops Lead</p>
                        <p className={styles.teamexperience}>BCom Accounting, University of Nairobi</p>


                    </div>

                </div>
            </div>



        </>
    }
    /**
     * <div className={styles.menuicon} onClick={clickOpenNavBar}> <Menu ></Menu>
     *             <img src={logo}/></div>
     *         <nav className={styles.mainnav}>
     *             <div className={styles.applogolayout}>
     *                 <img src={logo}/>
     *
     *             </div>
     *
     *             <ul className={styles.headernavlist}>
     *                 <li><a href="#home" onClick={clickHyperLink}>Home</a> </li>
     *                 <li><a href="#howitworks" onClick={clickHyperLink}>How it works</a> </li>
     *                 <li><a href="#benefits" onClick={clickHyperLink}>Benefits</a> </li>
     *                 <li><a href="#aboutus" onClick={clickHyperLink}>About Us</a> </li>
     *                 <li><a onClick={clickContactUs}>Contact Us </a> </li>
     *             </ul>
     *
     *             <button className="default_button_landingpage" onClick={clickLoginButton}>Login</button>
     *
     *         </nav>
     *
     *         <nav className={styles.mobilenav} style={{left:width}}>
     *
     *
     *             <ul className={styles.mobileheadernavlist}>
     *                 <li><a href="#home" onClick={clickCloseNavBar}>Home</a> </li>
     *                 <li><a href="#howitworks" onClick={clickCloseNavBar}>How it works</a> </li>
     *                 <li><a href="#benefits" onClick={clickCloseNavBar}>Benefits</a> </li>
     *                 <li><a href="#aboutus" onClick={clickCloseNavBar}>About Us</a> </li>
     *                 <li><a  onClick={clickContactUsNavBar}>Contact Us</a> </li>
     *             </ul>
     *
     *             <button className={styles.navbutton}  onClick={clickLoginButton}>Login</button>
     *
     *             <Close className={styles.closeicon} onClick={clickCloseNavBar}></Close>
     *         </nav>
     */
    return <>

        <LandingPageHeader type = "home" onClickContactUsNavBar = {clickContactUsNavBar} onClickCloseNavBar = {clickCloseNavBar} navwidth = {width} onClickLoginButton = {clickLoginButton} onClickContactUs = {clickContactUs} onClickOpenNavBar = {clickOpenNavBar} onClickHyPerLink = {clickHyperLink}/>
        {layoutView}


       <LandingpageFooter/>





    </>;



}

export default LandingPage;