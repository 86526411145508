import LandingPageHeader from "../components/LandingPageHeader";
import LandingpageFooter from "../components/LandingpageFooter";
import {Controls, Player} from "@lottiefiles/react-lottie-player";
import {useParams} from "react-router-dom";
import success from '../components/images/aitools/animation/success.json'
import styles from '../components/stylesheets/emailverification.module.css'
import axios from "axios";

function EmailVerification() {

    let isUpdateSuccessful = false;
    let {id} = useParams()

    axios.get(`https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlupdateuserstatus?userid=${id}`).then(function (response) {
                        console.log(response);

                        const data = response.data;

                        if (data === null || data === "") {
                            console.log("Data is null")

                            alert("Data is null");

                        } else {
                            console.log(data);
                            
                            if (data.statusCode === "200")
                            {
                                isUpdateSuccessful = true;
                            }
                            else
                            {
                                isUpdateSuccessful = false;
                            }
                        }
                    }).catch(function (error) {
                        console.log(error);
                        alert(error);
                    });

                    if (isUpdateSuccessful)
                    {
                        return <>
                        <LandingPageHeader/>

                        <div className={styles.messagelayout}>
                            <Player
                                autoplay
                                loop
                                src={success}
                                style={{ height: '200px', width: '200px' }}
                            >
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player>

                            <h3 className={styles.messagetitle}>Verified </h3>
                            <p className={styles.messagetext}>Your email address has been successfully verified and we will contact you within 24 hours once your administrator sets up your account</p>


                        </div>
                        <LandingpageFooter/>
                        </>
                    }
                    else
                    {
                        return <>
                        <LandingPageHeader/>

                        <div className={styles.messagelayout}>
                            <Player
                                autoplay
                                loop
                                src={success}
                                style={{ height: '200px', width: '200px' }}
                            >
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player>

                            <h3 className={styles.messagetitle}>Not Verified </h3>
                            <p className={styles.messagetext}>Your email address has not been successfully verified</p>


                        </div>
                        <LandingpageFooter/>
                        </>
                    }
}

export  default EmailVerification;