// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartbuttons_chartbuttonslayout__pXUcT{

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2%;


}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/chartbuttons.module.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;;;AAGlB","sourcesContent":[".chartbuttonslayout{\r\n\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-top: 2%;\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartbuttonslayout": `chartbuttons_chartbuttonslayout__pXUcT`
};
export default ___CSS_LOADER_EXPORT___;
