
function   EventButton(prop) {
    let buttonClassName ="defaultbutton";
    if(prop.buttonid === "lockedpredictionbutton") {
     buttonClassName    = "defaultbutton_grey";
    }


    const handleButtonClickEvent = (event)=>{

        if(prop.buttonid === "predictbutton") {
            prop.onhandlePredictButton();
        }else if(prop.buttonid === "lockedpredictionbutton") {
                prop.onhandlePredictButton();
        }else if(prop.buttonid === "save_specific_scenario_button") {

            prop.onHandleSaveScenarioButton();
        }else if(prop.buttonid === "save_all_products_button"){

            prop.onHandleSaveScenarioProductsButton();


        }else if(prop.buttonid === "optimizebutton") {

            prop.onhandleOptimizeButton();
        }else if(prop.buttonid === "savescenariobutton"){
            prop.onHandleSaveScenario();

        }else if(prop.buttonid === "deletescenariobutton"){
            prop.onHandleDeleteScenario();


        }else if(prop.buttonid === "runscenariobutton"){

            prop.onClickRunScenario();

        }else if(prop.buttonid === "clearbutton"){
            prop.onHandleClearButtonClicked();

        }else if(prop.buttonid === "clearbuttonautomate"){
            alert("coming soon")

        }else if(prop.buttonid === "sharebuttonautomate"){

           // alert("coming soon")
            prop.onHandleShareClickedAutomate();

        }else if(prop.buttonid === "savebuttonautomate"){

            alert("coming soon")

        }else if(prop.buttonid === "createplanbutton"){

            alert("coming soon")
        //download report pdf
        }else if(prop.buttonid === "sharebutton"){
            prop.onHandleShareButtonClicked();
            //alert("coming soon");

        }else if(prop.buttonid === "lockbutton"){
           // alert("report locked successfully")
            prop.onHandleLockPredictionClicked();
         //download data
        }else if(prop.buttonid === "savedraftbutton"){
            alert("coming soon")


        }

    }

    return <button className={buttonClassName} onClick={handleButtonClickEvent} id={prop.buttonid} hidden={prop.disableLockPrediction} disabled={prop.disableLockPrediction}>{prop.buttonname}</button>
}

export default EventButton