import axios from "axios";
import styles from './stylesheets/customlegend.module.css'
import {useEffect, useState} from "react";
function CustomLegend(){
    const MlRequest = {modelId:0, fmcgId:0, Jik_Bleach_Regular_750ml_price_dist:0, Jik_Bleach_Spring_Fresh_750ml_price_dist:0, Jik_Bleach_Lemon_750ml_price_dist:0  };

    const [isLoading,setLoading] = useState(true);
    const [reponseData, setData] = useState();
    let titlesArray = [];
    let colorsArray;

    useEffect(()=>{
        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlpredictions', MlRequest).then(function (response) {
            console.log(response);
            setLoading(false);
            setData(response);



        }).catch(function (error) {
            console.log(error);
        });

    }, [])

    if(isLoading){
        return <p>loading</p>;

    }else {
        let list = reponseData.data.mlBleachesResultsList;

        list.forEach(function (value, index, arr) {

            if (value.predDate === "Oct-18") {
                console.log("found date");
                //titles
                let keys = Object.keys(value);

                console.log(keys);

                titlesArray.push(keys[2]);
                titlesArray.push(keys[3]);
                titlesArray.push(keys[4]);
                titlesArray.push(keys[5]);
                titlesArray.push(keys[6]);
                titlesArray.push(keys[7]);
                titlesArray.push(keys[8]);
                titlesArray.push(keys[9]);
                titlesArray.push(keys[10]);
                console.log(keys[3]);

                colorsArray= [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(10, 181, 65, 1)',
                    'rgba(123, 32, 221, 1)',
                    'rgba(243, 102, 34, 1)'
                ]

            }

        });


        return (
            <div className={styles.legendcontainer}>
                {titlesArray.map((value, index) => (
                    <div key={index} className={styles.legendlayout}>
                        <div className={styles.legendbullet} style={{backgroundColor:colorsArray[index]}}></div>
                        <p className={styles.legendtext}>{value}</p>
                    </div>
                ))}
            </div>
        );
    }

}

export default CustomLegend;

