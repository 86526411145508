import EventButton from "./EventButton";
import styles from './stylesheets/chartbuttons.module.css'
import {CSVLink} from "react-csv";

function ChartButtons(props) {
   // <EventButton buttonname = "Download Data" buttonid = "savedraftbutton" disableLockPrediction = {false}/>

    return(
        <div className={styles.chartbuttonslayout}>
            <EventButton buttonname = "Clear Chart" buttonid = "clearbutton" onHandleClearButtonClicked = {props.onHandleClearButtonClicked} disableLockPrediction = {false}/>
            <EventButton buttonname = "Download Report" buttonid = "sharebutton" onHandleShareButtonClicked = {props.onHandleShareButtonClicked} disableLockPrediction = {false}/>
                   <CSVLink className="download_button_csv" filename={`${props.csvtitle}.csv`} data = {props.csvdata}>Download Data</CSVLink>
            <EventButton buttonname = "Lock Prediction" buttonid = "lockbutton" disableLockPrediction = {props.disableLockPrediction} onHandleLockPredictionClicked = {props.onHandleLockPredictionClicked}/>


        </div>
    )


}

export default ChartButtons