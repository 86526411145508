import { Chart as ChartJS, ArcElement, Title,Tooltip, Legend } from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import DoghnutLabels from 'chartjs-plugin-doughnutlabel-v3'



import { Doughnut } from "react-chartjs-2";

import styles from './stylesheets/charts.module.css'
import {DoghnutChartColors} from "../utils/Utilities";


ChartJS.register(ArcElement, ChartDataLabels,DoghnutLabels,Tooltip, Title, Legend);






function DoghnutChartWhatIf(props) {


    let titlesArray = props.labels;
    let dataArray = props.data;
    let colorsArray = props.colors;
    let data;
    let options;



    let data_1 = {
        labels: titlesArray, // Dummy data
        datasets: [{

            label: '#Qty',
            data: dataArray,

            backgroundColor: colorsArray,
            borderColor:colorsArray,

        }],
    }
    let data_2 = {
        labels: titlesArray,
        datasets: [
            {
                label: '#Qty',
                data: dataArray,

                backgroundColor: colorsArray,
                borderColor: colorsArray,
                borderWidth: 1,
            },
        ],

    };

    let show = props.showlegend === "true" ? true : false
    data = show === true ? data_1 : data_2;

    let position = show === true ? 'left' : 'bottom'

    const totalVol = dataArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    options = {
        cutoutPercentage: 50, // Adjust the size of the hole in the middle of the doughnut

        responsive: true,


        plugins: {

            title: {
                display: false,
                fullSize: true,
                text: "Total Vol",
                padding: {
                    top: 10,
                    bottom: 10
                }
            },
            subtitle: {
                display: false,
                fullSize: true,
                text: "0",
                padding: {
                    bottom: 5
                }
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem, data) {

                        let label = tooltipItem.dataset.label || '';


                        return `${label}:${tooltipItem.raw}   ${((parseFloat(tooltipItem.raw) / totalVol) * 100).toFixed(2)}%`;

                    },


                },


            },

            legend: {
                display: show,
                position: position,


                labels: {
                    padding: 15,
                }
            },
            doughnutLabel: {
                labels: [
                    {
                        text: "Total Vol",
                        color: "black",
                        font: {
                            size: "20",
                            family: "Arial, Helvetica, sans-serif",
                            style: "italic",
                            weight: "bold"
                        }
                    },
                    {
                        text: totalVol.toFixed(0),
                        font: {
                            size: "19",
                            weight: "bold"
                        },
                        color: "grey"
                    },
                ]
            },
            datalabels: {
                display: true,
                align: 'bottom',
                backgroundColor: '',
                borderRadius: 3,
                font: {
                    size: 14,
                },
                color: "white",
                formatter: function (value, context) {
                    let calculatedValue = ((value / totalVol) * 100);
                    if (calculatedValue >= 5) {
                        return `${calculatedValue.toFixed(1)}%`;
                    } else {
                        return null;
                    }
                },


            },


        }


    };








            // long id;
            //String predDate;

            //actualPred;




        return <Doughnut className={styles.doghnutchart} data={data} options={options}/>;

    }









export default DoghnutChartWhatIf
