// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maincontent{
    margin-left: 16%;
    margin-top: 5%;

}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
.maincontent{
    margin-left: 1%;
    margin-top: 9%;

}

}



/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .maincontent{
      margin-left: 1%;
      margin-top: 9%;

  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1366px) {

}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;;AAElB;;;;AAIA,iDAAiD;AACjD;AACA;IACI,eAAe;IACf,cAAc;;AAElB;;AAEA;;;;AAIA,qDAAqD;AACrD;EACE;MACI,eAAe;MACf,cAAc;;EAElB;AACF;;AAEA,mDAAmD;AACnD;;AAEA;;AAEA,oEAAoE;AACpE;;AAEA","sourcesContent":[".maincontent{\r\n    margin-left: 16%;\r\n    margin-top: 5%;\r\n\r\n}\r\n\r\n\r\n\r\n/* Extra small devices (phones, 600px and down) */\r\n@media only screen and (max-width: 600px) {\r\n.maincontent{\r\n    margin-left: 1%;\r\n    margin-top: 9%;\r\n\r\n}\r\n\r\n}\r\n\r\n\r\n\r\n/* Medium devices (landscape tablets, 768px and up) */\r\n@media only screen and (max-width: 768px) {\r\n  .maincontent{\r\n      margin-left: 1%;\r\n      margin-top: 9%;\r\n\r\n  }\r\n}\r\n\r\n/* Large devices (laptops/desktops, 992px and up) */\r\n@media only screen and (min-width: 992px) {\r\n\r\n}\r\n\r\n/* Extra large devices (large laptops and desktops, 1200px and up) */\r\n@media only screen and (min-width: 1366px) {\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
