import styles from './stylesheets/chartbuttons.module.css'
import EventButton from "./EventButton";
import { CSVLink } from "react-csv";
import {formatDateReport, getMonthFormatted} from "../utils/Utilities";

function AutomateResultsButtons(props) {
    let csvfile_title = `${props.product.replace(" ","_").trim()}_${props.retailer}_${getMonthFormatted(props.date)}_${ formatDateReport(new Date())}.csv`
    //let csv_file = `${props.retailer}-${props.category} ${props.constraint} ${props.maxconstraint}.csv`;
    return(
        <div className={styles.chartbuttonslayout}>
            <EventButton buttonname = "Clear" buttonid = "clearbuttonautomate"/>
            <EventButton buttonname = "Save" buttonid = "savebuttonautomate"/>
            <EventButton buttonname = "Share" buttonid = "sharebuttonautomate" onHandleShareClickedAutomate = {props.onhandleShareClicked}/>
            <EventButton buttonname = "Create plan" buttonid = "createplanbutton"/>
            <CSVLink className="download_button_csv" filename={csvfile_title} data = {props.csvData}>Download Data</CSVLink>
        </div>
    )


}
export default AutomateResultsButtons;
