
function NoPage(){


    return (<html>
           <title>NO PAGE FOUND</title>
        <body>
        <h1>ERROR 404 PAGE NOT FOUND</h1>

        </body>
        </html>


    )
}

export default NoPage