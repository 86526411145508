// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: fixed;
    top: 0;
    width: 90%;
    z-index: 999;
    background-color: #ffffff;
}
.header_namelayout__2Abwm{
   display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: nowrap;

}
.header_nameinitials__Rp3we{
    background-color: black;
    color: white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 12px;
    font-weight: var(--font_bold);
    font-size: 16px;
}

.header_clientname__AWfgM{
  font-weight: var(--font_semibold);
  font-size: 18px;

}
.header_headerlogo__6Bz3T{
    max-width: 150px;
    height: auto;
    margin-left: 10px;

}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,MAAM;IACN,UAAU;IACV,YAAY;IACZ,yBAAyB;AAC7B;AACA;GACG,aAAa;IACZ,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;IACnB,iBAAiB;;AAErB;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;EACE,iCAAiC;EACjC,eAAe;;AAEjB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;;AAErB","sourcesContent":["header{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n    position: fixed;\r\n    top: 0;\r\n    width: 90%;\r\n    z-index: 999;\r\n    background-color: #ffffff;\r\n}\r\n.namelayout{\r\n   display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n\r\n}\r\n.nameinitials{\r\n    background-color: black;\r\n    color: white;\r\n    border-radius: 50%;\r\n    width: 25px;\r\n    height: 25px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n    margin-right: 12px;\r\n    font-weight: var(--font_bold);\r\n    font-size: 16px;\r\n}\r\n\r\n.clientname{\r\n  font-weight: var(--font_semibold);\r\n  font-size: 18px;\r\n\r\n}\r\n.headerlogo{\r\n    max-width: 150px;\r\n    height: auto;\r\n    margin-left: 10px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"namelayout": `header_namelayout__2Abwm`,
	"nameinitials": `header_nameinitials__Rp3we`,
	"clientname": `header_clientname__AWfgM`,
	"headerlogo": `header_headerlogo__6Bz3T`
};
export default ___CSS_LOADER_EXPORT___;
