import {useRef, useState} from "react";
import {Alert, Button, CircularProgress, InputAdornment, TextField} from "@mui/material";
import styles from "./stylesheets/registration.module.css";
import {Lock, ArrowBack} from "@mui/icons-material";
function RegistrationForm(props) {
    const firstname = useRef("")
    const lastname = useRef("")
    const email = useRef("")
    const password = useRef("")
    const role = useRef("")
    const organization = useRef("")
    const[firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const[emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const[roleError, setRoleError] = useState(false);
    const [organizationError, setOrganizationError] = useState(false);

    const [firstnameErrorMessage, setFirstNameErrorMessage] = useState("");
    const[lastnameErrorMesssage, setLastNameErrorMessage]  = useState("");
    const [roleErrorMessage, setRoleErrorMessage] = useState("");
    const[organizationErrorMesssage, setOrganizationErrorMessage]  = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const[passwordErrorMesssage, setPasswordErrorMessage]  = useState("");
    let loader, alert;


    // Handle input change
    const handleChange = (e) => {
       switch (e.target.id){

           case "firstname":

               firstname.current = e.target.value;

               break;

           case "lastname":
               lastname.current = e.target.value;
               break;

           case "role":
               role.current = e.target.value;
               break;

           case "organization":

               organization.current = e.target.value;
               break;

           case "email":
               email.current = e.target.value;
               break;

           case 'password':
               password.current = e.target.value;
               break;


       }

    };

    const handleSubmitButtonClick = (event)=>{

        if(firstname.current.length === 0){
            setFirstNameError(true);
            setFirstNameErrorMessage("input firstname");


        }else if(lastname.current.length === 0){
            setLastNameError(true);
            setLastNameErrorMessage("input lastname");

        }else if(email.current.length === 0){

            setEmailError(true);
            setEmailErrorMessage("input email");
        }else if(organization.current.length === 0){
            setOrganizationError(true);
            setOrganizationErrorMessage("input organization")

        }else if(role.current.length === 0){

            setRoleError(true);
            setRoleErrorMessage("input role");

        }else if(password.current.length === 0){

            setPasswordError(true);
            setPasswordErrorMessage("input password")

        }else{

            const formData = { firstName: firstname.current,
                lastName: lastname.current,
                email: email.current,
                organization: organization.current,
                role: role.current,
                password: password.current}

            props.onRegisterUser(formData);
        }

       // alert("submitted");
    };



    const handleGoBackButtonEvent = (event)=>{

        props.onGoBackStateChange();

    }

    if(props.showLoader){

        loader = <CircularProgress/>

    }

    if(props.showAlert){

        if(props.alertType === "success"){

            alert = <Alert variant="filled" severity="success">
                {`${props.alertMessage} redirecting to login page`}
            </Alert>
              setTimeout(function () {
                  props.onGoBackStateChange();
              }, 3000);

            //error
        }else{

            alert = <Alert variant="filled" severity="error">
                {props.alertMessage}
            </Alert>
        }


    }
    return (
        <div className={styles.mainlayoutauthentication}>
            <div className={styles.formtitlelayout}> <ArrowBack style={{cursor:'pointer'}} onClick={handleGoBackButtonEvent}/>
                <h2>Create Account</h2>
                <ArrowBack style={{visibility:'hidden', cursor:'pointer'}}/>
            </div>
            {alert}
            <form  className={styles.formlayout}>
                <TextField id="firstname" className={styles.formcomponents
                } fullWidth={true}  label="FirstName" type="text" inputProps={{maxLength:100}} error={firstNameError} helperText={firstnameErrorMessage} variant="filled"
                onChange={handleChange}></TextField>

                <TextField id="lastname" className={styles.formcomponents
                } fullWidth={true}  label="LastName" type="text" inputProps={{maxLength:100}} error={lastNameError} helperText={lastnameErrorMesssage} variant="filled"
                onChange={handleChange}></TextField>

                <TextField id="email" className={styles.formcomponents
                } fullWidth={true}  label="Email" type="email" inputProps={{maxLength:100}} error={emailError} helperText={emailErrorMessage} variant="filled"
                onChange={handleChange}></TextField>

                <TextField id="organization" className={styles.formcomponents
                } fullWidth={true}  label="Organization" type="text" inputProps={{maxLength:100}} error={organizationError} helperText={organizationErrorMesssage} variant="filled"
                 onChange={handleChange}></TextField>

                <TextField id="role" className={styles.formcomponents
                } fullWidth={true}  label="Role" type="text" inputProps={{maxLength:100}} error={roleError} helperText={roleErrorMessage} variant="filled"
                 onChange={handleChange}></TextField>

                <TextField id="password" type="password" inputProps={{maxLength:20}} className={styles.formcomponents} error={passwordError} helperText={passwordErrorMesssage} fullWidth={true}  InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lock />
                        </InputAdornment>
                    ),
                }}  label="Password" variant="filled"
               onChange={handleChange} ></TextField>


                <Button variant="contained" onClick={handleSubmitButtonClick} className={styles.formcomponentsbutton} fullWidth={true} >Register</Button>
            </form>
            {loader}
        </div>
    )

    /**
    return (
      <div className="maincontent">
        <form onSubmit={handleSubmit}>
          <label>FirstName:
            <input
              type="text"
              name="firstName" 
              value={formData.firstName}
              onChange={handleChange}
            />
          </label>
          <label>LastName:
              <input
                  type="text"
                  name="lastName" 
                  value={formData.lastName}
                  onChange={handleChange}
              />
          </label>
          <label>Email:
            <input
              type="text"
              name="email" 
              value={formData.email}
              onChange={handleChange}
            />
          </label>
          <label>Organization:
            <input
              type="text"
              name="organization" 
              value={formData.organization}
              onChange={handleChange}
            />
          </label>
          <label>Role:
            <input
              type="text"
              name="role" 
              value={formData.role}
              onChange={handleChange}
            />
          </label>
          <label>Password:
            <input
              type="password"
              name="password" 
              value={formData.password}
              onChange={handleChange}
            />
          </label>
          <input type="submit" value="Register" />
        </form>
      </div>
    )
     **/
}

export default RegistrationForm