// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectiontitles_sectiontitles__Lqf7e{
display: flex;
flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 0;
    padding-top: 0;



}

.sectiontitles_selected__tPb5W, .sectiontitles_unselected__ceRDA{
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
    text-decoration: none;

    padding: 8px;
    color: black;
    font-weight: var(--font_semibold);
    display: block;
    text-align: center;
}
.sectiontitles_selected__tPb5W{
background-color: #666666;
color: white;
    border: 1px solid #666666;
}

.sectiontitles_unselected__ceRDA{
    background-color: #D9D9D9;
    color: black;
    border: 1px solid #D9D9D9;
}

`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/sectiontitles.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,mBAAmB;IACf,2BAA2B;IAC3B,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,cAAc;;;;AAIlB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,qBAAqB;;IAErB,YAAY;IACZ,YAAY;IACZ,iCAAiC;IACjC,cAAc;IACd,kBAAkB;AACtB;AACA;AACA,yBAAyB;AACzB,YAAY;IACR,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".sectiontitles{\r\ndisplay: flex;\r\nflex-direction: row;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n    margin-top: 0;\r\n    padding-top: 0;\r\n\r\n\r\n\r\n}\r\n\r\n.selected, .unselected{\r\n    width: 200px;\r\n    margin-left: 10px;\r\n    margin-right: 10px;\r\n    text-decoration: none;\r\n\r\n    padding: 8px;\r\n    color: black;\r\n    font-weight: var(--font_semibold);\r\n    display: block;\r\n    text-align: center;\r\n}\r\n.selected{\r\nbackground-color: #666666;\r\ncolor: white;\r\n    border: 1px solid #666666;\r\n}\r\n\r\n.unselected{\r\n    background-color: #D9D9D9;\r\n    color: black;\r\n    border: 1px solid #D9D9D9;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectiontitles": `sectiontitles_sectiontitles__Lqf7e`,
	"selected": `sectiontitles_selected__tPb5W`,
	"unselected": `sectiontitles_unselected__ceRDA`
};
export default ___CSS_LOADER_EXPORT___;
