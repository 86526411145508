
import DoghnutChartWhatIfAnalysis from "./DoghnutChartWhatIfAnalysis";
import styles from "./stylesheets/doghnutchartwhatiflayout.module.css"
import {DoghnutChartColors} from "../utils/Utilities";
import {
    BLEACHES_750ML,
    CARREFOUR_RETAILER_ID,
    NAIVAS_RETAILER_ID,
    RECKITT_FMCG_ID,
    TOILETCLEANERS_500ML
} from "../utils/Constants";
function DoghnutChartWhatIfAnalaysisLayout (props){

  const predictedColors = [];
  const actualLabels = props.actualLabels;
  const predictedLabels  =  props.predictedLabels;
  const predictedLabelsEdited = [];
//alert(props.category);
    let matchedProductsSet = new Set();
  predictedLabels.forEach((value)=>{
      actualLabels.forEach((valueActual,index)=> {
           if(parseInt(props.fmcgid) === RECKITT_FMCG_ID) {
              // alert(value + " "+valueActual);
               if((parseInt(props.retailer) === NAIVAS_RETAILER_ID||parseInt(props.retailer) === CARREFOUR_RETAILER_ID) && props.category.toLowerCase().includes("bleach")){
                  if(props.size === BLEACHES_750ML) {
                      if (valueActual.toLowerCase().replaceAll(" ", "").replaceAll("/", "").replaceAll("+", "plus")=== value.toLowerCase()) {
                          predictedColors.push(DoghnutChartColors[index]);
                          predictedLabelsEdited.push(valueActual);
                          //alert(valueActual +" "+value);

                      }
                  }else{

                      if (valueActual.toLowerCase().replaceAll(" ", "").replaceAll("/", "").replaceAll("+", "plus").includes(value.replace("PromoPack3x750ml", "").replace("750ml", "").replace("700ml", "").toLowerCase())) {
                          predictedColors.push(DoghnutChartColors[index]);
                          predictedLabelsEdited.push(valueActual);
                          //alert(valueActual +" "+value);

                      }
                  }
               }else{

                   if(props.size === TOILETCLEANERS_500ML) {
                       if (valueActual.toLowerCase().replaceAll("/", "").replaceAll("+", "plus").replaceAll(" ", "") === value.toLowerCase()) {
                           predictedColors.push(DoghnutChartColors[index]);
                           predictedLabelsEdited.push(valueActual);

                       }
                   }else{
                       if(!matchedProductsSet.has(valueActual)) {
                           if (valueActual.toLowerCase().replaceAll(" ", "").replaceAll("/", "").replaceAll("+", "plus").replace("mpine", "mountainpine").replace("tcpowerpluslemon", "toiletcleanerpplem").includes(value.replace("2x500ml", "").replace("500ml", "").toLowerCase())) {
                               predictedColors.push(DoghnutChartColors[index]);
                               predictedLabelsEdited.push(valueActual);
                               //alert(valueActual +" "+value);
                               matchedProductsSet.add(valueActual);

                           }
                       }
                   }
               }

           }else{
               if (value.toLowerCase() === valueActual.toLowerCase()) {
                   predictedColors.push(DoghnutChartColors[index]);
                   predictedLabelsEdited.push(predictedLabelsEdited);

               }
           }


      });


  });




    return(<div className={styles.mainlayout}>
            <div className={styles.doghnutlayout}>
                <h2>{`${props.actualDate} (Actual)`}</h2>
                <DoghnutChartWhatIfAnalysis data = {props.actualValues} labels = {props.actualLabels}  showlegend = "true" colors = {DoghnutChartColors}></DoghnutChartWhatIfAnalysis>
      </div>

            <div className={styles.doghnutlayout}>

                <h2>{`${props.predictedDate} (Predicted)`}</h2>
                <DoghnutChartWhatIfAnalysis data = {props.predictedValues} labels = {predictedLabelsEdited} showlegend = "true" colors = {predictedColors}></DoghnutChartWhatIfAnalysis>

            </div>



        </div>



    )


}
export default DoghnutChartWhatIfAnalaysisLayout