import styles from './stylesheets/sidepanel.module.css'
function SidePanel(props) {

    let subtext = "";
    if(props.amount !== undefined){

        subtext =  <p className={styles.subtext}>{`Ksh ${props.amount}`}</p>
    }

    return(
        <div className={styles.panelcontainer}>
         <p className={styles.paneltitle}>{props.title}</p>
          <p className={styles.paneltext}>{props.value}</p>
            {subtext}


        </div>
    )

}

export  default SidePanel