// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacypolicy_content_container__aiFCW{
  width: 80%;
  margin-left: 3%;
  margin-right: 3%;


}
p{
  line-height: 1.6;
}
li{
  padding-top: 4px;
  padding-bottom: 4px;
}

@media  (max-width: 600px) {

  .privacypolicy_content_container__aiFCW{

    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/privacypolicy.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;;;AAGlB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;EAEE;;IAEE,UAAU;IACV,iBAAiB;IACjB,kBAAkB;EACpB;;AAEF","sourcesContent":[".content_container{\r\n  width: 80%;\r\n  margin-left: 3%;\r\n  margin-right: 3%;\r\n\r\n\r\n}\r\np{\r\n  line-height: 1.6;\r\n}\r\nli{\r\n  padding-top: 4px;\r\n  padding-bottom: 4px;\r\n}\r\n\r\n@media  (max-width: 600px) {\r\n\r\n  .content_container{\r\n\r\n    width: 90%;\r\n    margin-left: 10px;\r\n    margin-right: 10px;\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content_container": `privacypolicy_content_container__aiFCW`
};
export default ___CSS_LOADER_EXPORT___;
