// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidepanel_panelcontainer__cDGKh{
    width: 200px;
    height: 140px;
    border: 2px solid black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;


}

.sidepanel_paneltitle__dBZ1M{
 font-weight: var(--font_semibold);


    font-size: 17px;
    color: #666666;

}

.sidepanel_paneltext__LJSyf{
 font-weight: var(--font_bold);
 font-size: 28px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;


}

.sidepanel_subtext__5RR0L{
    font-weight: var(--font_semibold);
    font-size: 17px;
    color: black;
    margin-top: 5px;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 5px;


}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/sidepanel.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;;;AAGtB;;AAEA;CACC,iCAAiC;;;IAG9B,eAAe;IACf,cAAc;;AAElB;;AAEA;CACC,6BAA6B;CAC7B,eAAe;IACZ,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,iBAAiB;;;AAGrB;;AAEA;IACI,iCAAiC;IACjC,eAAe;IACf,YAAY;IACZ,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,mBAAmB;;;AAGvB","sourcesContent":[".panelcontainer{\r\n    width: 200px;\r\n    height: 140px;\r\n    border: 2px solid black;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n    align-items: center;\r\n    margin-bottom: 6px;\r\n\r\n\r\n}\r\n\r\n.paneltitle{\r\n font-weight: var(--font_semibold);\r\n\r\n\r\n    font-size: 17px;\r\n    color: #666666;\r\n\r\n}\r\n\r\n.paneltext{\r\n font-weight: var(--font_bold);\r\n font-size: 28px;\r\n    margin-top: 0;\r\n    padding-top: 0;\r\n    margin-bottom: 0;\r\n    padding-bottom: 0;\r\n\r\n\r\n}\r\n\r\n.subtext{\r\n    font-weight: var(--font_semibold);\r\n    font-size: 17px;\r\n    color: black;\r\n    margin-top: 5px;\r\n    padding-top: 0;\r\n    margin-bottom: 0;\r\n    padding-bottom: 5px;\r\n\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelcontainer": `sidepanel_panelcontainer__cDGKh`,
	"paneltitle": `sidepanel_paneltitle__dBZ1M`,
	"paneltext": `sidepanel_paneltext__LJSyf`,
	"subtext": `sidepanel_subtext__5RR0L`
};
export default ___CSS_LOADER_EXPORT___;
