const MAX_OPTIMIZE_DISCOUNT = 50;
const RECKITT_FMCG_ID = 34;

const  KIMBERLY_CLARKS_FMCG_ID = 52;
const NAIVAS_RETAILER_ID = 3;
const CARREFOUR_RETAILER_ID = 30;
//const NAIVAS_BLEACHES_MODEL_ID = 20;
const NAIVAS_BLEACHES_MODEL_ID = 30;
//const CARREFOUR_BLEACHES_MODEL_ID = 26;
const CARREFOUR_BLEACHES_MODEL_ID = 32;
const DIAPERS_MODEL_ID = 25;
//const NAIVAS_TOILETCLEANERS_MODEL_ID = 27;
const NAIVAS_TOILETCLEANERS_MODEL_ID = 33;
//const CARREFOUR_TOILETCLEANERS_MODEL_ID = 28;
const CARREFOUR_TOILETCLEANERS_MODEL_ID = 34;
const BLEACHES_CURRENT_PRICES_STORAGE_KEY = "BLEACHES_CURRENT_PRICES_KEY";
const TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY = "TOILET_CLEANERS_CURRENT_PRICES_KEY";

const DIAPERS_CURRENT_PRICES_STORAGE_KEY = "DIAPERS_CURRENT_PRICES_KEY";
const PRICELIST_MILLISECONDS_KEY ="PRICELIST_MILLISECONDS_KEY";
const MLTARGETS_MILLISECONDS_KEY ="MLTARGETS_MILLISECONDS_KEY";
const ML_TARGETS_STORAGE_KEY = "ML_TARGETS_STORAGE_KEY";
//2 minutes
const REFRESH_PRICELIST_DURATION = 180000;

const REFRESH_ML_TARGETS_DURATION = 180000;

const WHATIF_REQUESTTYPE = "whatif";
const SCENARIO_REQUESTTYPE = "scenario";
const CHECK_LOCKED_PREDICTION_REQUESTTYPE = "checklockedprediction";

const LOAD_LOCKED_PREDICTION_REQUESTTYPE = "loadlockedprediction";

const LOCK_PREDICTION_REQUESTTYPE = "lockprediction";

const OPTIMIZE_REQUESTTYPE = "optimize";
const MAIN_URL  ="https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions";
const DEFAULT_PREVIOUS_MONTH_DATE   =   "2024-08-01";
const DEFAULT_CURRENT_MONTH_DATE   =   "2024-09-01";
const PREDICTED_LINE_CHART ="PREDICTED_LINE_CHART";
const SCENARIOS_BLEACHES_PRODUCT_COUNT_CARREFOUR = 9;
const SCENARIOS_BLEACHES_PRODUCT_COUNT_NAIVAS = 8;
const SCENARIOS_TOILET_CLEANERS_PRODUCT_COUNT = 15;

const LOCKED_LINE_CHART ="LOCKED_LINE_CHART";

const BLEACHES_BRANDS_STACKBAR = ["Jik Bleach Regular 750ml", "Jik Bleach Lemon 750ml", "Jik Bleach Regular 2+1 Promo Pack 3x750ml"]
const BLEACHES_BRANDS_STACKBAR_NAIVAS = ["Jik Bleach Regular 750ml", "Jik Bleach Lemon 750ml"];
const BLEACHES_BRANDS_STACKBAR_CARREFOUR = ["Jik Bleach Regular 750ml", "Jik Bleach Lemon 750ml", "Jik Bleach Regular 2+1 Promo Pack 3x750ml"]

const BLEACHES_BRANDS_STACKBAR_NAIVAS_1L = ["Jik Bleach Regular 1.5L"];

const BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L = ["Jik Bleach Regular Perfumed Promo 1.5L"];
const BLEACHES_BRANDS_STACKBAR_NAIVAS_2L = ["Jik Bleach Regular 2.25L"];
const TOILETCLEANERS_500ML = "500ml";
const TOILETCLEANERS_1L = "1000ml";
const BLEACHES_750ML = "750ml";
const BLEACHES_1_L= "1500ml";
const BLEACHES_2_L = "2250ml";

const TOILET_CLEANER_1L_FILTER = "1L";
const TOILET_CLEANER_1L_FILTER_2 = "1000ml";
const TOILET_CLEANER_500_ML_FILTER = "500ml";
const BLEACHES_1L_FILTER_ = "1L";
const BLEACHES_750_ML_FILTER = "750ml";
const BLEACHES_1_5L_FILTER = "1.5L";
const BLEACHES_2L_FILTER = "2L";
const BLEACHES_2_25L_FILTER = "2.25L";

const TOILETCLEANERS_BRANDS_STACKBAR =["Harpic Power Plus Citrus 500ml",
    "Harpic Power Plus Lavender 500ml",
    "Harpic Power Plus Original 500ml",
    "Harpic Toilet Cleaner Mountain Pine 500ml",
    "Harpic Toilet Cleaner P/P Flo 500ml",
    "Harpic Toilet Cleaner P/P Lem 500ml",
    "Harpic Power Plus Original 2x500ml",
    "Harpic Power Plus Citrus 2x500ml"]

const TOILETCLEANERS_BRANDS_STACKBAR_1L =["Harpic Power Plus Citrus 1L",
    "Harpic Power Plus Lavender 1L",
    "Harpic Power Plus Original 1L",
    "Harpic Toilet Cleaner M/Pine P/P 1L",
    "Harpic Toilet Cleaner P/P Flo 1L",
    "Harpic Tc Power Plus Lemon 1L",
    "Harpic Power Plus Original Twin Pack 2x1L",
    "Harpic Power Plus Citrus Twin Pack 2x1L"]

const TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR =["Harpic Power Plus Citrus 1L",
    "Harpic Power Plus Lavender 1L",
    "Harpic Power Plus Original 1L",
    "Harpic Toilet Cleaner M/Pine P/P 1L",
    "Harpic Toilet Cleaner P/P Flo 1L",
    "Harpic Tc Power Plus Lemon 1L",
    "Harpic Power Plus Original 2x1L",
    "Harpic Power Plus Citrus 2x1L"]

const TOILETCLEANERS_BRANDS_VALUES_STACKBAR =["Harpic Power Plus Citrus 500ml 500ml",
    "Harpic Power Plus Lavender 500ml 500ml",
    "Harpic Power Plus Original 500ml 500ml",
    "Harpic Toilet Cleaner Mountain Pine 500ml 500ml",
    "Harpic Toilet Cleaner P/P Flo 500ml 500ml",
    "Harpic Toilet Cleaner P/P Lem 500ml 500ml",
    "Harpic Power Plus Original 2x500ml 500ml",
    "Harpic Power Plus Citrus 2x500ml 500ml"]
export {TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR,BLEACHES_1L_FILTER_,BLEACHES_1_5L_FILTER,BLEACHES_750_ML_FILTER,BLEACHES_2_25L_FILTER,BLEACHES_2L_FILTER,  TOILET_CLEANER_1L_FILTER_2,TOILET_CLEANER_1L_FILTER,TOILET_CLEANER_500_ML_FILTER,TOILETCLEANERS_BRANDS_STACKBAR_1L,TOILETCLEANERS_1L, TOILETCLEANERS_500ML,BLEACHES_BRANDS_STACKBAR_CARREFOUR,BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L,BLEACHES_750ML, BLEACHES_1_L,BLEACHES_2_L,BLEACHES_BRANDS_STACKBAR_NAIVAS_1L, BLEACHES_BRANDS_STACKBAR_NAIVAS_2L,BLEACHES_BRANDS_STACKBAR_NAIVAS,TOILETCLEANERS_BRANDS_VALUES_STACKBAR,TOILETCLEANERS_BRANDS_STACKBAR,
    BLEACHES_BRANDS_STACKBAR,
    DIAPERS_CURRENT_PRICES_STORAGE_KEY,
    KIMBERLY_CLARKS_FMCG_ID,DIAPERS_MODEL_ID,
    SCENARIOS_BLEACHES_PRODUCT_COUNT_CARREFOUR,
    SCENARIOS_BLEACHES_PRODUCT_COUNT_NAIVAS,
    SCENARIOS_TOILET_CLEANERS_PRODUCT_COUNT,DEFAULT_CURRENT_MONTH_DATE,PREDICTED_LINE_CHART,LOCKED_LINE_CHART,DEFAULT_PREVIOUS_MONTH_DATE,MAIN_URL,WHATIF_REQUESTTYPE,SCENARIO_REQUESTTYPE,CHECK_LOCKED_PREDICTION_REQUESTTYPE,LOAD_LOCKED_PREDICTION_REQUESTTYPE,LOCK_PREDICTION_REQUESTTYPE,OPTIMIZE_REQUESTTYPE,MLTARGETS_MILLISECONDS_KEY,REFRESH_ML_TARGETS_DURATION,ML_TARGETS_STORAGE_KEY,REFRESH_PRICELIST_DURATION,TOILET_CLEANERS_CURRENT_PRICES_STORAGE_KEY,BLEACHES_CURRENT_PRICES_STORAGE_KEY,PRICELIST_MILLISECONDS_KEY,MAX_OPTIMIZE_DISCOUNT, NAIVAS_TOILETCLEANERS_MODEL_ID, CARREFOUR_TOILETCLEANERS_MODEL_ID, CARREFOUR_BLEACHES_MODEL_ID, CARREFOUR_RETAILER_ID, NAIVAS_BLEACHES_MODEL_ID, NAIVAS_RETAILER_ID,RECKITT_FMCG_ID};
