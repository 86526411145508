// src/StackedBarChart.js
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {DoghnutChartColors, formatDate} from "../utils/Utilities";
import {
    BLEACHES_1_L,
    BLEACHES_2_L,
    BLEACHES_750ML,
    BLEACHES_BRANDS_STACKBAR, BLEACHES_BRANDS_STACKBAR_CARREFOUR, BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L,
    BLEACHES_BRANDS_STACKBAR_NAIVAS,
    BLEACHES_BRANDS_STACKBAR_NAIVAS_1L,
    BLEACHES_BRANDS_STACKBAR_NAIVAS_2L, CARREFOUR_RETAILER_ID,
    NAIVAS_RETAILER_ID,
    RECKITT_FMCG_ID, TOILETCLEANERS_1L, TOILETCLEANERS_500ML,
    TOILETCLEANERS_BRANDS_STACKBAR, TOILETCLEANERS_BRANDS_STACKBAR_1L, TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR
} from "../utils/Constants";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const totalLabelPlugin = {
    id: 'totalLabel',
    afterDatasetsDraw: (chart) => {
        const { ctx, chartArea: { top, left, bottom, right }, scales: { x, y } } = chart;
        chart.data.datasets.forEach((dataset, index) => {
            chart.getDatasetMeta(index).data.forEach((bar, index2) => {
                const data = dataset.data[index2];
                if (index === 0) {
                    let sum = 0;
                    chart.data.datasets.forEach((ds) => {
                        sum += ds.data[index2];
                    });
                    ctx.save();
                    ctx.font = 'bold 17px Helvetica, sans-serif';
                    ctx.fillStyle = 'black';
                    ctx.textAlign = 'center';
                    ctx.fillText(sum.toLocaleString("en-US"), bar.x, y.getPixelForValue(sum) - 10);
                    ctx.restore();
                }
            });
        });
    },
};

const textInsideBarPlugin = {
    id: 'textInsideBar',
    afterDatasetsDraw: (chart) => {
        const { ctx } = chart;
        chart.data.datasets.forEach((dataset, datasetIndex) => {
            chart.getDatasetMeta(datasetIndex).data.forEach((bar, index) => {
                const value = dataset.data[index];
                const barHeight = bar.height;
                const yPosition = bar.y + (barHeight / 2) + 6; // Adjust y position for better centering

                ctx.save();
                ctx.font = 'bold 13px Helvetica, sans-serif';
                ctx.fillStyle = 'white'; // Change text color here
                ctx.textAlign = 'center';
                ctx.fillText(value.toLocaleString("en-US"), bar.x, yPosition);
                ctx.restore();
            });
        });
    },
};

const dottedOutlinePlugin = {
    id: 'dottedOutline',
    beforeDraw: (chart) => {
        const { ctx, data } = chart;
        const lastIndex = data.labels.length - 1;
       // const secondlastIndex = data.labels.length - 2;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            const lastBar = meta.data[lastIndex];

            if (lastBar) {
                console.log(meta +"lastbar")
           // alert(console.log(lastBar));
                //if(data.labels[datasetIndex] ==="Jul 2024" || data.labels[datasetIndex] ==="Jun 2024"){
                ctx.save();
                ctx.strokeStyle = 'rgba(0, 0, 0, 1)'; // Black outline
                ctx.lineWidth = 2; // Thickness of the border
                ctx.setLineDash([5, 5]); // Dashed line pattern
                ctx.strokeRect(lastBar.x - lastBar.width / 2, lastBar.y, lastBar.width, lastBar.base - lastBar.y);
                ctx.restore();
            }
        });
    },
};
const dottedOutlinePlugin2 = {
    id: 'dottedOutline',
    beforeDraw: (chart) => {
        const { ctx, data } = chart;
        const lastIndex = data.labels.length - 2;
        // const secondlastIndex = data.labels.length - 2;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
            const meta = chart.getDatasetMeta(datasetIndex);
            const lastBar = meta.data[lastIndex];

            if (lastBar) {
                console.log(meta +"lastbar")
                // alert(console.log(lastBar));
                //if(data.labels[datasetIndex] ==="Jul 2024" || data.labels[datasetIndex] ==="Jun 2024"){
                ctx.save();
                ctx.strokeStyle = 'rgba(0, 0, 0, 1)'; // Black outline
                ctx.lineWidth = 2; // Thickness of the border
                ctx.setLineDash([5, 5]); // Dashed line pattern
                ctx.strokeRect(lastBar.x - lastBar.width / 2, lastBar.y, lastBar.width, lastBar.base - lastBar.y);
                ctx.restore();
            }
        });
    },
};
function StackedBarChart (props){
//alert(props.retailerName);
    let predictedResults = props.predictedResults;
    let previousValues = [];
    let filteredPreviousValues = [];



 //alert(props.date);


    //alert(props.fmcgid);
    if(parseInt(props.fmcgid) === RECKITT_FMCG_ID){
       // alert("ok fmcgid");
    if(props.retailerName === "Naivas"){
    if(props.category.toLowerCase().includes("toilet") && (props.size === TOILETCLEANERS_1L)) {
        previousValues = props.previousValuesBrands.filter((value)=>{


            return ((value.month + " "+value.year !== "August 2023") &&(value.month + " "+value.year !== "July 2023")  &&(value.month + " "+value.year !== "September 2023")  &&(value.month + " "+value.year !== "October 2023")  &&(value.month + " "+value.year !== "November 2023")  &&(value.month + " "+value.year !== "December 2023"))
        });
    }else {
        previousValues = props.previousValuesBrands;
    }

       // alert('ok');
        //carrefour
    }else{
       if(props.category.toLowerCase().includes("bleach")){
          // alert("ok");
           previousValues = props.previousValuesBrands.filter((value)=>{


               return ((value.month + " "+value.year !== "August 2023") &&(value.month + " "+value.year !== "July 2023"))
           });
       } else{

           previousValues = props.previousValuesBrands;

       }
    }

    }else{
        previousValues = props.previousValuesBrands;
    }
    if(props.category.toLowerCase().includes("bleach")){

        if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID || parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
            if(props.size === BLEACHES_750ML) {
                if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                    filteredPreviousValues = previousValues.filter((value) => {

                        return (value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[0] || value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[1] || value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR[2])

                    });
                }else {
                    filteredPreviousValues = previousValues.filter((value) => {

                        return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS[0] || value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS[1])

                    });
                }
            }else if(props.size === BLEACHES_1_L){
                if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){
                    filteredPreviousValues = previousValues.filter((value) => {

                        return (value.product === BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L[0])

                    });
                }else {
                    filteredPreviousValues = previousValues.filter((value) => {

                        return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS_1L[0])

                    });
                }

            }else if(props.size === BLEACHES_2_L){

                filteredPreviousValues = previousValues.filter((value) => {

                    return (value.product === BLEACHES_BRANDS_STACKBAR_NAIVAS_2L[0])

                });
            }

        }else {
            filteredPreviousValues = previousValues.filter((value) => {

                return (value.product === BLEACHES_BRANDS_STACKBAR[0] || value.product === BLEACHES_BRANDS_STACKBAR[1] || value.product === BLEACHES_BRANDS_STACKBAR[2])

            });
        }
        //console.log(JSON.stringify(filteredPreviousValues) + "filtered previous values")

    }else if(props.category.toLowerCase().includes("toilet")) {
        if(props.size === TOILETCLEANERS_500ML) {
            filteredPreviousValues = previousValues.filter((value) => {

                return (value.product === TOILETCLEANERS_BRANDS_STACKBAR[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR[7])
            })
        }else{
            if(parseInt(props.retailerId) === NAIVAS_RETAILER_ID) {
                filteredPreviousValues = previousValues.filter((value) => {

                    return (value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L[7])
                });
            }else if(parseInt(props.retailerId) === CARREFOUR_RETAILER_ID){

                filteredPreviousValues = previousValues.filter((value) => {

                    return (value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[0] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[1] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[2] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[3] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[4] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[5] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[6] || value.product === TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR[7])
                });
            }
        }

    }else if(props.category.toLowerCase().includes("diaper")){
       /**
        filteredPreviousValues = previousValues.filter((value)=>{

            return (value.brand === "Huggies")
        })
        **/
       filteredPreviousValues = previousValues;
    }


    let XAxisMonths = filteredPreviousValues.map((value)=>{


        return `${value.month.substring(0,3)} ${value.year}`;
    })

    let xAxisLabels = [...new Set(XAxisMonths)];
   // console.log(xAxisLabels + " X AXIS LABELS");
    //add predicted values
    xAxisLabels.push(formatDate(props.date))
    let datasetsLabels = [...new Set(filteredPreviousValues.map(value=>value.product))];
    function getQtyFromPredicted(productName){
        let predictedValue = 0;
        Object.keys(predictedResults).forEach(key => {


            if(parseInt(props.fmcgid) === RECKITT_FMCG_ID) {
                if (productName.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/","").replace("mpine", "mountainpine").replace("tcpowerpluslemon", "toiletcleanerpplem").includes(key.replace("Qty", "").replace("750ml", "").replace("700ml", "").replace("500ml","").toLowerCase())) {

                    predictedValue = parseFloat(predictedResults[key].toFixed(0));
                    console.log("found " + key);
                    //alert("found")

                }else{
                   // alert("not found");
                }

                //kimberly clarks
            }else {
                let gotProduct = `${key.replace("Qty", "").charAt(0).toUpperCase() + key.replace("Qty", "").slice(1)} ${props.segment} ${props.size}`;


                if (productName.toLowerCase().includes(gotProduct.toLowerCase())) {
//alert("found")
                    predictedValue = parseFloat(predictedResults[key].toFixed(0));
                    console.log("found " + key);
                }else{
                    //alert("not found" + props.fmcgid);
                }

            }



        });

        return predictedValue;


    }


    let extractedDataSets = [];
    //alert(datasetsLabels.length);
    datasetsLabels.forEach((value,index)=>{

        let filteredProducts = filteredPreviousValues.filter(filteredValue => filteredValue.product.toLowerCase() === value.toLowerCase());
        let productQty = filteredProducts.map(value => value.qty);
        productQty.push(getQtyFromPredicted(value));

        //add predicted values


        extractedDataSets.push({label:value, data:productQty, backgroundColor:DoghnutChartColors[index]});
    });


//alert(predictedResults.length);



    const data = {
        //labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November','December'],
        labels:xAxisLabels,
        datasets:extractedDataSets,
        /**
        datasets: [

            {
                label: 'Jik Bleach Regular 750ml',
                data: [28, 48, 40, 19, 86, 27, 90,80,45,56,33,78],
                backgroundColor: DoghnutChartColors[0],
            },
            {
                label: 'Jik Bleach Lemon 750ml',
                data: [35, 29, 50, 51, 36, 25, 30,34,67,89,32,45],
                backgroundColor:  DoghnutChartColors[1],

            },
        ],
         **/
    };

    const options = {
        plugins: {
            title: {
                display: true,
                fullSize:true,
                text: `${props.brand} Brands`,
                font: {
                    size: "26",
                    family: "Arial, Helvetica, sans-serif",
                    style: "italic",
                    weight: "bold"
                }
            },
            tooltip: {
                enabled: true,
            },
            datalabels: {
                display: false, // Disable default data labels
            },
            totalLabel: true,
            textInsideBar:true
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    font: {
                        size: 15, // Adjust the font size as needed
                    }
                }
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Qty in Pieces',
                    font: {
                        size: 16,
                        weight: 'bold',
                        family: "Arial, Helvetica, sans-serif",
                        style: "italic",

                    }
                },
                ticks: {
                    font: {
                        size: 15, // Adjust the font size as needed
                    }
                }
            },
        },
    };

    if(props.date === "2024-09-01") {
        return <Bar data={data} options={options}
                    plugins={[totalLabelPlugin, textInsideBarPlugin, dottedOutlinePlugin, dottedOutlinePlugin2]}/>;
    }else{
        return <Bar data={data} options={options}
                    plugins={[totalLabelPlugin, textInsideBarPlugin, dottedOutlinePlugin]}/>;

    }
    };

export default StackedBarChart;
