import styles from "./stylesheets/datepicker.module.css";

function DatePicker(props){

   const handeDatePicked = (event)=>{
       props.onhandleDatePickerChange(event.target.value);


   }

    return(<div className={styles.datepickercontainer}>
            <label className={styles.datepickerlabel} htmlFor={props.labelid}>{props.labeltitle}</label>
            <input type="month" onChange={handeDatePicked} className={styles.datepicker} id={props.labelid} name="datePicker" min={props.min} max={props.max}/>

        </div>
    )




}

export default DatePicker