// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdowncontainer_dropwncontainer__fUcs7{
    margin-right: 12px;



}

.dropdowncontainer_dropdown__sH9EV{
    margin-left: 7px;
    width: var(--dropdown_width);
    padding: 5px 10px;
    font-weight: var(--font_regular);
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 8px;
}
.dropdowncontainer_dropdownlabel__Xo0L\\+{
    font-weight: var(--font_medium);
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/dropdowncontainer.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;;;AAItB;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,iBAAiB;IACjB,gCAAgC;IAChC,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,+BAA+B;IAC/B,eAAe;AACnB","sourcesContent":[".dropwncontainer{\r\n    margin-right: 12px;\r\n\r\n\r\n\r\n}\r\n\r\n.dropdown{\r\n    margin-left: 7px;\r\n    width: var(--dropdown_width);\r\n    padding: 5px 10px;\r\n    font-weight: var(--font_regular);\r\n    font-size: 14px;\r\n    margin-top: 5px;\r\n    margin-bottom: 8px;\r\n}\r\n.dropdownlabel{\r\n    font-weight: var(--font_medium);\r\n    font-size: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropwncontainer": `dropdowncontainer_dropwncontainer__fUcs7`,
	"dropdown": `dropdowncontainer_dropdown__sH9EV`,
	"dropdownlabel": `dropdowncontainer_dropdownlabel__Xo0L+`
};
export default ___CSS_LOADER_EXPORT___;
