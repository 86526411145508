import styles from './stylesheets/sidebar.module.css'
import {useState} from "react";

function Sidebar(props) {

   // const[showScenario, setScenarioStatus] = useState(false);

    const scenarioEvent = (event)=>{
        event.preventDefault();

        props.onhandleShowScenarioStateChange(true);
        props.onhandleAutomateStateChange(false);
        props.onhandleShowmyPlanStateChange(false);
        props.onhandleShowSettingsStateChange(false);
        props.onChangeActiveLink(1);



    }

    const whatifEvent = (event)=>{
        event.preventDefault();

        props.onhandleShowScenarioStateChange(false);
        props.onhandleAutomateStateChange(false);
        props.onhandleShowmyPlanStateChange(false);
        props.onhandleShowSettingsStateChange(false);
        props.onChangeActiveLink(0);
    }

    const automateEvent = (event)=>{
        event.preventDefault();

        props.onhandleAutomateStateChange(true);
        props.onhandleShowScenarioStateChange(false);
        props.onhandleShowmyPlanStateChange(false);
        props.onhandleShowSettingsStateChange(false);
        props.onChangeActiveLink(2);
    }

    const myPlanEvent = (event)=>{
        event.preventDefault();

        props.onhandleAutomateStateChange(false);
        props.onhandleShowScenarioStateChange(false);
        props.onhandleShowmyPlanStateChange(true);
        props.onhandleShowSettingsStateChange(false);
        props.onChangeActiveLink(3);
    }

    const settingsEvent = (event)=>{
        event.preventDefault();

        props.onhandleAutomateStateChange(false);
        props.onhandleShowScenarioStateChange(false);
        props.onhandleShowmyPlanStateChange(false);
        props.onhandleShowSettingsStateChange(true);
        props.onChangeActiveLink(4);
    }

    /**
    const loginEvent = () => {
        onhandleShowScenarioStateChange(false);
    }
     **/

    const logoutEvent = (event)=>{
        event.preventDefault();
        props.onChangeActiveLink(5);
     props.onLogoutStateChange();

    }

    return(<div className={styles.sidebar}>
           <ul>

           <li className={props.activeLink === 0 ? styles.active :styles.inactive} onClick={whatifEvent}> <a href="#" onClick={whatifEvent}   target="_self">What if</a> </li>
               <li className={props.activeLink === 1 ? styles.active :styles.inactive} onClick={scenarioEvent}> <a href="#"   target="_self">Scenario</a> </li>
               <li className={props.activeLink === 2 ? styles.active :styles.inactive} onClick={automateEvent} > <a href="#"   target="_self">Optimize</a> </li>
               <li className={props.activeLink === 3 ? styles.active :styles.inactive} onClick={myPlanEvent} > <a href="#"   target="_self">My Plan</a> </li>
               <li className={props.activeLink === 4 ? styles.active :styles.inactive} onClick={settingsEvent} > <a href="#"   target="_self">Settings</a> </li>
               <li className={props.activeLink === 5 ? styles.active :styles.inactive} onClick={logoutEvent} > <a href="#"    target="_self">Logout</a> </li>

           </ul>


    </div>)


}
export default Sidebar;