import LandingPageHeader from "../components/LandingPageHeader";
import LandingpageFooter from "../components/LandingpageFooter";

function Termsofservice(){

    return <>
        <LandingPageHeader/>
        <LandingpageFooter/>
    </>

}
export default Termsofservice;