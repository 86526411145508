// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration_mainlayoutauthentication__srTgt{

    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

}

.registration_formtitlelayout__KfZM4{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

}



.registration_formlayout__QcJJd{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 40%;

}
.registration_formcomponents__7ZqbL{

    margin-top: var(--textfields_margintopdown) !important;
    margin-bottom: var(--textfields_margintopdown) !important;
    font-weight: var(--font_medium);
}

.registration_formcomponentsbutton__ttSzB{

    margin-top: var(--form_submit_button_margintop) !important;
    margin-bottom: var(--textfields_margintopdown) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/registration.module.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;;AAErB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;;AAErB;;;;AAIA;;IAEI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,iBAAiB;IACjB,UAAU;;AAEd;AACA;;IAEI,sDAAsD;IACtD,yDAAyD;IACzD,+BAA+B;AACnC;;AAEA;;IAEI,0DAA0D;IAC1D,yDAAyD;AAC7D","sourcesContent":[".mainlayoutauthentication{\r\n\r\n    width: 90%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n\r\n}\r\n\r\n.formtitlelayout{\r\n    width: 40%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n\r\n}\r\n\r\n\r\n\r\n.formlayout{\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n    flex-wrap: nowrap;\r\n    width: 40%;\r\n\r\n}\r\n.formcomponents{\r\n\r\n    margin-top: var(--textfields_margintopdown) !important;\r\n    margin-bottom: var(--textfields_margintopdown) !important;\r\n    font-weight: var(--font_medium);\r\n}\r\n\r\n.formcomponentsbutton{\r\n\r\n    margin-top: var(--form_submit_button_margintop) !important;\r\n    margin-bottom: var(--textfields_margintopdown) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainlayoutauthentication": `registration_mainlayoutauthentication__srTgt`,
	"formtitlelayout": `registration_formtitlelayout__KfZM4`,
	"formlayout": `registration_formlayout__QcJJd`,
	"formcomponents": `registration_formcomponents__7ZqbL`,
	"formcomponentsbutton": `registration_formcomponentsbutton__ttSzB`
};
export default ___CSS_LOADER_EXPORT___;
