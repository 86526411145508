import DropDown from "./DropDown";
import styles from './stylesheets/dropdownlayout.module.css';
import axios from "axios";
import DatePicker from "./DatePicker";
import {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import MultipleSelect from "./MultipleSelect";
import {
    BLEACHES_CURRENT_PRICES_STORAGE_KEY, CARREFOUR_RETAILER_ID, DIAPERS_MODEL_ID, KIMBERLY_CLARKS_FMCG_ID,
    ML_TARGETS_STORAGE_KEY, MLTARGETS_MILLISECONDS_KEY,
    NAIVAS_BLEACHES_MODEL_ID, NAIVAS_RETAILER_ID,
    NAIVAS_TOILETCLEANERS_MODEL_ID,
    PRICELIST_MILLISECONDS_KEY, RECKITT_FMCG_ID, REFRESH_ML_TARGETS_DURATION, REFRESH_PRICELIST_DURATION
} from "../utils/Constants";
function Target(prop) {

    const[isLoading, setLoading] = useState(true);
    const[filteredData, setFilteredData] = useState([]);
    const [data, setData] = useState([]);
    const[showMultipleRetailerDropDown, setShowMultipleRetailerDropDown] = useState(false);

//alert(prop.modelId);
    useEffect(()=>{
        if(data.length === 0) {

            if(localStorage.getItem(ML_TARGETS_STORAGE_KEY) === null) {
                let MlTargetsRequest;

                if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                    if (prop.category === "Regular Bleach") {

                        MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_BLEACHES_MODEL_ID}

                    } else {

                        MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_TOILETCLEANERS_MODEL_ID}
                    }
                }else{
                    MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": DIAPERS_MODEL_ID}

                }
                // const MlTargetsRequest = {"fmcgId":34, "modelId":10}

                // alert(prop.category);

                axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mltargets', MlTargetsRequest).then(function (response) {

                    console.log(response);

                    setLoading(false);

                    let results = response.data.mlTargetsResponseItemList;
                    localStorage.setItem(ML_TARGETS_STORAGE_KEY,JSON.stringify(results));
                    localStorage.setItem(MLTARGETS_MILLISECONDS_KEY,new Date().getTime().toFixed(0))

                    let results_filtered = [];
                    if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                        if (prop.category.toLowerCase().includes("toilet")) {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === "Toilet Cleaner" && value.modelId === parseInt(prop.modelId);

                            });

                        } else {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === prop.category && value.modelId === parseInt(prop.modelId);

                            });
                        }
                    }else{
                        //kimberly clarks
                        results_filtered = results.filter(function (value, index, array) {

                            return  value.brand=== prop.brand;

                        });


                    }

                    setData(results);
                    setFilteredData(results_filtered);


                }).catch(function (error) {

                    setLoading(false);
                    console.error(error)
                });

            }else{

                let previousMilliSeconds = parseFloat(localStorage.getItem(MLTARGETS_MILLISECONDS_KEY));
                let currentMilliseconds = parseFloat(new Date().getTime().toFixed(0));
                if((currentMilliseconds - previousMilliSeconds)<=REFRESH_ML_TARGETS_DURATION) {
                setLoading(false);

                    let results = JSON.parse(localStorage.getItem(ML_TARGETS_STORAGE_KEY));
                    let results_filtered = [];
                    if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                        if (prop.category.toLowerCase().includes("toilet")) {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === "Toilet Cleaner" && value.modelId === parseInt(prop.modelId);

                            });

                        } else {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === prop.category && value.modelId === parseInt(prop.modelId);

                            });
                        }
                    }else{

                        results_filtered = results.filter(function (value, index, array) {

                            return  value.brand === prop.brand;

                        });
                    }

                    setData(results);
                    setFilteredData(results_filtered);

                }else{

                    let MlTargetsRequest;

                    if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                        if (prop.category === "Regular Bleach") {

                            MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_BLEACHES_MODEL_ID}

                        } else {

                            MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_TOILETCLEANERS_MODEL_ID}
                        }
                    }else{
                        MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": DIAPERS_MODEL_ID}

                    }
                    // const MlTargetsRequest = {"fmcgId":34, "modelId":10}

                    // alert(prop.category);

                    axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mltargets', MlTargetsRequest).then(function (response) {

                        console.log(response);

                        setLoading(false);

                        let results = response.data.mlTargetsResponseItemList;
                        localStorage.setItem(ML_TARGETS_STORAGE_KEY,JSON.stringify(results));
                        localStorage.setItem(MLTARGETS_MILLISECONDS_KEY,new Date().getTime().toFixed(0))

                        let results_filtered = [];
                        if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                            if (prop.category.toLowerCase().includes("toilet")) {

                                results_filtered = results.filter(function (value, index, array) {

                                    return value.category === "Toilet Cleaner" && value.modelId === parseInt(prop.modelId);

                                });

                            } else {

                                results_filtered = results.filter(function (value, index, array) {

                                    return value.category === prop.category && value.modelId === parseInt(prop.modelId);

                                });
                            }
                        }else{
                            results_filtered = results.filter(function (value, index, array) {

                                return  value.modelId === parseInt(prop.modelId);

                            });
                        }

                        setData(results);
                        setFilteredData(results_filtered);


                    }).catch(function (error) {

                        setLoading(false);
                        console.error(error)
                    });


                }

            }


        }else{

            let results_filtered = [];
            if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                if (prop.category.toLowerCase().includes("toilet")) {

                    results_filtered = data.filter(function (value, index, array) {

                        return value.category === "Toilet Cleaner" && value.modelId === parseInt(prop.modelId);

                    });

                } else {
                    results_filtered = data.filter(function (value, index, array) {

                        return value.category === prop.category && value.modelId === parseInt(prop.modelId);

                    });
                }
            }else{
                //kimberly clarks

                results_filtered = data.filter(function (value, index, array) {

                    return  value.brand === prop.brand;

                });

            }


            setFilteredData(results_filtered);


        }

    }, [prop.category, prop.modelId]);
    /**
    useEffect(()=>{
        if(data.length === 0) {

            if(localStorage.getItem(ML_TARGETS_STORAGE_KEY) === null) {
                let MlTargetsRequest;

                if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                    if (prop.category === "Regular Bleach") {

                        MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_BLEACHES_MODEL_ID}

                    } else {

                        MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_TOILETCLEANERS_MODEL_ID}
                    }
                }else{
                    MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": DIAPERS_MODEL_ID}

                }
                // const MlTargetsRequest = {"fmcgId":34, "modelId":10}

                // alert(prop.category);

                axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mltargets', MlTargetsRequest).then(function (response) {

                    console.log(response);

                    setLoading(false);

                    let results = response.data.mlTargetsResponseItemList;
                    localStorage.setItem(ML_TARGETS_STORAGE_KEY,JSON.stringify(results));
                    localStorage.setItem(MLTARGETS_MILLISECONDS_KEY,new Date().getTime().toFixed(0))

                    let results_filtered = [];
                    if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                        if (prop.category.toLowerCase().includes("toilet")) {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === "Toilet Cleaner" && value.modelId === prop.modelId;

                            });

                        } else {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === prop.category && value.modelId === prop.modelId;

                            });
                        }
                    }else{
                        //kimberly clarks
                        results_filtered = results.filter(function (value, index, array) {

                            return  value.brand=== prop.brand;

                        });


                    }

                    setData(results);
                    setFilteredData(results_filtered);


                }).catch(function (error) {

                    setLoading(false);
                    console.error(error)
                });

            }else{

                let previousMilliSeconds = parseFloat(localStorage.getItem(MLTARGETS_MILLISECONDS_KEY));
                let currentMilliseconds = parseFloat(new Date().getTime().toFixed(0));
                if((currentMilliseconds - previousMilliSeconds)<=REFRESH_ML_TARGETS_DURATION) {
                    setLoading(false);

                    let results = JSON.parse(localStorage.getItem(ML_TARGETS_STORAGE_KEY));
                    let results_filtered = [];
                    if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                        if (prop.category.toLowerCase().includes("toilet")) {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === "Toilet Cleaner" && value.modelId === prop.modelId;

                            });

                        } else {

                            results_filtered = results.filter(function (value, index, array) {

                                return value.category === prop.category && value.modelId === prop.modelId;

                            });
                        }
                    }else{

                        results_filtered = results.filter(function (value, index, array) {

                            return  value.brand === prop.brand;

                        });
                    }

                    setData(results);
                    setFilteredData(results_filtered);

                }else{

                    let MlTargetsRequest;

                    if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                        if (prop.category === "Regular Bleach") {

                            MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_BLEACHES_MODEL_ID}

                        } else {

                            MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": NAIVAS_TOILETCLEANERS_MODEL_ID}
                        }
                    }else{
                        MlTargetsRequest = {"fmcgId": prop.fmcgId, "modelId": DIAPERS_MODEL_ID}

                    }
                    // const MlTargetsRequest = {"fmcgId":34, "modelId":10}

                    // alert(prop.category);

                    axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mltargets', MlTargetsRequest).then(function (response) {

                        console.log(response);

                        setLoading(false);

                        let results = response.data.mlTargetsResponseItemList;
                        localStorage.setItem(ML_TARGETS_STORAGE_KEY,JSON.stringify(results));
                        localStorage.setItem(MLTARGETS_MILLISECONDS_KEY,new Date().getTime().toFixed(0))

                        let results_filtered = [];
                        if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                            if (prop.category.toLowerCase().includes("toilet")) {

                                results_filtered = results.filter(function (value, index, array) {

                                    return value.category === "Toilet Cleaner" && value.modelId === prop.modelId;

                                });

                            } else {

                                results_filtered = results.filter(function (value, index, array) {

                                    return value.category === prop.category && value.modelId === prop.modelId;

                                });
                            }
                        }else{
                            results_filtered = results.filter(function (value, index, array) {

                                return  value.modelId === prop.modelId;

                            });
                        }

                        setData(results);
                        setFilteredData(results_filtered);


                    }).catch(function (error) {

                        setLoading(false);
                        console.error(error)
                    });


                }

            }


        }else{

            let results_filtered = [];
            if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID) {
                if (prop.category.toLowerCase().includes("toilet")) {

                    results_filtered = data.filter(function (value, index, array) {

                        return value.category === "Toilet Cleaner" && value.modelId === prop.modelId;

                    });

                } else {
                    results_filtered = data.filter(function (value, index, array) {

                        return value.category === prop.category && value.modelId === prop.modelId;

                    });
                }
            }else{
                //kimberly clarks

                results_filtered = data.filter(function (value, index, array) {

                    return  value.brand === prop.brand;

                });

            }


            setFilteredData(results_filtered);


        }

    }, []);
     **/
    useEffect(()=>{

        if(parseInt(prop.fmcgId) === KIMBERLY_CLARKS_FMCG_ID){
        let results = data.filter((value)=>{


            return  (value.brand === prop.brand)
        })

        setFilteredData(results);
       // alert('ok');
        }

    },[prop.brand])

    useEffect(()=>{

        if(parseInt(prop.fmcgId) === KIMBERLY_CLARKS_FMCG_ID){

            let results = data.filter((value)=>{


                return  (value.brand === prop.brand && value.segment === prop.segment)
            })

            setFilteredData(results);


        }else {
            if(parseInt(prop.fmcgId) === RECKITT_FMCG_ID){

                if (prop.category === "Regular Bleach") {
                    //naivas
                    if(parseInt(prop.retailer) === NAIVAS_RETAILER_ID || parseInt(prop.retailer) === CARREFOUR_RETAILER_ID ){
                        let results = data.filter((value)=>{


                            return  (value.category === prop.category && value.modelId === parseInt(prop.modelId) && value.size === prop.size)
                        });
                        setFilteredData(results);

                    }
                }else {
                    if(parseInt(prop.retailer) === NAIVAS_RETAILER_ID || parseInt(prop.retailer) === CARREFOUR_RETAILER_ID ){
                        let results = data.filter((value)=>{


                            return  (value.category === 'Toilet Cleaner' && value.modelId === parseInt(prop.modelId) && value.size === prop.size)
                        });
                        setFilteredData(results);

                    }
                }

            }
        }

    },[prop.size])



    useEffect(()=>{

        if(parseInt(prop.fmcgId) === KIMBERLY_CLARKS_FMCG_ID){

            let results = data.filter((value)=>{


                return  (value.brand === prop.brand && value.segment === prop.segment )
            })
            setFilteredData(results);


        }

    },[prop.segment])

    useEffect(()=>{
        if(prop.showAutomateState === true){

            setShowMultipleRetailerDropDown(true);
        }else{
            setShowMultipleRetailerDropDown(false);
        }

    }, [prop.showAutomateState]);




    if(isLoading){
        return <CircularProgress></CircularProgress>;
    }else {

        let retailersData = prop.category === "Regular Bleach"?data:data;


        let dropDown;
        let segmentDropDown;
        let brandDropDown;
        let sizeDropDown;
        let prodctDropDown;


        if(showMultipleRetailerDropDown){

            dropDown = <MultipleSelect labeltitle = "Retailers"  onhandleDropDownStateChange={prop.onhandleDropDownStateChange}></MultipleSelect>
        }else{

            dropDown =  <DropDown labeltitle="Retailer" labelid="retailer"
                                  onhandleDropDownStateChange={prop.onhandleDropDownStateChange} dropDownData = {retailersData}/>


        }

        //alert(prop.fmcgId);
        if(parseInt(prop.fmcgId) === KIMBERLY_CLARKS_FMCG_ID){
            segmentDropDown =  <DropDown labeltitle="Segment" labelid="segment"
                                  onhandleDropDownStateChange={prop.onhandleDropDownStateChange} dropDownData = {data}/>
brandDropDown =  <DropDown labeltitle="Brand" labelid="brand"
                           onhandleDropDownStateChange={prop.onhandleDropDownStateChange} dropDownData = {data}/>
           // alert(filteredData.length);
            sizeDropDown =   <DropDown labeltitle="Size" labelid="size"
                                       onhandleDropDownStateChange={prop.onhandleDropDownStateChange} dropDownData = {filteredData}/>

        }else{
           // segmentDropDown = <p></p>
            if(filteredData.length === 0){
                let gotData;
                if (prop.category === "Regular Bleach") {
                    gotData = data.filter((value) => {
                        return value.category === prop.category;
                    })
                }else{
                    gotData = data.filter((value) => {
                        return value.category === 'Toilet Cleaner';
                    })
                }
                brandDropDown = <DropDown labeltitle="Brand" labelid="brand"
                                          onhandleDropDownStateChange={prop.onhandleDropDownStateChange}
                                          dropDownData={gotData}/>
            }else {
                brandDropDown = <DropDown labeltitle="Brand" labelid="brand"
                                          onhandleDropDownStateChange={prop.onhandleDropDownStateChange}
                                          dropDownData={filteredData}/>
            }

            if ((prop.category === "Regular Bleach") && (parseInt(prop.retailer) === NAIVAS_RETAILER_ID || parseInt(prop.retailer) === CARREFOUR_RETAILER_ID)) {
                //naivas

                 let gotData = data.filter((value)=>{

                     return value.size !== "500ml" && value.size !== "1000ml";
                 })
                sizeDropDown =   <DropDown labeltitle="Size" labelid="size"
                                           onhandleDropDownStateChange={prop.onhandleDropDownStateChange} category = {prop.category} retailer = {prop.retailer} dropDownData = {gotData}/>


            }else{

                let gotData = data.filter((value)=>{

                    return value.size !== "750ml" && value.size !== "1500ml" && value.size !== "2250ml";
                })
                sizeDropDown =   <DropDown labeltitle="Size" labelid="size"
                                           onhandleDropDownStateChange={prop.onhandleDropDownStateChange} category = {prop.category} retailer = {prop.retailer} dropDownData = {gotData}/>


            }

        }
        if(filteredData.length === 0){
            prodctDropDown =  <DropDown labeltitle="Product" labelid="product"
                                        onhandleDropDownStateChange={prop.onhandleDropDownStateChange} dropDownData = {data}/>

        }else{
          prodctDropDown =   <DropDown labeltitle="Product" labelid="product"
                      onhandleDropDownStateChange={prop.onhandleDropDownStateChange} dropDownData = {filteredData}/>

        }

        return (<div className="dropdownsection">
                <h2 className={styles.dropdownsectiontitle}>{prop.sectionname}</h2>
                <hr/>
                <div className={styles.dropdownsectionslayout}>
                <div className={styles.dropdownsectionleft}>
                <div className={styles.dropdownlayout}>
                    <DropDown labeltitle="Category" labelid="subcategory"
                              onhandleDropDownStateChange={prop.onhandleDropDownStateChange}  dropDownData = {data}/>
                    {brandDropDown}
                    {segmentDropDown}
                    {sizeDropDown}
                    {prodctDropDown}

                    {dropDown}

                         <DatePicker onhandleDatePickerChange = {prop.onhandleDatePickerChange} labeltitle="Date" labelid="predictdate" min = "2023-08" max = "2024-09"/>



                </div>
                </div>
                <div className={styles.dropdownsectionright}>
                 <img src={prop.img_url} className={styles.productimage}/>

                </div>
                </div>

            </div>


        )
    }


}

export default Target