
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useRef, useState} from "react";


function AddScenarioDialog(props) {

    const scenarioName = useRef("");
    const [error, setError] = useState(false);
    const[errorMessage, setErrorMessage] = useState("");

    const handleClickSave = () => {

        if(scenarioName.current.length === 0){
            setError(true);
            setErrorMessage("input scenario name");
        }else if(localStorage.getItem(scenarioName.current) !== null) {

            setError(true);
            setErrorMessage("scenario name exists");
        }else{
            setError(false);
            setErrorMessage("");
            props.onCloseDialog(scenarioName.current);
            scenarioName.current = "";
        }
    };

    const handleChange = (event)=>{

        scenarioName.current = event.target.value;

    };

    const handleClose = () => {
       // setOpen(false);
        scenarioName.current = "";
        setError(false);
    props.onCloseDialog("close dialog");
    };

    return (
        <React.Fragment>

            <Dialog
                open={props.dialogopenstatus}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Save Scenario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter Scenario name to be able to recreate it later
                    </DialogContentText>

                    <TextField
                        error={error}
                        autoFocus
                        required
                        margin="dense"
                        id="standard-error-helper-text"
                        name="scenarioname"
                        label="Scenario Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        helperText={errorMessage}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClickSave} >Save</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default AddScenarioDialog;
