import DropDown from "./DropDown";
import EventButton from "./EventButton";
import  Sliders from "./Sliders"
import styles from './stylesheets/scenarios.module.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Button, CircularProgress} from "@mui/material";
import {useEffect, useState} from "react";
import AddScenarioDialog from "./SaveScenarioDialog";
import AlertDialog from "./AlertDialog";
import {Delete} from "@mui/icons-material";
import {formatDate, getMonthFormatted, getScenariosTargets, getYearFormatted} from "../utils/Utilities";
import axios from "axios";
import DoghnutChartWhatIfAnalaysisLayout from "./DoghnutChartWhatIfAnalaysisLayout";
import {
    BLEACHES_1_L, BLEACHES_2_L,
    BLEACHES_750ML,
    CARREFOUR_RETAILER_ID,
    NAIVAS_RETAILER_ID,
    SCENARIOS_BLEACHES_PRODUCT_COUNT_CARREFOUR,
    SCENARIOS_BLEACHES_PRODUCT_COUNT_NAIVAS,
    SCENARIOS_TOILET_CLEANERS_PRODUCT_COUNT
} from "../utils/Constants";


function Scenarios(prop) {

    const[price, setPrice] = useState(0);
    const[period, setPeriod] = useState(1);
    const [discount, setDiscount] = useState(0);
    const [inStoreMarketing, setInstoreMarketing] = useState(0);
    const [product, setProduct] = useState("");
    const [layoutVisibility, setLayoutVisibility] = useState("hidden");
    const [rows, setRows] = useState([]);
    const [myScenarios, setMyScenarios] = useState([]);
    const[data,setData] = useState([]);
    const[targetsLength, setTargetLengths] = useState(0);
    const [scenarioTitle, setScenarioTitle] = useState("Scenario Summary");
    const [openSaveScenarioDialogStatus, setOpenSaveScenarioDialogStatus] = useState(false);
    const[alertDialogStatus, setAlertDialogStatus] = useState(false);
    const[dialogTitle, setDialogTitle] = useState("");
    const [dialogText, setDialogText] = useState("");
    const [selectedCurrentProductPrice, setSelectedCurrentProductPrice] = useState(0);
    const [showChart, setShowChart] = useState(false);
    const[showLoader, setShowLoader] = useState(false);

    const [previousMonthLabelsA, setPreviousMonthLabels] = useState([]);
    const [previousMonthValuesA, setPreviousMonthValues] = useState([]);
    const [predictedMonthLabelsA,setPredictedMonthLabels ] = useState([]);
    const [predictedMonthValuesA, setPredictedMonthValues] = useState([]);


    let loader;

   // let data = [{targetName :"Jik Bleach Regular 750ml"},{targetName:"Jik Bleach Lemon 750ml"}, {targetName:"Jik Bleach Lemon 750ml"}, {targetName: "Jik Bleach Spring Fresh 750ml"}, {targetName: "Omo Bleach Lemon Fresh 700ml"}, {targetName: "Omo Bleach Regular 700ml"},{targetName: "Safisha Bleach Lemon 750ml"},{targetName: "Safisha Bleach Regular 750ml"},{targetName: "Topex Bleach Lemon 750ml"},{targetName: "Topex Bleach Regular 750ml"}];

    useEffect(()=>{
        let targetsArray = getScenariosTargets(parseInt(prop.retailer), prop.size, parseInt(prop.modelId));
        setData(targetsArray);
        setTargetLengths(targetsArray.length);
        /**
        if(prop.category  === "Bleach"){
            if(parseInt(prop.retailer) === NAIVAS_RETAILER_ID) {
                if(prop.size === BLEACHES_750ML) {
                    setData([{targetName: "Jik Bleach Regular 750ml"},
                        {targetName: "Jik Bleach Lemon 750ml"},
                        {targetName: "Omo Bleach Lemon Fresh 700ml"},
                        {targetName: "Omo Bleach Regular 700ml"},
                        {targetName: "Safisha Bleach Lemon 750ml"},
                        {targetName: "Safisha Bleach Regular 750ml"},
                        {targetName: "Topex Bleach Lemon 750ml"},
                        {targetName: "Topex Bleach Regular 750ml"}]);
                }else if(prop.size === BLEACHES_1_L){


                }else if(prop.size === BLEACHES_2_L){

                }
            }else{

                setData([{targetName: "Jik Bleach Regular 750ml"},
                    {targetName: "Jik Bleach Lemon 750ml"},
                    {targetName: "Omo Bleach Lemon Fresh 700ml"},
                    {targetName: "Omo Bleach Regular 700ml"},
                    {targetName: "Safisha Bleach Lemon 750ml"},
                    {targetName: "Safisha Bleach Regular 750ml"},
                    {targetName: "Topex Bleach Lemon 750ml"},
                    {targetName: "Topex Bleach Regular 750ml"},
                    {targetName: "Msafi Bleach Lavender 750ml"},
                    {targetName: "Msafi Bleach Lemon Fresh 750ml"},
                    {targetName: "Msafi Bleach Potpourri 750ml"},
                    {targetName: "Msafi Bleach Regular 750ml"},
                    {targetName: "Jik Bleach Regular 2+1 Promo Pack 3x750ml"}]);

            }
        }else{
            setData([
                { targetName: "Harpic Power Plus Citrus 500ml" },
                { targetName: "Harpic Power Plus Lavender 500ml" },
                { targetName: "Harpic Power Plus Original 500ml" },
                { targetName: "Harpic Toilet Cleaner Mountain Pine 500ml" },
                { targetName: "Harpic Toilet Cleaner P/P Flo 500ml" },
                { targetName: "Harpic Toilet Cleaner P/P Lem 500ml" },
                { targetName: "Hurricane 500ml" },
                { targetName: "Ace 500ml" },
                { targetName: "Atilla 500ml" },
                { targetName: "Tropikal 500ml" },
                { targetName: "Safisha 500ml" },
                { targetName: "Domestos 500ml" },
                { targetName: "Velvex 500ml" },
                {targetName: "Harpic Power Plus Original 2x500ml"},
                {targetName: "Harpic Power Plus Citrus 2x500ml"}
            ]);

        }
         **/
    }, [prop.retailer])

    useEffect(()=>{
        let targetsArray = getScenariosTargets(parseInt(prop.retailer), prop.size, parseInt(prop.modelId));
        setData(targetsArray);
        setTargetLengths(targetsArray.length);
    },[prop.size])
    useEffect(()=>{
       // alert(prop.category);
        let targetsArray = getScenariosTargets(parseInt(prop.retailer), prop.size, parseInt(prop.modelId));
        setData(targetsArray);
        setTargetLengths(targetsArray.length);
        /**
        if(prop.category  === "Bleach"){
            if(parseInt(prop.retailer) === NAIVAS_RETAILER_ID) {
                setData([{targetName: "Jik Bleach Regular 750ml"},
                    {targetName: "Jik Bleach Lemon 750ml"},
                    {targetName: "Omo Bleach Lemon Fresh 700ml"},
                    {targetName: "Omo Bleach Regular 700ml"},
                    {targetName: "Safisha Bleach Lemon 750ml"},
                    {targetName: "Safisha Bleach Regular 750ml"},
                    {targetName: "Topex Bleach Lemon 750ml"},
                    {targetName: "Topex Bleach Regular 750ml"}]);
            }else{
                setData([{targetName: "Jik Bleach Regular 750ml"},
                    {targetName: "Jik Bleach Lemon 750ml"},
                    {targetName: "Omo Bleach Lemon Fresh 700ml"},
                    {targetName: "Omo Bleach Regular 700ml"},
                    {targetName: "Safisha Bleach Lemon 750ml"},
                    {targetName: "Safisha Bleach Regular 750ml"},
                    {targetName: "Topex Bleach Lemon 750ml"},
                    {targetName: "Topex Bleach Regular 750ml"},
                    {targetName: "Msafi Bleach Lavender 750ml"},
                    {targetName: "Msafi Bleach Lemon Fresh 750ml"},
                    {targetName: "Msafi Bleach Potpourri 750ml"},
                    {targetName: "Msafi Bleach Regular 750ml"},
                    {targetName: "Jik Bleach Regular 2+1 Promo Pack 3x750ml"}]);

            }
        }else{
            setData([
                { targetName: "Harpic Power Plus Citrus 500ml" },
                { targetName: "Harpic Power Plus Lavender 500ml" },
                { targetName: "Harpic Power Plus Original 500ml" },
                { targetName: "Harpic Toilet Cleaner Mountain Pine 500ml" },
                { targetName: "Harpic Toilet Cleaner P/P Flo 500ml" },
                { targetName: "Harpic Toilet Cleaner P/P Lem 500ml" },
                { targetName: "Hurricane 500ml" },
                { targetName: "Ace 500ml" },
                { targetName: "Atilla 500ml" },
                { targetName: "Tropikal 500ml" },
                { targetName: "Safisha 500ml" },
                { targetName: "Domestos 500ml" },
                { targetName: "Velvex 500ml" },
                {targetName: "Harpic Power Plus Original 2x500ml"},
                {targetName: "Harpic Power Plus Citrus 2x500ml"}
            ]);

        }
         **/

    },[prop.category])



    let instoreMaketing = "Instore\nMarketing";
const handleDeleteScenarioEvent = (event) =>{
//show scenario title
    //alert(scenarioTitle.replace("summary",""));
    let scenariosStringArray = localStorage.getItem("scenarios");
    if(scenariosStringArray !== null) {
        let selectedScenario = scenarioTitle.replace("summary", "").trim();
//get scenario names array


        let scenariosArray = JSON.parse(scenariosStringArray);
        //filter out scenario names
        const filteredScenarioNames = scenariosArray.filter((value) => {

            return value.scenario !== selectedScenario;

        });
        // alert(selectedScenario.length + " "+selectedScenario.trim().length);

        if (filteredScenarioNames.length === 0) {

            localStorage.removeItem("scenarios");

        } else {
            localStorage.setItem("scenarios", JSON.stringify(filteredScenarioNames));
        }

        localStorage.removeItem(selectedScenario);

        setRows([]);

        if (filteredScenarioNames.length === 0) {
            setMyScenarios([]);
        } else {
            setMyScenarios(filteredScenarioNames);
        }
    }else{
        setRows([]);
        setMyScenarios([]);
    }
setScenarioTitle("Scenario Summary");
    setLayoutVisibility("hidden");
    setShowChart(false);




};

    const handleSaveScenarioEvent = (event)=>{
        if(prop.category.toLowerCase().includes("toilet")){
            if(rows.length !== targetsLength){

                setDialogTitle("NOTICE");
                setDialogText("Please ensure you have added all the scenario products for "+prop.category);
                setAlertDialogStatus(true);
        }else {

                //alert(JSON.stringify(rows));
                setOpenSaveScenarioDialogStatus(true);

                console.log(openSaveScenarioDialogStatus);
                // alert("add scenario");
        }
        }else {
            if (parseInt(prop.retailer) === CARREFOUR_RETAILER_ID && rows.length !== targetsLength) {
                setDialogTitle("NOTICE");
                setDialogText("Please ensure you have added all the scenario products for " + prop.category);
                setAlertDialogStatus(true);

            }else if(parseInt(prop.retailer) === NAIVAS_RETAILER_ID && rows.length !== targetsLength){
                setDialogTitle("NOTICE");
                setDialogText("Please ensure you have added all the scenario products for " + prop.category);
                setAlertDialogStatus(true);

            } else {
                //alert(JSON.stringify(rows));
                setOpenSaveScenarioDialogStatus(true);

                console.log(openSaveScenarioDialogStatus);
                // alert("add scenario");
            }
        }



    };

    const getFilteredPrice = (productName, gotRetailer) => {

        const filterResults = prop.pricesList.filter((value, index, array) => {


            return (value.product === productName) && (value.retailer_id === parseInt(gotRetailer));
        });
        if(filterResults.length > 0){
            return filterResults[0].price;
        }else{
            if(prop.category.toLowerCase().includes("toilet")){

                if(productName.includes("Harpic")){

                    return  600;

                }else{

                    return 0;
                }

            }else {
                return 0;

            }
        }


    };
    

    const handleRunScenarioEvent = (event)=>{
        setShowLoader(true);
        //alert("Run scenarios feature is currently under development");


if (prop.category === "Bleach" && parseInt(prop.retailer) === CARREFOUR_RETAILER_ID && rows.length !== targetsLength) {

    setShowLoader(false);
    setDialogTitle("NOTICE");
    setDialogText(`Please ensure you have selected the correct category  and added all the products`);

    setAlertDialogStatus(true);

} else if (prop.category === "Bleach" && parseInt(prop.retailer) === NAIVAS_RETAILER_ID && rows.length !== targetsLength) {

        setShowLoader(false);
        setDialogTitle("NOTICE");
        setDialogText(`Please ensure you have selected the correct category  and added all the products`);

        setAlertDialogStatus(true);

}else  if (prop.category === "Toilet Cleaner" && rows.length !== targetsLength) {

        setShowLoader(false);
        setDialogTitle("NOTICE");
        setDialogText(`Please ensure you have selected the correct category  and added all the products`);
        setAlertDialogStatus(true);

}else if(prop.date === "0"){

    setShowLoader(false);
    setDialogTitle("NOTICE");
    setDialogText("Please set a date");
    setAlertDialogStatus(true);

}else {
    let productPrice = parseFloat(getFilteredPrice(prop.product, prop.retailer));
    let jik_bleach_regular_price = parseFloat(getFilteredPrice("Jik Bleach Regular 750ml", prop.retailer));
    let jik_bleach_springfresh_price = parseFloat(getFilteredPrice("Jik Bleach Spring Fresh 750ml", prop.retailer));
    let jik_bleach_lemon_price = parseFloat(getFilteredPrice("Jik Bleach Lemon 750ml", prop.retailer));

    const requestData = {
        modelId: prop.modelId,
        fmcgId: prop.fmcgid,
        date: prop.date,
        product: prop.product,
        category: prop.category,
        subcategory: prop.subcategory,
        retailerId:prop.retailer,
        size: prop.size,
        targetPrice: productPrice,
        effectivePrice: productPrice,
        currentPrice:productPrice,
        discount: prop.discount,
        period: prop.period,
        Jik_Bleach_Regular_750ml_price: jik_bleach_regular_price,
        Jik_Bleach_Spring_Fresh_750ml_price: jik_bleach_springfresh_price,
        Jik_Bleach_Lemon_750ml_price: jik_bleach_lemon_price,
        scenarioProducts: getOutputScenarioArray(),
        userId:prop.userid,
        requestType: "scenario"

    }

   // setShowChart(false);


        axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/bleachespredictions', requestData).then(function (response) {


            // alert(JSON.stringify(response.data));


            const data = response.data;

            //got current prices successfully
            if (data.statusCode === "200") {

                let previousQuantities = [];
                let predictedQuantities = [];
                let previousQuantitiesLabels = [];
                let previousMonthLabels = [];
                let previousMonthValues = [];
                let predictedMonthLabels = [];
                let predictedMonthValues = [];
                let predictedMonthValuesLabels = [];




                let inference_data_results = prop.category.toLowerCase().includes("toilet") ? data.toiletCleanersResponseItemsList[0] : data.bleachesResponseItemsList[0];


                let previous_quantities_results = data.previousQuantitiesList;


                const previous_month_results_original = data.actualCategoryQuantitiesList

                //order based on quantity
                const previous_month_results = previous_month_results_original.sort((a, b) => {

                    return (b.quantity - a.quantity);


                });
               // alert(previous_month_results.length);
                // alert(`${getYearFormatted(predictedDate)} ${previous_quantities_results[previous_quantities_results.length - 1].year}`)


                let actualMonthQuantity = 0;
                //check if previous quantities contain the current month
                if ((getYearFormatted(prop.date) === previous_quantities_results[previous_quantities_results.length - 1].year) && (getMonthFormatted(prop.date).toLowerCase() === previous_quantities_results[previous_quantities_results.length - 1].month.toLowerCase())) {

                    actualMonthQuantity = previous_quantities_results[previous_quantities_results.length - 1].qty;
                    //get predicted month year and current month year
                    // let actualMonthMonth = previous_quantities_results[previous_quantities_results.length - 1].month;
                    // let actualMonthYear = previous_quantities_results[previous_quantities_results.length - 1].year;

                    //remove extra month element
                    previous_quantities_results.pop();

                }


                previous_quantities_results.forEach(function (value) {

                    previousQuantities.push(value.qty);
                    predictedQuantities.push(value.qty);
                    previousQuantitiesLabels.push(`${value.month.substring(0, 3)} ${value.year}`);


                });


                let predictedValue;
                let predictionTotalSalesQty = 0;
                //filter to add prediction
                Object.keys(inference_data_results).forEach(key => {
                    // predictedMonthValues.push(parseFloat(inference_data_results[key]))

                    predictionTotalSalesQty += parseFloat(inference_data_results[key]);

                    //predictedMonthLabels.push(key.replace("Qty", ""));

                    predictedMonthValuesLabels.push({
                        label: key.replace("Qty", ""),
                        value: parseFloat(inference_data_results[key])
                    });

                    if (product.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").includes(key.replace("Qty", "").toLowerCase())) {

                        predictedValue = parseFloat(inference_data_results[key].toFixed(0));
                        console.log("found " + key);

                    }

                });

                let predictedMonthValuesLabelsSorted = predictedMonthValuesLabels.sort(function (a, b) {


                    return (b.value - a.value);
                });

                predictedMonthValuesLabelsSorted.forEach((value) => {

                    if(value.value !== 0) {
                        predictedMonthLabels.push(value.label);
                        predictedMonthValues.push(value.value);
                    }
                });


                let previousMonthQtyTotal = 0;

                previous_month_results.forEach(function (value) {
                    previousMonthValues.push(parseFloat(value.quantity))
                    previousMonthLabels.push(value.product)
                    previousMonthQtyTotal += parseFloat(value.quantity);


                });






                setPredictedMonthLabels(predictedMonthLabels);
                setPredictedMonthValues(predictedMonthValues);
                setPreviousMonthLabels(previousMonthLabels);
                setPreviousMonthValues(previousMonthValues);

               // alert(previousMonthLabels.length + " "+previousMonthValues.length + " "+ predictedMonthLabels.length +" "+predictedMonthValues.length);

                setShowLoader(false);
                // setShowChart(false);
                setShowChart(true);

            } else {
                setShowLoader(false);
                // alert(response.data);
                setDialogTitle("NOTICE");
                setDialogText(data.message);
                setAlertDialogStatus(true);
            }


        }).catch(function (error) {
            setShowLoader(false);
            console.log(error);
            setDialogTitle("NOTICE");
            setDialogText(error.toString());
            setAlertDialogStatus(true);
        });



}



    }

    const handleCloseAddScenarioDialog = (name)=>{

        setOpenSaveScenarioDialogStatus(false);

       // alert(name);
        if(name !== "close dialog") {
            saveScenarioDetails(name);
        }
    }

    const saveScenarioDetails = (name)=> {

        if (localStorage.getItem("scenarios") === null) {

            let scenarioNamesArray = [{scenario: name}];
            localStorage.setItem("scenarios", JSON.stringify(scenarioNamesArray));
            localStorage.setItem(name, JSON.stringify(rows));
            setRows([]);
            setMyScenarios(scenarioNamesArray);

        } else {
            let scenariosStringArray = localStorage.getItem("scenarios");

            let scenariosArray = JSON.parse(scenariosStringArray);
            let updatedArray = [...scenariosArray, {scenario: name}];
            localStorage.setItem("scenarios", JSON.stringify(updatedArray));
            localStorage.setItem(name, JSON.stringify(rows));
            setRows([]);
            setMyScenarios(updatedArray);

        }
    }

  const handleAddScenario = (event)=>{
            setLayoutVisibility("visible");
            setRows([]);


  }

  const handleDeleteRow = (event)=>{
       // alert('ok');
      try {
          //let productname = event.target.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.firstElementChild.innerText;

          let productname = event.target.parentElement.parentElement.previousElementSibling.previousElementSibling.previousElementSibling.previousElementSibling.firstElementChild.innerText;
        //  alert(event.target + " " + productname)


          let filtered_array = rows.filter(function (value, index, array) {


              return value.product.toLowerCase() !== productname.toLowerCase();
          });


          console.log(filtered_array)


          setRows(filtered_array);

      }catch (e) {
          console.log(e);
          alert(e);
      }

  };



    const handleScenarioNameClick = (event)=>{

        let scenarioname = event.target.id;

        setRows([]);
        setTimeout(() => {
            setRows(JSON.parse(localStorage.getItem(scenarioname)));
            setScenarioTitle(`${scenarioname} summary`);
            setLayoutVisibility("visible");
        }, 0);
       // setRows([]);

        console.log("scenario "+scenarioname +" selected");
        //alert(rows.length);

    }


    const handleCloseAlertDialog = (event)=>{

        setAlertDialogStatus(false);
    }




    const handleSliderEvent = (value)=>{

       // alert(value.type + " "+ value.value);

        if(value.type === "Price"){

            setPrice(value.value);


        }else if(value.type === "Promo"){

            setDiscount(value.value);


        }else if(value.type === "Period"){

            setPeriod(value.value);

        //instore marketing
        } else{
            setInstoreMarketing(value.value);
        }

    };

    const handleScenarioDropDowns = (value)=>{

         setProduct(value.placeholder);

        let productPrice = parseFloat(getFilteredPrice(value.placeholder, prop.retailer));

            setSelectedCurrentProductPrice(productPrice);

            setPrice(productPrice);

           // alert(productPrice);




    };
    const setMyScenariosArray = ()=>{
        if(myScenarios.length === 0){

            if(localStorage.getItem("scenarios") !== null){

                let scenariosArray = JSON.parse(localStorage.getItem("scenarios"));
                setMyScenarios(scenariosArray);


            }

        }else{

            if(localStorage.getItem("scenarios") !== null){
                let scenariosArray = JSON.parse(localStorage.getItem("scenarios"));
                setMyScenarios(scenariosArray);

            }


        }

    };

    function getOutputScenarioArray(){
        let outputarray = [];

        for (let i = 0; i <rows.length ; i++) {
            let rowItem = {
                productName: rows[i].product,
                period: rows[i].period,
                marketing: rows[i].inStoreMarketing,
                discount: rows[i].discount,
                price: rows[i].price
            };
            outputarray.push(rowItem)
        }

        return outputarray;
    }

    const handleAddAllProducts = ()=>{

        if(rows.length === 0){
            const newArray = [];
            //loop through all items;
            data.forEach((value, index, array)=>{

                let gotproduct = value.targetName;

                let price = parseFloat(getFilteredPrice(gotproduct,prop.retailer));

                let rowItem = {
                    id: index+1,
                    product: gotproduct,
                    period: period,
                    instoreMaketing: 0,
                    discount: 0,
                    price: price
                };

              newArray.push(rowItem);



            })

            setRows(newArray);
        }else{

            setDialogTitle("NOTICE");
            setDialogText("Please clear all products first");
            setAlertDialogStatus(true);
        }




    }

    const handleAddSpecificScenarioEvent = (value)=>{

        if(product === "") {
            setDialogTitle("NOTICE");
            setDialogText("Please select a product to add to scenario");
            setAlertDialogStatus(true);

        }else if(price === 0){


            setDialogTitle("NOTICE");
            setDialogText("Please set the product price for the selected product");
            setAlertDialogStatus(true);



        }else {

            if (rows.length === 0) {

                let rowItem = {
                    id: 1,
                    product: product,
                    period: period,
                    instoreMaketing: inStoreMarketing,
                    discount: discount,
                    price: price
                };

                const newArray = [rowItem]
                setRows(newArray);


            } else {

                //check if  product name already exists before adding
                let filtered_array = rows.filter(function (value, index, array) {


                    return value.product.toLowerCase() === product.toLowerCase();
                });

                if(filtered_array.length >  0){

                    setDialogTitle("NOTICE");
                    setDialogText(`${product}  already exists in scenarios`);
                    setAlertDialogStatus(true);

                }else {

                    let rowItem = {
                        id: rows.length + 1,
                        product: product,
                        period: period,
                        instoreMaketing: inStoreMarketing,
                        discount: discount,
                        price: price
                    };

                    const newArray = [...rows, rowItem]


                    setRows(newArray);

                    setDialogTitle("NOTICE");
                    setDialogText(`${product} added successfully`);
                    setAlertDialogStatus(true);

                }


            }

        }


        //setRows(rows);

    };

    useEffect(()=>{
        setMyScenariosArray();
    }, [])


    /**
     *  <tr>
     *                         <td><p>Product A</p></td>
     *                       <td><p>0</p></td>
     *                       <td><p>-25%</p></td>
     *
     *                         <td><p>0</p></td>
     *                         <td><p>0</p></td>
     *                     </tr>
     */


  // Dialog = <AddScenarioDialog dialogopenstatus = {openSaveScenarioDialogStatus} onCloseDialog = {handleCloseAddScenarioDialog}></AddScenarioDialog>

    if(showLoader){
        loader = <CircularProgress/>
    }else{
        loader = "";
    }
    let ChartLayout;
    if(showChart){

        ChartLayout  = <DoghnutChartWhatIfAnalaysisLayout predictedDate={formatDate(prop.date)}
                                                                          actualDate={formatDate(prop.previousDate)}
                                                                          actualValues={previousMonthValuesA}
                                                                          actualLabels={previousMonthLabelsA}
                                                                          predictedValues={predictedMonthValuesA}
                                                                          predictedLabels={predictedMonthLabelsA}
                                                                          fmcgid = {prop.fmcgid}
                                                                          size = {prop.size}
                                                                          retailer = {prop.retailer}
                                                                           category = {prop.category}></DoghnutChartWhatIfAnalaysisLayout>

    }else{
        ChartLayout = <p></p>;
    }

    return( <div className="scenariossection">
            <h2  className={styles.scenariotitle}>{prop.sectionname}</h2>
            <hr/>

            <div className={styles.scenariostitleslayout}>
                <p style={{visibility:"hidden"}}>product</p>

                <div className={styles.textImageButton} onClick={handleAddScenario} >
                    <AddCircleOutlineIcon fontSize="large"></AddCircleOutlineIcon>
                    <p>Add Scenario</p>
                </div>

                {myScenarios.map(row =>(
                    <Button variant="outlined" className={styles.scenarionametitles} id={row.scenario} onClick={handleScenarioNameClick}>{row.scenario}</Button>
                    ))}


            </div>
            <div className={styles.scenarioslayout} style={{visibility:`${layoutVisibility}`} }>

                <div className={styles.scenariossubLayout}>


                     <div className={styles.leverstitle}>
                     <div>
                     <DropDown labeltitle = "Product" labelid = "productscenarios" onhandleScenariosDropDown = {handleScenarioDropDowns}  dropDownData =  {data}/>

                     </div>
                         <div className={styles.scenariosleverlayout}>
                             <h4>Levers</h4>
                             <Sliders title = "Price" default = {selectedCurrentProductPrice + " Ksh"} rangeid = "slider_price" min = "0" max = "1000" sliderValue = {selectedCurrentProductPrice}  onhandlesliderevent = {handleSliderEvent} ></Sliders>
                             <Sliders title = "Promo" default = "0%" rangeid = "slider_promo" min = "0" max = "50" sliderValue = "0" onhandlesliderevent = {handleSliderEvent} ></Sliders>

                             <Sliders title = {instoreMaketing} default = "0 ksh" rangeid = "slider_instoremarketing" sliderValue = "0"  min = "0" max = "250000" onhandlesliderevent = {handleSliderEvent}></Sliders>

                             <div className={styles.dropdownbuttonlayout}>
                                 <EventButton  buttonname = "Add" buttonid = "save_specific_scenario_button" onHandleSaveScenarioButton = {handleAddSpecificScenarioEvent}  />
                                 <EventButton  buttonname = "Add All" buttonid = "save_all_products_button" onHandleSaveScenarioProductsButton = {handleAddAllProducts}  />
                             </div>

                         </div>
                     </div>







                </div>
                   <div className={styles.scenariossubLayout}>

                        <h3 className={styles.scenariotabletitle}>{scenarioTitle}</h3>

                    <table className={styles.scenariostable}>
                      <thead>
                        <tr>
                            <th className={styles.tablemaintext}><p className={styles.tabletitles}>Product</p></th>
                            <th className={styles.tablesubtext}><p  className={styles.tabletitles}>Price</p></th>
                            <th className={styles.tablesubtext}><p  className={styles.tabletitles}>Promo</p></th>
                            <th className={styles.tablesubtext}><p className={styles.tabletitles}>Marketing</p></th>
                        </tr>

                      </thead>
<tbody>
                        {rows.map(row =>(
                            <tr key={row.id}>
                            <td className={styles.tablemaintext}><p className={styles.tabletext}>{row.product}</p></td>
                            <td className={styles.tablesubtext}><p className={styles.tabletext}>Ksh {row.price}</p></td>
                            <td className={styles.tablesubtext}><p className={styles.tabletext}>{row.discount}%</p></td>


                            <td className={styles.tablesubtext}><p className={styles.tabletext}>Ksh {row.instoreMaketing}</p></td>
                                <td className={styles.tablesubtext}><Delete className={styles.deleteicon} onClick={handleDeleteRow}></Delete></td>
                            </tr>

                        ))}






</tbody>




                    </table>
                    <div className={styles.scenariossavebuttonsubLayout}>
                        <EventButton  buttonname = "Delete Scenario" onHandleDeleteScenario={handleDeleteScenarioEvent} buttonid = "deletescenariobutton"/>
                        <EventButton  buttonname = "Save Scenario" onHandleSaveScenario={handleSaveScenarioEvent} buttonid = "savescenariobutton"/>

                    </div>
                </div>


            </div>
            <hr style={{marginTop:"15px"}}/>
            <div className={styles.dropdownbuttonlayout}>
                <EventButton onClickRunScenario = {handleRunScenarioEvent}  buttonname = "Run Scenarios"  buttonid = "runscenariobutton"/>
                {loader}
            </div>

            {ChartLayout}

            <AddScenarioDialog dialogopenstatus={openSaveScenarioDialogStatus} onCloseDialog={handleCloseAddScenarioDialog} />
            <AlertDialog dialogStatus = {alertDialogStatus} dialogTitle = {dialogTitle} dialogText = {dialogText} onCloseAlertDialog = {handleCloseAlertDialog}/>

        </div>




    )


}

export default Scenarios;