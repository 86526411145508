import styles from './stylesheets/dropdowncontainer.module.css'
import {assignAlphabetNumber} from "../utils/Utilities";
import {useState} from "react";
import {NAIVAS_RETAILER_ID} from "../utils/Constants";
function DropDown(props){

    const dropDownData = props.dropDownData;
    const set = new Set();
    const itemsArrayOriginal = [];
    let  itemsArray = [];
    const barcodesArray = [];
    let isDropDownDisabled = false;
    let showPlaceHolder = false;


    const handleChange = (event)=>{


        //target drop downs
        if(props.labelid === "product"){

            if(event.target.selectedIndex !== 0) {
                const filter_array = dropDownData.filter((value) => {

                    return value.targetName === event.target.value;
                });

                let img_url = "none";
                if (filter_array[0].img_url !== undefined) {
                    img_url = filter_array[0].img_url;
                }

               // alert(event.target.selectedIndex - 1);
               // if(props.dropDownData[event.target.selectedIndex - 1].barcode !== undefined) {
                    //alert(props.dropDownData[event.target.selectedIndex -1].barcode);
               // }
                let response = {
                    type: "product",
                    value: event.target.value,
                    img_url: img_url,
                    showImage: showPlaceHolder,
                    text: event.target.options[event.target.selectedIndex -1].text,
                    barcode:props.dropDownData[event.target.selectedIndex -1].barcode,
                    selectedProductIndex: event.target.selectedIndex - 1
                }

                //alert(response.barcode);
                props.onhandleDropDownStateChange(response);

            }else{
                console.log("select product");
                alert("select product");
            }

        }else if(props.labelid === "size"){

            let response = {type:"size", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "subcategory"){

            let response = {type:"subcategory", value:event.target.value}
            props.onhandleDropDownStateChange(response);



        }else if(props.labelid === "brand"){

            let response = {type:"brand", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "segment"){

            let response = {type:"segment", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "retailer"){

            let response = {type:"retailer", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "price"){

            let response = {type:"price", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "discount"){

            let response = {type:"discount", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "periodlevers"){

            let response = {type:"periodlevers", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "instoremarketing"){

            let response = {type:"instoremarket", value:event.target.value}
            props.onhandleDropDownStateChange(response);

        }else if(props.labelid === "retailerdiscount"){

            let response = {type:"retailerdiscount", value:event.target.value}
            props.onhandleDropDownStateChange(response);
        }else if(props.labelid === "productscenarios"){
            let placeholdervalue = showPlaceHolder === true? event.target.options[event.target.selectedIndex].text : event.target.value;
            let response = {type:"productscenarios", value:event.target.value,placeholder:placeholdervalue }
            props.onhandleScenariosDropDown(response);


        }else if(props.labelid === "constraints"){
            let response = {type:"constraints", value:event.target.value}
            props.onhandleDropDownStateChange(response);
            

        }else if(props.labelid === "objective"){
            let response = {type:"objective", value:event.target.value}
            props.onhandleDropDownStateChange(response);


        }

    }

    dropDownData.forEach((value, index)=>{
        let optionValue;
        if (props.labelid === "product" ||props.labelid === "productscenarios" ) {
            optionValue = value.targetName;
        } else if (props.labelid === "size") {
            // Handle size logic here

                optionValue = value.size;


        } else if (props.labelid === "subcategory") {
            optionValue = value.category;
            // Handle category logic here
        } else if (props.labelid === "brand") {
            // Handle brand logic here
            optionValue = value.brand;

        } else if (props.labelid === "segment") {
            // Handle brand logic here
            optionValue = value.segment;

        } else if (props.labelid === "retailer") {
            // Handle retailer logic here
            optionValue = value.retailer;

        } else if (props.labelid === "constraints") {
            // Handle retailer logic here
            optionValue = value.constraints;


        } else if (props.labelid === "objective") {
            // Handle retailer logic here
            optionValue = value.objective;
        }
        if(!set.has(optionValue)){
            set.add(optionValue);
            itemsArrayOriginal.push(optionValue)
            console.log(optionValue + " "+index + " saved");
        }


    });

    if (props.labelid === "price") {
        //isDropDownDisabled = true;
    }

    if(props.labelid === "subcategory"){

        //itemsArray = itemsArrayOriginal.reverse();
       itemsArray = itemsArrayOriginal;
    }else{
       itemsArray= itemsArrayOriginal;
    }


    return (
        <div className={styles.dropwncontainer}>
            <label className={styles.dropdownlabel} htmlFor={props.labelid}>{props.labeltitle}</label>
            <select className={styles.dropdown} id={props.labelid} onChange={handleChange} disabled={isDropDownDisabled}>
                {itemsArray.length > 0 ? (

                    itemsArray.map((value, index) => {
                        let optionValue;
                        let  selected = index === 0;
                        if (props.labelid === "product" || props.labelid === "productscenarios") {
                            //optionValue = value.targetName;
                            optionValue = value;
                        } else if (props.labelid === "size") {
                            // Handle size logic here
                           // optionValue = value.size;
                            optionValue = value;

                        } else if (props.labelid === "segment") {
                            // Handle size logic here
                            // optionValue = value.size;
                            optionValue = value;

                        } else if (props.labelid === "subcategory") {
                            //optionValue = value.category;
                            optionValue = value;
                            // Handle category logic here
                        } else if (props.labelid === "brand") {
                            // Handle brand logic here
                            //optionValue = value.brand;
                            optionValue = value;
                        } else if (props.labelid === "retailer") {
                            // Handle retailer logic here
                            //optionValue = value.retailer;
                            optionValue = value;

                        } else if (props.labelid === "constraints") {
                            // Handle retailer logic here
                            //optionValue = value.retailer;
                            optionValue = value;

                        } else if (props.labelid === "objective") {
                            // Handle retailer logic here
                            //optionValue = value.retailer;
                            optionValue = value;
                        }

                        if(index === 0) {
                            if (props.labelid === "product" || props.labelid === "productscenarios") {
                                return (
                                    <>
                                    <option key={-1} value="select" disabled={false} >select</option>
                                <option key={index} value={optionValue}>{showPlaceHolder ? `Product ${assignAlphabetNumber(index)}`:optionValue}</option>
                                        </>


                                )
                            }else {
                                if(props.labelid === "brand") {


                                    return (
                                        <option key={index}
                                                value={optionValue}>{showPlaceHolder? `Brand ${assignAlphabetNumber(index)}`:optionValue}</option>
                                    )

                                }else if(props.labelid === "segment"){
                                    return  (
                                        <option key={index} value={optionValue}>{showPlaceHolder?`Segment ${assignAlphabetNumber(index)}`:optionValue}</option>
                                    )

                                }else if(props.labelid === "subcategory"){
                                 return  (
                                     <option key={index} value={optionValue}>{showPlaceHolder?`Category ${assignAlphabetNumber(index)}`:optionValue}</option>
                                 )
                                }else{
                                    return (
                                        <option key={index} value={optionValue}>{optionValue}</option>
                                    )
                                }
                            }
                        }else{
                            if (props.labelid === "product" || props.labelid === "productscenarios") {
                                return (
                                    <option key={index}
                                            value={optionValue}>{showPlaceHolder?`Product ${assignAlphabetNumber(index)}`:optionValue}</option>
                                )

                            }else if(props.labelid === "brand"){
                                return (
                                    <option key={index}
                                            value={optionValue}>{showPlaceHolder?`Brand ${assignAlphabetNumber(index)}`:optionValue}</option>
                                )

                            }else if(props.labelid === "segment"){
                                return (
                                    <option key={index}
                                            value={optionValue}>{showPlaceHolder?`Segment ${assignAlphabetNumber(index)}`:optionValue}</option>
                                )


                            }else if(props.labelid === "subcategory"){
                                return  (
                                    <option key={index} value={optionValue}>{showPlaceHolder?`Category ${assignAlphabetNumber(index)}`:optionValue}</option>
                                )
                            }else{
                                return (
                                    <option key={index} value={optionValue}>{optionValue}</option>
                                )
                            }
                        }

                    })

                ) : (

                    <>
                        {props.labelid === "discount" || props.labelid === "retailerdiscount" ? (
                            // Render discount specific options
                           <>
                               <option  value="0">0%</option>
                            <option  value="5">5%</option>
                            <option value="10">10%</option>
                               <option value="15">15%</option>
                               <option value="20">20%</option>
                               <option value="25">25%</option>
                               <option value="30">30%</option>
                               <option value="35">35%</option>
                               <option value="40">40%</option>
                               <option value="45">45%</option>
                               <option value="50">50%</option>
                               </>
                        ) : (props.labelid === "instoremarketing" ? (
                            // Render default options
                            <>
                                <option  value="1">0 - 50000 Ksh</option>
                                <option value="2">50000 - 100000 Ksh</option>
                                <option value="3">100000 - 150000 Ksh</option>
                                <option value="4">150000 - 200000 Ksh</option>
                            </>

                                ):(props.labelid === "periodlevers" ?(
                                <>
                                    <option value="1">1 week</option>
                                    <option value="2">2 weeks</option>
                                    <option value="3">3 weeks</option>
                                    <option value="4">4 weeks</option>

                                </>

                            ):(
                            <>
                                <option value="select">select</option>
                                <option value="test" disabled={true}>test</option>
                            </>

                            )
                            )
                        )}
                    </>
                )}
            </select>
        </div>
    );

}

export default DropDown