
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import NoPage from "./pages/nopage";
import Privacypolicy from "./pages/privacypolicy";
import Termsofservice from "./pages/termsofservice";
import Home from "./pages/home";
import LandingPage from "./components/LandingPage";
import Emailverification from "./pages/emailverification";
const root = ReactDOM.createRoot(document.getElementById('root'));
export default function PageHandler () {

    return (<BrowserRouter>

        <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route index element={<Home/>}></Route>
            <Route path="termsofservice" element={<Termsofservice/>}></Route>
            <Route path="privacypolicy" element={<Privacypolicy/>}></Route>
            <Route path="emailverification/:id" element={<Emailverification/>}></Route>
            <Route path="*" element={<NoPage/>}></Route>

        </Routes>

    </BrowserRouter>);
}
root.render(
  <React.StrictMode>
    <PageHandler />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
