import {
    BLEACHES_1_5L_FILTER,
    BLEACHES_1_L,
    BLEACHES_1L_FILTER_,
    BLEACHES_2_25L_FILTER,
    BLEACHES_2_L,
    BLEACHES_2L_FILTER,
    BLEACHES_750_ML_FILTER,
    BLEACHES_750ML,
    BLEACHES_BRANDS_STACKBAR,
    BLEACHES_BRANDS_STACKBAR_CARREFOUR,
    BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L,
    BLEACHES_BRANDS_STACKBAR_NAIVAS,
    BLEACHES_BRANDS_STACKBAR_NAIVAS_1L,
    BLEACHES_BRANDS_STACKBAR_NAIVAS_2L,
    ML_TARGETS_STORAGE_KEY, NAIVAS_RETAILER_ID,
    TOILET_CLEANER_1L_FILTER,
    TOILET_CLEANER_1L_FILTER_2,
    TOILET_CLEANER_500_ML_FILTER,
    TOILETCLEANERS_1L,
    TOILETCLEANERS_500ML,
    TOILETCLEANERS_BRANDS_STACKBAR,
    TOILETCLEANERS_BRANDS_STACKBAR_1L, TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR,
    TOILETCLEANERS_BRANDS_VALUES_STACKBAR
} from "./Constants";

const calculateEffectivePrice = (currentPrice, discountRate, discountDuration)=>{
    console.log(`current price ${currentPrice} discountRate ${discountRate} discountDuration ${discountDuration}`)

    let effectivePrice = 0;
    let unitProductPrice = 0;
    for(let i = 0; i < discountDuration; i++){

       console.log(`Count ${i}`)

            unitProductPrice += (currentPrice * (1 - (discountRate / 100)))
            console.log(`unit Product Price ${unitProductPrice}`)

    }
  if(parseInt(discountDuration) === 1){

     effectivePrice = ((unitProductPrice + currentPrice)/2);
      console.log(`effective price 1 ${effectivePrice}`)
  } else{
      effectivePrice = (unitProductPrice /discountDuration);

      console.log(`effective price ${effectivePrice}`)

  }

   return parseFloat(effectivePrice.toFixed(0));
}

function calculateSalesROi(predictedQty, predictedPrice, previousQty, previousPrice) {
    // Test variables
    console.log("predictedQty: " + predictedQty);
    console.log("predictedPrice: " + predictedPrice);
    console.log("previousQty: " + previousQty);
    console.log("previousPrice: " + previousPrice);

    let roi = 0.0;

    if (predictedQty > 0 && predictedPrice > 0 && previousQty > 0 && previousPrice > 0) {
        let salesGrowth = (predictedQty * predictedPrice) - (previousQty * previousPrice);
        console.log("salesGrowth: " + salesGrowth);

        let investment = -(predictedQty * (predictedPrice - previousPrice));
        console.log("investment: " + investment);

        let returns = salesGrowth - investment;
        //roi = (returns - investment) / investment;
        roi = returns / investment;
        console.log("roi: " + roi);
    }

    return (roi *100).toFixed(2);

}

function calculateSalesROiOptimize(predictedQty, predictedPrice, previousQty,currentPrice, prev_3_months_sales_value) {

    let roi = 0.0;

    if (predictedQty > 0 && predictedPrice > 0 && previousQty > 0 && prev_3_months_sales_value > 0) {
        if (currentPrice === predictedPrice) {
     roi = 0;
        } else {
            let salesGrowth = ((predictedQty * predictedPrice) - prev_3_months_sales_value);
            console.log("salesGrowth: " + salesGrowth);


            let investment = ((currentPrice - predictedPrice) * predictedQty);
            console.log("investment: " + investment);

            let returns = ((salesGrowth - investment));
            //roi = (returns - investment) / investment;
            console.log("returns: " + returns);
            roi = returns / investment;
            console.log("roi: " + roi);

        }
    }

    return (roi *100).toFixed(2);

}

function calculateSalesROiUpdated(predictedQty, predictedPrice, previousQty,currentPrice, prev_3_months_sales_value) {
    // Test variables
    console.log("predictedQty: " + predictedQty);
    console.log("predictedPrice: " + predictedPrice);
    console.log("previousQty: " + previousQty);
    console.log("prev 3 month sales value: " + prev_3_months_sales_value);
    console.log("current price: "+ currentPrice)
  //  console.log("previousPrice: " + previousPrice);

    let roi = 0;
    let investment = 0;

    if (predictedQty > 0 && predictedPrice > 0  && prev_3_months_sales_value > 0) {
        let salesGrowth = ((predictedQty * predictedPrice) - prev_3_months_sales_value);
        console.log("salesGrowth: " + salesGrowth);

        if(currentPrice === predictedPrice){
            if(salesGrowth > 0){

                return  {ROI:"+100", Investment:0};

            }else {

                return  {ROI:"-100", Investment:0};

            }

        }else {
            investment = ((currentPrice - predictedPrice) * predictedQty);
            console.log("investment: " + investment);

            let returns = ((salesGrowth - investment));
            //roi = (returns - investment) / investment;
            console.log("returns: " + returns);
            roi = returns / investment;
            console.log("roi: " + roi);
        }
    }

    return {ROI:parseFloat((roi *100).toFixed(2)), Investment:investment};

}

function calculateUplift(predictedQty, previousQty)
{
    let uplift = 0.0;
    uplift = (((predictedQty - previousQty) / previousQty)*100).toFixed(2);

    return uplift;
}

function calculateValueUplift(predictedSalesValue, prev3MonthsSalesValue)
{
    let valueUplift = 0.0;
    valueUplift = (((predictedSalesValue - prev3MonthsSalesValue) / prev3MonthsSalesValue)*100).toFixed(2);

    return valueUplift;
}

function getNextMonth(previousMonthString) {
    //'January 2022'
    // Create a Date object from the previous month string
    const previousDate = new Date(previousMonthString);

    // Increment the month by 1
    previousDate.setMonth(previousDate.getMonth() + 1);

    // Get the next month and year as strings
    const nextMonth = previousDate.toLocaleString('default', { month: 'short' });
    const nextYear = previousDate.getFullYear();

    // Concatenate the month and year strings
    const nextMonthString = `${nextMonth.substring(0,3)} ${nextYear}`;

    return nextMonthString;
}

function getPreviousMonth(dateString) {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add one month to the date
    date.setMonth(date.getMonth() - 1);

    // Construct the new date string in the format YYYY-MM-DD
    const previousMonth = date.toISOString().slice(0, 10);

    return previousMonth;
}

//format date to return in the format of August 2016
function formatDate(dateString){
    const date = new Date(dateString);

    const Month = date.toLocaleString('default', { month: 'short' });
    const Year = date.getFullYear();

    // Concatenate the month and year strings
    const formattedDate = `${Month.substring(0,3)} ${Year}`;

    return formattedDate

}

function getYearFormatted(dateString){
    const date = new Date(dateString);
   return  date.getFullYear().toString();

}

function getMonthFormatted(dateString){

    const date = new Date(dateString);

    const shortMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return shortMonths[date.getMonth()];

}

function formatDateReport(date) {
    // Pad a number with leading zero if necessary
    function pad(number) {
        return number < 10 ? '0' + number : number;
    }

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${day}${month}${year}_${hours}${minutes}${seconds}`;
}

function setNaivasArray(size){

    if(size === BLEACHES_750ML){

        return BLEACHES_BRANDS_STACKBAR_NAIVAS;

    }else if (size === BLEACHES_1_L){

        return  BLEACHES_BRANDS_STACKBAR_NAIVAS_1L;

    }else if(size === BLEACHES_2_L){

        return BLEACHES_BRANDS_STACKBAR_NAIVAS_2L;

    }

}
function setCarrefourArray(size){

    if(size === BLEACHES_750ML){

        return BLEACHES_BRANDS_STACKBAR_CARREFOUR;

    }else if (size === BLEACHES_1_L){

        return  BLEACHES_BRANDS_STACKBAR_CARREFOUR_1L;

    }else if(size === BLEACHES_2_L){

        return BLEACHES_BRANDS_STACKBAR_NAIVAS_2L;

    }

}

function setToiletCleanersArray(size,retailerId){

    if(size === TOILETCLEANERS_500ML){

        return TOILETCLEANERS_BRANDS_STACKBAR;

    }else if (size === TOILETCLEANERS_1L) {
if(parseInt(retailerId) === NAIVAS_RETAILER_ID) {
    return TOILETCLEANERS_BRANDS_STACKBAR_1L;
}else{
    return TOILETCLEANERS_BRANDS_STACKBAR_1L_CARREFOUR;
}

    }

}

function setToiiletCleanersArrayValues(size){

    if(size === TOILETCLEANERS_500ML){

        return TOILETCLEANERS_BRANDS_VALUES_STACKBAR;

    }else if (size === TOILETCLEANERS_1L) {

        return TOILETCLEANERS_BRANDS_STACKBAR_1L;

    }

}
const DoghnutChartColors = [ 'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(10, 181, 65, 1)',
    'rgba(101, 14, 21, 15)',
    'rgba(83, 103, 210, 150)',
    'rgba(33, 103, 210, 150)',
    'rgba(78, 149, 210, 154)',
    'rgba(123, 33, 201, 1)',
    'rgba(243, 102, 34, 1)',
    'rgba(243, 167, 31, 14)',
    'rgba(120, 39, 107, 1)',
    'rgba(180, 201, 29, 11)',
    'rgba(120, 102, 34, 1)']


function assignAlphabetNumber(number) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    switch (number) {
        case 0:
            return alphabet[0];
        case 1:
            return alphabet[1];
        case 2:
            return alphabet[2];
        case 3:
            return alphabet[3];
        case 4:
            return alphabet[4];
        case 5:
            return alphabet[5];
        case 6:
            return alphabet[6];
        case 7:
            return alphabet[7];
        case 8:
            return alphabet[8];
        case 9:
            return alphabet[9];
        case 10:
            return alphabet[10];
        case 11:
            return alphabet[11];
        case 12:
            return alphabet[12];
        case 13:
            return alphabet[13];
        case 14:
            return alphabet[14];
        case 15:
            return alphabet[15];
        case 16:
            return alphabet[16];
        case 17:
            return alphabet[17];
        case 18:
            return alphabet[18];
        case 19:
            return alphabet[19];
        case 20:
            return alphabet[20];
        case 21:
            return alphabet[21];
        case 22:
            return alphabet[22];
        case 23:
            return alphabet[23];
        case 24:
            return alphabet[24];
        case 25:
            return alphabet[25];
        default:
            return null; // Return null for numbers outside the range of 0-25
    }
}


function allocateBudget(array1, array2, budget) {
    let maxVolume = 0;
    let bestCombination = [];

    // Try all combinations of one retailer from each group
    for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {

                let selectedRetailers = [array1[i], array2[j]];
                let totalInvestment = selectedRetailers.reduce((sum, retailer) => sum + retailer.investment, 0);
                let totalVolume = selectedRetailers.reduce((sum, retailer) => sum + retailer.volume, 0);

                if (totalInvestment <= budget && totalVolume > maxVolume) {
                    maxVolume = totalVolume;
                    bestCombination = selectedRetailers;

            }
        }
    }

    return {
        totalInvestment: bestCombination.reduce((sum, retailer) => sum + retailer.Investment, 0),
        totalVolume: maxVolume,
        selectedRetailers: bestCombination
    };
}

function getPredictedResults(products, key){
    let isFound = false;
    let productName;

    products.forEach((value)=>{
        //alert( value + " "+key);
        if (value.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/", "").includes(key.replace("Qty", "").replace("750ml", "").replace("700ml", "").replace("500ml", "").toLowerCase())) {

            productName = value;
            isFound = true;
            //alert("found");


        }


        });

    return {foundStatus:isFound, product:productName};


}

function getPredictedQty(product,predictedResults){

    let salesAmount = 0;
    Object.keys(predictedResults).forEach(key => {
        if (product.toLowerCase().replaceAll(" ", "").replaceAll("+", "plus").replaceAll("/", "").replace("mpine", "mountainpine").replace("tcpowerpluslemon", "toiletcleanerpplem").includes(key.replace("Qty", "").replace("750ml", "").replace("700ml", "").replace("500ml", "").toLowerCase())) {
//alert("found");
          salesAmount = parseFloat(predictedResults[key].toFixed(0));


        }

    });



    return salesAmount;


}

function getPrevious3MonthValues(product, values){

    let value = 0;
    values.forEach((item)=> {

        if (item.product.includes(product)) {

            value = parseFloat(item.last_3_months_avg_sales_values.toFixed(0));
            // alert(value)
        }



    });

    return value;

}

function calculateValuePackQuantity(productName, quantity){

    let calulatedQuantity = quantity;
    if(productName.includes("3x") || productName.includes("2+1")){

        calulatedQuantity = quantity /3;

    }else if(productName.includes("2x") || productName.includes("Value Pack") || productName.includes("+")){

        calulatedQuantity = quantity /2;
    }




    return parseFloat(calulatedQuantity.toFixed(0));


}

function getValuePackQuantity(productName){


    if(productName.includes("3x") || productName.includes("2+1")){

        return 3;

    }else if(productName.includes("2x") || productName.includes("Value Pack") || productName.includes("+")){

        return 2;
    }







}

function getSinglePackNameFromValuePack(product) {

    if(product === "Harpic Power Plus Original 2x500ml"){

        return  "Harpic Power Plus Original 500ml"

    }else if(product === "Harpic Power Plus Citrus 2x500ml") {

        return "Harpic Power Plus Citrus 500ml";
    }else if(product === "Jik Bleach Regular 2+1 Promo Pack 3x750ml") {
        return "Jik Bleach Regular 750ml";

    }else if(product === "Harpic Power Plus Original Twin Pack 2x1L") {

        return  "Harpic Power Plus Original 1L";
    }else if(product === "Harpic Power Plus Citrus Twin Pack 2x1L"){

        return  "Harpic Power Plus Citrus 1L"

    }else if(product === "Harpic Power Plus Original 2x1L") {

        return  "Harpic Power Plus Original 1L";

    }else if(product === "Harpic Power Plus Citrus 2x1L"){

        return  "Harpic Power Plus Citrus 1L"

    }else{

        return product;
    }




}

function isValuePack(productName){

    let isValuePack = false;
    if(productName.includes("3x") || productName.includes("2+1")){

        isValuePack = true;

    }else if(productName.includes("2x") || productName.includes("Value Pack") || productName.includes("+")){

        isValuePack = true;
    }




    return isValuePack;


}


function replaceLastOccurrence(str, wordToReplace, replacementWord) {
    const lastIndex = str.lastIndexOf(wordToReplace);

    if (lastIndex === -1) {
        // The word was not found in the string
        return str;
    }

    const before = str.substring(0, lastIndex);
    const after = str.substring(lastIndex + wordToReplace.length);

    return before + replacementWord + after;
}

function getPreviousMonthValues(product, values){

    let value = 0;
    values.forEach((item)=>{

            if (item.product === product) {

                value = item.quantity;
            }


    });

    return value;

}

const filterProductPrice = (priceList,productName, gotRetailer,gotCategory, barcode = "none") => {

    const filterResults = priceList.filter((value, index, array) => {


        return (value.product === productName) && (value.retailer_id === gotRetailer);
    });

    if(filterResults.length === 0){
        if(barcode !== "none") {

            const filterResults = priceList.filter((value, index, array) => {


                return (value.barcode === barcode) && (value.retailer_id === gotRetailer);
            });
            if (filterResults.length === 0) {
                if(gotCategory.toLowerCase().includes("toilet")) {
                    if (productName.includes("Harpic")) {
                        return 600;
                    } else {
                        return 0;
                    }
                }else{
                    return 0;
                }
            } else {
                return filterResults[0].price;
            }

        }else{
            //alert("0");
            if(gotCategory.toLowerCase().includes("toilet")) {
                if (productName.includes("Harpic")) {
                    return 600;
                } else {
                    return 0;
                }
            }else {
                return 0;
            }
        }

    }else {
        return filterResults[0].price;
    }
};

function getScenariosTargets(retailerId, size, modelId){
//alert(retailerId + " "+size + " "+modelId);
    let responseArray = [];
    let targetsArray = JSON.parse(localStorage.getItem(ML_TARGETS_STORAGE_KEY)).filter((value)=>{


        return ( value.size === size && value.modelId === modelId)
    });

    targetsArray.forEach((value)=>{

       responseArray.push({"targetName": value.targetName});

    });


return responseArray;


}

function checkProductNameSizeMatch(product, size, category){

    if(category.toLowerCase().includes("bleach")){

        if(size === BLEACHES_750ML){

            if(product.includes(BLEACHES_750_ML_FILTER)){
                return  true;
            }else{
                return false;
            }

        }else if(size === BLEACHES_1_L){

            if(product.includes(BLEACHES_1L_FILTER_) || product.includes(BLEACHES_1_5L_FILTER)){
                return  true;
            }else{
                return  false;
            }

        }else if(size === BLEACHES_2_L){
            if(product.includes(BLEACHES_2L_FILTER) || product.includes(BLEACHES_2_25L_FILTER)){
                return  true;
            }else{
                return  false;
            }
        }

    }else if(category.toLowerCase().includes("toilet")){

        if(size === TOILETCLEANERS_500ML){
            if(product.includes(TOILET_CLEANER_500_ML_FILTER)){
                return  true;
            }else{
                return  false;
            }

        }else if(size === TOILETCLEANERS_1L){
            if(product.includes(TOILET_CLEANER_1L_FILTER) || product.includes(TOILET_CLEANER_1L_FILTER_2)){
                return  true;
            }else{
                return  false;
            }

        }
    }else{

        return true;
    }




}



export {checkProductNameSizeMatch,getScenariosTargets,getSinglePackNameFromValuePack,setToiiletCleanersArrayValues,setToiletCleanersArray,setCarrefourArray,setNaivasArray,getValuePackQuantity,isValuePack,calculateValuePackQuantity,getPreviousMonthValues,getPrevious3MonthValues,getPredictedQty, filterProductPrice,getPredictedResults, formatDateReport, assignAlphabetNumber,calculateSalesROiOptimize, calculateEffectivePrice,calculateSalesROi,calculateSalesROiUpdated, getNextMonth, getPreviousMonth, formatDate, DoghnutChartColors, getMonthFormatted, getYearFormatted, calculateUplift,calculateValueUplift, allocateBudget}