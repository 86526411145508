import { Chart as ChartJS, ArcElement, Title,Tooltip, Legend } from "chart.js"
import ChartDataLabels from 'chartjs-plugin-datalabels'
import DoghnutLabels from 'chartjs-plugin-doughnutlabel-v3'
import {DoghnutChartColors} from "../utils/Utilities";
import{useEffect, useState} from 'react'
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import styles from './stylesheets/charts.module.css'
import {CircularProgress} from "@mui/material";

ChartJS.register(ArcElement, ChartDataLabels,DoghnutLabels,Tooltip, Title, Legend);

const MlRequest = {modelId:0, fmcgId:0, date:"2023-08-01", Jik_Bleach_Regular_750ml_price:0, Jik_Bleach_Spring_Fresh_750ml_price:0, Jik_Bleach_Lemon_750ml_price:0  };


/**

let colorsArray = [];



function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to generate a random color in RGBA format
function getRandomColor() {
    const red = getRandomInt(0, 255);
    const green = getRandomInt(0, 255);
    const blue = getRandomInt(0, 255);
    return `rgba(${red}, ${green}, ${blue}, 1)`;
}
**/



 function DoghnutChart(props) {

    const [isLoading,setLoading] = useState(true);
    const [reponseData, setData] = useState();

     let titlesArray = [];
     let dataArray = [];
     let data;
     let options;
     useEffect(()=>{
         axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlpredictions', MlRequest).then(function (response) {
             console.log(response);
             setLoading(false);
             setData(response);



         }).catch(function (error) {
             console.log(error);
         });

     }, [])

 if(isLoading){
     return <CircularProgress></CircularProgress>;

 }else {


     let list = reponseData.data.mlBleachesResultsList;


     list.forEach(function (value, index, arr) {

         if ((value.predDate === props.period) && (value.actualPred === props.datatype)) {
             console.log("found date "+ value.predDate);
             //titles
             let keys = Object.keys(value);

             console.log(keys);

             titlesArray.push(keys[2]);
             titlesArray.push(keys[3]);
             titlesArray.push(keys[4]);
             titlesArray.push(keys[5]);
             titlesArray.push(keys[6]);
             titlesArray.push(keys[7]);
             titlesArray.push(keys[8]);
             titlesArray.push(keys[9]);
             titlesArray.push(keys[10]);
             console.log(keys[3]);

             //values
             dataArray.push(parseFloat(value.jikBleachLemon750mlQty));
             dataArray.push(parseFloat(value.jikBleachRegular750mlQty));
             dataArray.push(parseFloat(value.jikBleachSpringFresh750mlQty));
             dataArray.push(parseFloat(value.omoBleachLemonFresh700mlQty));
             dataArray.push(parseFloat(value.omoBleachRegular700mlQty));
             dataArray.push(parseFloat(value.safishaBleachLemon750mlQty));
             dataArray.push(parseFloat(value.safishaBleachRegular750mlQty));
             dataArray.push(parseFloat(value.topexBleachLemon750mlQty));
             dataArray.push(parseFloat(value.topexBleachRegular750mlQty));

             let data_1 = {
                 labels:titlesArray, // Dummy data
                 datasets:[{

                     label: '#Qty',
                     data: dataArray,

                     backgroundColor: DoghnutChartColors,
                     borderColor:DoghnutChartColors,

                 }],
             }
               let  data_2 = {
                 labels: titlesArray,
                 datasets: [
                     {
                         label: '#Qty',
                         data: dataArray,

                         backgroundColor: DoghnutChartColors,
                         borderColor: DoghnutChartColors,
                         borderWidth: 1,
                     },
                 ],

             };

             let show  = props.showlegend === "true"?true:false
             data = show === true?data_1:data_2;

             let position = show === true?'left':'bottom'

             const totalVol = dataArray.reduce((accumulator, currentValue)=> accumulator +currentValue, 0);


             options = {
                 cutoutPercentage: 50, // Adjust the size of the hole in the middle of the doughnut

                 responsive: true,


                 plugins: {

                     title: {
                         display: false,
                         fullSize: true,
                         text: "Total Vol",
                         padding: {
                             top: 10,
                             bottom: 10
                         }
                     },
                     subtitle: {
                         display: false,
                         fullSize: true,
                         text: "0",
                         padding: {
                             bottom: 5
                         }
                     },
                     tooltip:{
                         enabled:true,
                         callbacks:{
                         label:function (tooltipItem, data) {

                             let label = tooltipItem.dataset.label || '';


                             return `${label}:${tooltipItem.raw}   ${((parseFloat(tooltipItem.raw)/totalVol)*100).toFixed(2)}%`;
                             
                         },




                         },


                     },

                     legend: {
                         display: show,
                         position: position,


                         labels: {
                             padding: 15,
                         }
                     },
                     doughnutLabel: {
                         labels: [
                             {
                                 text: "Total Vol",
                                 color: "black",
                                 font: {
                                     size: "20",
                                     family: "Arial, Helvetica, sans-serif",
                                     style: "italic",
                                     weight: "bold"
                                 }
                             },
                             {
                                 text: totalVol.toFixed(0),
                                 font: {
                                     size: "19",
                                     weight: "bold"
                                 },
                                 color: "grey"
                             },
                             ]},
                     datalabels: {
                         display: true,
                         align: 'bottom',
                         backgroundColor: '',
                         borderRadius: 3,
                         font: {
                             size: 14,
                         },
                         color:"white",
                         formatter: function(value, context) {
                             let calculatedValue = ((value/totalVol)*100);
                             if(calculatedValue >= 5){
                             return `${calculatedValue.toFixed(1)}%`;
                             }else{
                                 return null;
                             }
                         },


                     },



                 }


             };


         }

         // long id;
         //String predDate;

         //actualPred;


     });

     return <Doughnut className={styles.doghnutchart} data={data} options={options}/>;

 }








   }
export default DoghnutChart
