import styles from './stylesheets/contactus.module.css'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Alert} from "@mui/material";
import {useRef, useState} from "react";
import axios from "axios";

function ContactUs() {
    const [showAlert, setShowAlert] = useState(false);
    const alertType = useRef({});

    let name = "", email  = "", message = "", company = "", subject = "", alert;
    const handleChange = (event)=>{
        switch (event.target.id){

            case "name":
                name  =event.target.value;
                break;

            case "email":
                email  =event.target.value;
                break;
            case "message":
                message  =event.target.value;
                break;
            case "company":
                company  =event.target.value;
                break;
            case "subject":
                subject  =event.target.value;
                break;

        }

    }

    const handleSendMessage = (event)=>{

       if( name === ""|| email === "" || message === "" || company === "" || subject === ""){
           alertType.current = {type:"error", message:"please input all the fields"};
           setShowAlert(true)
       }else{

           const contactusObject = {recipientName:name,company:company, emailAddress:email, subject:subject, body:message}
           document.getElementById("name").value = "";
           document.getElementById("email").value = "";
           document.getElementById("subject").value = "";
           document.getElementById("company").value = "";
           document.getElementById("message").value = "";

           axios.post('https://8pngvf5dpc.execute-api.eu-west-2.amazonaws.com/beta/mlcontactus', contactusObject).then((response)=>{
               console.log(response);
               alertType.current = {type:"success", message:"Thank you for contacting us! We will email you within 24 hours"};

               setShowAlert(true)
           }).catch((error)=>{
              console.log(error);
               alertType.current = {type:"error", message:error.toString()};
               setShowAlert(true)
           });


       }

    }

    if(!showAlert){


        alert = <></>
    }else{
        alert = <Alert variant="filled" className={styles.alertbox} severity={alertType.current.type}>
            {alertType.current.message}
        </Alert>
    }

    return <div className={styles.contactuslayout}>
        <div className={styles.contactusleft}>
        <h1 className={styles.contactustitle}>Talk to Us</h1>
        <p className={styles.contactussubtitle}>Reach out for any inquiries or feedback and we'll respond in less than 24 hours.</p>
        <div className={styles.contacttypelayout}>
            <PhoneIcon/>
            <a href="tel:+254743926362">+254 743 926 362 </a>
        </div>
            <div className={styles.contacttypelayout}>
                <EmailIcon/>
                <a href="mailto:customercare@tabiri.ai">customercare@tabiri.ai </a>
            </div>

            <div className={styles.contacttypelayout}>
                <LocationOnIcon/>
                <a href="https://goo.gl/maps/uLMACJGqKg4wcGQx9"> 4 th floor, Delta Towers, Westlands, Nairobi</a>
            </div>

            <div className={styles.contacttypelayout}>
                <WhatsAppIcon/>
                <a href="https://wa.me/+254743926362">+254 743 926 362</a>
            </div>
        </div>
        <div className={styles.contactusright}>
            {alert}
            <input type="text" onChange={handleChange} className={styles.textboxes} placeholder="name" id="name" maxLength="50"/>
            <input type="text" onChange={handleChange} className={styles.textboxes} placeholder="company" id="company" maxLength="50"/>
            <input type="email" onChange={handleChange} className={styles.textboxes} placeholder="email address" id="email" maxLength="100"/>
            <input type="text" onChange={handleChange} className={styles.textboxes} placeholder="subject" id="subject" maxLength="100"/>
            <textarea className={styles.textboxes} onChange={handleChange} rows="6" id="message"></textarea>
            <input type="Button" className="default_button_landingpage" onClick={handleSendMessage} value="send message"/>


        </div>

    </div>

}

export default ContactUs;