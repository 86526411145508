import logo from './images/tabirilogo.png'
import styles from './stylesheets/header.module.css'
function Header(props) {
    let altName = "Machine Learning";
    let clientName =  "Live Demo";//"Reckitt Beckniser";

    return (<header>
           <img className={styles.headerlogo} src={logo} alt={altName}/>


        <div className={styles.namelayout}>
         <span className={styles.nameinitials}>{clientName.at(0).toUpperCase()}</span>
         <p>{clientName}</p>
        </div>
        </header>
    )
    
}

export default  Header;