// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leversslider_sliderslayout__fLqLG{
display: flex;
    flex-direction: row;
 justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}
.leversslider_slidertitle__I6Dh4{
    font-weight: var(--font_regular);
    margin-right: 12px;
    width: 72px;
}
.leversslider_slidervalue__tvX8N{
    font-weight: var(--font_semibold);
    margin-left: 6px;
    width: 100px;

}
.leversslider_slider__sGJ4s {
    -webkit-appearance: none;
    width: 180px;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.leversslider_slider__sGJ4s:hover {
    opacity: 1;
}

.leversslider_slider__sGJ4s::-webkit-slider-thumb {
    appearance: none;
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
}

.leversslider_slider__sGJ4s::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/leversslider.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;IACT,mBAAmB;CACtB,2BAA2B;IACxB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,iCAAiC;IACjC,gBAAgB;IAChB,YAAY;;AAEhB;AACA;IACI,wBAAwB;IACxB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,YAAY;IAEZ,uBAAuB;AAC3B;;AAEA;IACI,UAAU;AACd;;AAEA;IAEI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".sliderslayout{\r\ndisplay: flex;\r\n    flex-direction: row;\r\n justify-content: flex-start;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n}\r\n.slidertitle{\r\n    font-weight: var(--font_regular);\r\n    margin-right: 12px;\r\n    width: 72px;\r\n}\r\n.slidervalue{\r\n    font-weight: var(--font_semibold);\r\n    margin-left: 6px;\r\n    width: 100px;\r\n\r\n}\r\n.slider {\r\n    -webkit-appearance: none;\r\n    width: 180px;\r\n    height: 25px;\r\n    background: #d3d3d3;\r\n    outline: none;\r\n    opacity: 0.7;\r\n    -webkit-transition: .2s;\r\n    transition: opacity .2s;\r\n}\r\n\r\n.slider:hover {\r\n    opacity: 1;\r\n}\r\n\r\n.slider::-webkit-slider-thumb {\r\n    -webkit-appearance: none;\r\n    appearance: none;\r\n    width: 25px;\r\n    height: 25px;\r\n    background: #04AA6D;\r\n    cursor: pointer;\r\n}\r\n\r\n.slider::-moz-range-thumb {\r\n    width: 25px;\r\n    height: 25px;\r\n    background: #04AA6D;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderslayout": `leversslider_sliderslayout__fLqLG`,
	"slidertitle": `leversslider_slidertitle__I6Dh4`,
	"slidervalue": `leversslider_slidervalue__tvX8N`,
	"slider": `leversslider_slider__sGJ4s`
};
export default ___CSS_LOADER_EXPORT___;
