// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datepicker_datepickercontainer__7mIF\\+{
    margin-right: 12px;



}

.datepicker_datepicker__Cy7iE{
    margin-left: 7px;
    width: var(--dropdown_width);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: var(--font_regular);
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 8px;
}
.datepicker_datepickerlabel__eR6aR{
    font-weight: var(--font_medium);
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/datepicker.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;;;AAItB;;AAEA;IACI,gBAAgB;IAChB,4BAA4B;IAC5B,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,gCAAgC;IAChC,eAAe;IACf,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,+BAA+B;IAC/B,eAAe;AACnB","sourcesContent":[".datepickercontainer{\r\n    margin-right: 12px;\r\n\r\n\r\n\r\n}\r\n\r\n.datepicker{\r\n    margin-left: 7px;\r\n    width: var(--dropdown_width);\r\n    padding-top: 5px;\r\n    padding-bottom: 5px;\r\n    padding-left: 10px;\r\n    padding-right: 10px;\r\n    font-weight: var(--font_regular);\r\n    font-size: 14px;\r\n    margin-top: 5px;\r\n    margin-bottom: 8px;\r\n}\r\n.datepickerlabel{\r\n    font-weight: var(--font_medium);\r\n    font-size: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepickercontainer": `datepicker_datepickercontainer__7mIF+`,
	"datepicker": `datepicker_datepicker__Cy7iE`,
	"datepickerlabel": `datepicker_datepickerlabel__eR6aR`
};
export default ___CSS_LOADER_EXPORT___;
