// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doghnutchartwhatiflayout_mainlayout__OltPs{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;

}

.doghnutchartwhatiflayout_doghnutlayout__SGXR0{

    width: 600px;
    height: 500px;


}

.doghnutchartwhatiflayout_doghnutlayout__SGXR0 h2{

    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/doghnutchartwhatiflayout.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,uBAAuB;IACvB,iBAAiB;;AAErB;;AAEA;;IAEI,YAAY;IACZ,aAAa;;;AAGjB;;AAEA;;IAEI,kBAAkB;AACtB","sourcesContent":[".mainlayout{\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n    flex-wrap: nowrap;\r\n\r\n}\r\n\r\n.doghnutlayout{\r\n\r\n    width: 600px;\r\n    height: 500px;\r\n\r\n\r\n}\r\n\r\n.doghnutlayout h2{\r\n\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainlayout": `doghnutchartwhatiflayout_mainlayout__OltPs`,
	"doghnutlayout": `doghnutchartwhatiflayout_doghnutlayout__SGXR0`
};
export default ___CSS_LOADER_EXPORT___;
