import DoghnutChart from "./DoghnutChart";
import ChartButtons from "./ChartButtons";
import CustomLegend from "./CustomLegend";
import styles from './stylesheets/charts.module.css'
function ChartsLayout() {
    let classes = styles.chartslayoutsection +" "+styles.chartslayoutsectionleft;
    return(<div className={styles.chartslayout}>
            <div className={styles.chartslayoutsection}>
              <h2>Actual Past Perfomance</h2>
                <div className={styles.chartslayoutleftsectoncharts}>
                    <div className={styles.chartslayoutleftsecton}>
                        <p>Jul</p>
                    <div className={styles.doghnutchartcontainer}>
                    <DoghnutChart showlegend = "false" period = "Jul-23" datatype = "Actual"/>
                    </div>

                    </div>
                    <div className={styles.chartslayoutleftsecton}>
                        <p>Aug</p>
                    <div className={styles.doghnutchartcontainer}>
                <DoghnutChart showlegend = "false" period = "Aug-23" datatype = "Actual"/>
                    </div>

                    </div>
                </div>
               <CustomLegend/>
            </div>
            <div  className={classes} >
                <div className={styles.chartslayoutrighttitles}>
                <h2 style={{width:"300px", textAlign:"center"}}>Prediction</h2>
                    <h2 style={{visibility:"hidden"}}>Prediction</h2>
                <h2>Probability</h2>
                </div>
                    <div>
                <div className={styles.chartslayoutright}>
                    <div className={styles.doghnutchartcontainer}>
                <DoghnutChart showlegend = "false" period = "Sep-23" datatype = "Pred_most_likely"/>
                    </div>
                    <p>Most Likely</p>
                    <h3>91%</h3>
                </div>
                        <div className={styles.chartslayoutright}>
                            <div className={styles.doghnutchartcontainer}>
                            <DoghnutChart showlegend = "false" period = "Sep-23" datatype = "Pred_competitor_matches"/>
                            </div>
                            <p>Competitor matcher</p>
                            <h3>91%</h3>
                        </div>

                        <div className={styles.chartslayoutright}>
                            <div className={styles.doghnutchartcontainer}>
                            <DoghnutChart showlegend = "false" period = "Sep-23" datatype = "Pred_competitor_exceeds"/>
                            </div>
                            <p>Competitor % above</p>
                            <h3>91%</h3>
                        </div>

                        <div className={styles.chartbuttonslayout}>
                            <ChartButtons/>
                        </div>



        </div>
            </div>
        </div>



    )
}

export default ChartsLayout