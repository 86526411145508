import styles from "./stylesheets/landingpage.module.css";
import { Outlet, Link } from "react-router-dom";


function LandingpageFooter() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
 return <>
     <footer>
         <Link to="/privacypolicy" className={styles.footerlinks} onClick={scrollToTop}>Privacy Policy</Link>
         <p className={styles.copyrighttext}>&copy;2024 Tabiri AI</p>
         <Link to="/termsofservice" className={styles.footerlinks} onClick={scrollToTop}> Terms Of Service</Link>

     </footer>

 </>

}

export default LandingpageFooter;