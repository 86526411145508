// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emailverification_messagelayout__AIKvm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.emailverification_messagetitle__b\\+MYn{

    font-weight: var(--font_semibold);
    font-size: 26px;
    text-align: center;
    margin-top: 15px;


}

.emailverification_messagetext__y3gGH{

    font-weight: var(--font_medium);
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    width: 50%;

}

@media only screen and (max-width: 600px) {

    .emailverification_messagetext__y3gGH{

        width: 85%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/emailverification.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;;IAEI,iCAAiC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;;;AAGpB;;AAEA;;IAEI,+BAA+B;IAC/B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;;AAEd;;AAEA;;IAEI;;QAEI,UAAU;IACd;AACJ","sourcesContent":[".messagelayout{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.messagetitle{\r\n\r\n    font-weight: var(--font_semibold);\r\n    font-size: 26px;\r\n    text-align: center;\r\n    margin-top: 15px;\r\n\r\n\r\n}\r\n\r\n.messagetext{\r\n\r\n    font-weight: var(--font_medium);\r\n    font-size: 16px;\r\n    text-align: center;\r\n    margin-top: 20px;\r\n    width: 50%;\r\n\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n\r\n    .messagetext{\r\n\r\n        width: 85%;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messagelayout": `emailverification_messagelayout__AIKvm`,
	"messagetitle": `emailverification_messagetitle__b+MYn`,
	"messagetext": `emailverification_messagetext__y3gGH`
};
export default ___CSS_LOADER_EXPORT___;
