// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comingsoonimage_layout__gjmFV{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 16%;
    margin-top: 7%;
    height: 100%;
}

.comingsoonimage_comingsoontext__\\+aEE2{

    font-weight: var(--font_medium);
    font-size: 18px;
    text-align: justify;
}



`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/comingsoonimage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;;IAEI,+BAA+B;IAC/B,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".layout{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-left: 16%;\r\n    margin-top: 7%;\r\n    height: 100%;\r\n}\r\n\r\n.comingsoontext{\r\n\r\n    font-weight: var(--font_medium);\r\n    font-size: 18px;\r\n    text-align: justify;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `comingsoonimage_layout__gjmFV`,
	"comingsoontext": `comingsoonimage_comingsoontext__+aEE2`
};
export default ___CSS_LOADER_EXPORT___;
