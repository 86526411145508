import styles from './stylesheets/sectiontitles.module.css'

function SectionTitles(props) {
    return(
        <div className={styles.sectiontitles}>
         <a href="#" target="_self" className= {styles.selected}>Current</a>
            <a href="#" target="_self" className={styles.unselected}>Past Predictions</a>
            <img src={props.image_url} className={styles.productimage}/>

        </div>

    )
}
export default SectionTitles;