import styles from "./stylesheets/landingpage.module.css";
import {Close, Menu} from "@mui/icons-material";
import logo from "./images/tabirilogo.png";
import {useState} from "react";


function LandingPageHeader(props){
    const [width, setWidth] = useState("-240px");

 let layout;

 if(props.type === "home"){
   layout = <>
       <div className={styles.menuicon} onClick={props.onClickOpenNavBar}> <Menu ></Menu>
           <img src={logo}/></div>
       <nav className={styles.mainnav}>
           <div className={styles.applogolayout}>
               <img src={logo}/>

           </div>

           <ul className={styles.headernavlist}>
               <li><a href="#home" onClick={props.onClickHyPerLink}>Home</a> </li>
               <li><a href="#howitworks" onClick={props.onClickHyPerLink}>How it works</a> </li>
               <li><a href="#benefits" onClick={props.onClickHyPerLink}>Benefits</a> </li>
               <li><a href="#aboutus" onClick={props.onClickHyPerLink}>About Us</a> </li>
               <li><a onClick={props.onClickContactUs}>Contact Us </a> </li>
           </ul>

           <button className="default_button_landingpage" onClick={props.onClickLoginButton}>Login</button>

       </nav>

       <nav className={styles.mobilenav} style={{left:props.navwidth}}>


           <ul className={styles.mobileheadernavlist}>
               <li><a href="#home" onClick={props.onClickCloseNavBar}>Home</a> </li>
               <li><a href="#howitworks" onClick={props.onClickCloseNavBar}>How it works</a> </li>
               <li><a href="#benefits" onClick={props.onClickCloseNavBar}>Benefits</a> </li>
               <li><a href="#aboutus" onClick={props.onClickCloseNavBar}>About Us</a> </li>
               <li><a  onClick={props.onClickContactUsNavBar}>Contact Us</a> </li>
           </ul>

           <button className={styles.navbutton}  onClick={props.onClickLoginButton}>Login</button>

           <Close className={styles.closeicon} onClick={props.onClickCloseNavBar}></Close>
       </nav>

   </>

 }else{
     //no home page


     const clickCloseNavBar = (event)=>{

         setWidth("-240px");

     }
     const clickOpenNavBar = (event)=>{

         setWidth("0");
     }




     layout = <>
         <div className={styles.menuicon} onClick={clickOpenNavBar}> <Menu ></Menu>
             <img src={logo}/></div>
         <nav className={styles.mainnav}>
             <div className={styles.applogolayout}>
                 <img src={logo}/>

             </div>

             <ul className={styles.headernavlist}>
                 <li><a href="/">Home</a> </li>
                 <li><a href="/#howitworks" >How it works</a> </li>
                 <li><a href="/#benefits" >Benefits</a> </li>
                 <li><a href="/#aboutus" >About Us</a> </li>

             </ul>

             <button className="default_button_landingpage" style={{visibility:"hidden"}}>Login</button>

         </nav>

         <nav className={styles.mobilenav} style={{left:width}}>


             <ul className={styles.mobileheadernavlist}>
                 <li><a href="/" onClick={clickCloseNavBar}>Home</a> </li>
                 <li><a href="/#howitworks" onClick={clickCloseNavBar}>How it works</a> </li>
                 <li><a href="/#benefits" onClick={clickCloseNavBar}>Benefits</a> </li>
                 <li><a href="/#aboutus" onClick={clickCloseNavBar}>About Us</a> </li>

             </ul>


             <Close className={styles.closeicon} onClick={clickCloseNavBar}></Close>
         </nav>

     </>



 }


    return <>{layout}</>

}

export default LandingPageHeader;