import styles from './stylesheets/leversslider.module.css'
import {useEffect, useState} from "react";
function Sliders(prop) {


    const [sliderValue, setSliderValue] = useState(parseInt(prop.sliderValue));
    const [sliderTextValue, setSlidertTextValue] = useState(prop.default);

    useEffect(() => {
        // Update slider value and text when the default prop changes
        setSliderValue(parseInt(prop.sliderValue));
        setSlidertTextValue(prop.default);
    }, [prop.default, prop.sliderValue]);


    const getSliderValue = (event)=>{
        let sliderVal = event.currentTarget.value;
        const returnValue =  {type:prop.title, value:sliderVal};
        if(prop.title === "Price"){
            sliderVal = sliderVal + " ksh";



        }else if(prop.title === "Promo"){
            sliderVal = sliderVal + "%";

        }else if(prop.title === "Period"){

            sliderVal = sliderVal + " wks";

        } else{

            sliderVal = sliderVal + " ksh";

        }

        //alert(event.currentTarget.value);
        setSlidertTextValue(sliderVal)

        setSliderValue(event.currentTarget.value);

        prop.onhandlesliderevent(returnValue);
    };
    return (<div className={styles.sliderslayout}>
            <p className={styles.slidertitle}>{prop.title}</p>

            <input className={styles.slider} type="range"
                   min={prop.min}   max={prop.max}  id={prop.rangeid} value={sliderValue} onInput={(event) =>getSliderValue(event) }/>
            <p className={styles.slidervalue}>{sliderTextValue}</p>
        </div>



    )



}

export default Sliders;